/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
// Dependencies
import { ExclamationCircleIcon } from "@fluentui/react-icons-northstar";
import { Alert, Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import moment from "moment";
import { useEffect, useState } from "react";
// Hooks
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import useSearchFlightForm from "../../../../hooks/useSearchFlightForm/useHook";
import CovidModalService from "../../../../services/search-flight/covid-modal.service";
// Services
import SearchFlightService from "../../../../services/search-flight/search-flights.service";
import HostSettings from "../../../../utils/host.settings";
import { RiskAdvisoryOutput } from "../search/covid-popup/covid-modal.model";
import CovidPopup from "../search/covid-popup/covid-popup";
import { SearchCriteria } from "../search/search.model";
import TextCovid from "../search/TextCovid/TextCovid";
// Images
import EditSearch from "./edit-search/edit-search";
// Models
import { FlightOffer } from "./fligh-results.model";
// Components
import FlightCard from "./flight-card/flight-card";
// Styles
import "./flight-results.scss";

const retrieveFlights = (state: any, searchFlightService: any) => {
  let departureDateTime: any = [];
  let arrivalDateTime: any = [];

  const { departureDate, returnDate, departureTime, returnTime } = state;

  if (departureDate) {
    departureDateTime = moment(
      new Date(departureDate)?.setHours(
        parseInt(departureTime?.value.split(":")[0], 10)
      )
    )
      .format()
      .split(":");
  }

  if (returnDate) {
    arrivalDateTime = moment(
      new Date(returnDate)?.setHours(
        parseInt(returnTime?.value.split(":")[0], 10)
      )
    )
      .format()
      .split(":");
  }

  const formattedDepartureDate = `${departureDateTime[0]}:${departureDateTime[1]}`;
  const formattedArrivalDate = `${arrivalDateTime[0]}:${arrivalDateTime[1]}`;

  return searchFlightService(
    state,
    formattedDepartureDate,
    formattedArrivalDate
  );
};

const redirectToSearchFlights = (state: any) => {
  let departureDate: any = "";
  let arrivalDate: any = "";
  let formattedDepartureDate: any = "";
  let formattedArrivalDate: any = "";

  if (state?.departureDate) {
    departureDate = moment(
      state?.departureDate?.setHours(
        parseInt(state.departureTime.value?.split(":")[0], 10)
      )
    )
      .format()
      .split(":");
  }

  if (state?.returnDate) {
    arrivalDate = moment(
      state?.returnDate?.setHours(
        parseInt(state.returnTime.value?.split(":")[0], 10)
      )
    )
      .format()
      .split(":");
  }

  if (departureDate) {
    formattedDepartureDate = `${departureDate[0]}:${departureDate[1]}`;
  }

  if (arrivalDate) {
    formattedArrivalDate = `${arrivalDate[0]}:${arrivalDate[1]}`;
  }

  let context: any = "";

  if (state?.tripType === "roundtrip") {
    context = {
      subEntityId: {
        segmentType: "Flight",
        numberOfPassengers: 1,
        directFlights: false,
        stopOnStartPage: false,
        departure: [state.fromIataCode, state.toIataCode],
        arrival: [state.toIataCode, state.fromIataCode],
        dateTime: [formattedDepartureDate, formattedArrivalDate],
        dateTimeType: [state.tripTypeTimeOutbound, state.tripTypeTimeInbound],
        purpose: state.purpose,
      },
    };
  } else {
    context = {
      subEntityId: {
        segmentType: "Flight",
        numberOfPassengers: 1,
        directFlights: false,
        stopOnStartPage: false,
        departure: [state.fromIataCode],
        arrival: [state.toIataCode],
        dateTime: [formattedDepartureDate],
        dateTimeType: [state.tripTypeTimeOutbound],
        purpose: state.purpose,
      },
    };
  }

  context.subEntityId = JSON.stringify(context.subEntityId);
  const stringContext = JSON.stringify(context);

  const url = `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/cytricweb?context=${encodeURI(stringContext)}`;

  microsoftTeams.executeDeepLink(url);
};

const redirectToPFC = (currentFlight: any) => {
  const context = {
    subEntityId: JSON.stringify(currentFlight),
  };

  const stringContext = JSON.stringify(context);

  const url = `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/cytricweb?context=${encodeURI(stringContext)}`;

  microsoftTeams.executeDeepLink(url);
};

function FlightResults(): JSX.Element {
  const [formData] = useSearchFlightForm();

  const { toCountryCode, purpose, tripTypeTimeInbound, tripTypeTimeOutbound } =
    formData;

  const { isLoading, data, isError } = useQuery(
    ["fetchFlights", formData],
    () => {
      return retrieveFlights(formData, searchFlightService.getFlightsv2);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    }
  );

  const covidModalResponseV2 = useQuery(
    ["covidDataPopupV2", toCountryCode],
    () => covidModalService.getCovidInformationV2(toCountryCode),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [openModal, setOpenModal] = useState(false);
  const [covidMsgVisible, setCovidMsgVisible] = useState(true);
  const [flightsIDs, setFlightsIDs] = useState<string[]>([]);

  const searchFlightService = new SearchFlightService();
  const covidModalService = new CovidModalService();

  // Hooks
  const history = useHistory();
  let currentFlight: any;

  const flightData: any = data?.data?.data;
  const covidModalData: RiskAdvisoryOutput = covidModalResponseV2?.data?.data;

  // eslint-disable-next-line react/no-unstable-nested-components
  function AlertCovidWarning() {
    return (
      <Alert
        warning
        className="warningAlertResultsPage"
        icon={<ExclamationCircleIcon />}
        content={
          <TextCovid
            covidModalData={covidModalData}
            setOpenModal={setOpenModal}
          />
        }
        dismissible
        visible={covidMsgVisible}
        onVisibleChange={() => setCovidMsgVisible(false)}
        dismissAction={{ "aria-label": "close" }}
      />
    );
  }

  function flightIDIncluded(id: string) {
    if (flightsIDs.includes(id)) {
      const currentOffer = flightData.offers.find((of: any) => of.id === id);
      const selectedOffer: any = {
        segmentType: "Flight",
        numberOfPassengers: 1,
        directFlights: false,
        stopOnStartPage: false,
        departure: [],
        arrival: [],
        dateTime: [],
        dateTimeType: [],
        purpose,
        airlineCode: [],
        airlineNumber: [],
        numberOfFlights: 0,
      };

      const segments = currentOffer.bounds.map((co: any) => co.segments);

      const [firstBound, secondBound] = segments;

      let borders: any = [];

      const findFlight = (sm: any) => {
        const current = flightData.bounds.find(
          (flight: any) => sm === flight.id
        );

        selectedOffer.airlineCode.push(current.carrierCode);
        selectedOffer.airlineNumber.push(current.flightNumber);
      };

      if (secondBound) {
        borders = [firstBound[0], secondBound[0]];

        const firstFlightInfo = flightData.bounds.find(
          (flight: any) => borders[0] === flight.id
        );

        const firstFlightEndInfo = flightData.bounds.find(
          (flight: any) => firstBound[firstBound.length - 1] === flight.id
        );

        const secondFlightInfo = flightData.bounds.find(
          (flight: any) => borders[1] === flight.id
        );

        const secondFlightEndInfo = flightData.bounds.find(
          (flight: any) => secondBound[secondBound.length - 1] === flight.id
        );

        selectedOffer.departure.push(firstFlightInfo.departure.iataCode);
        selectedOffer.departure.push(secondFlightInfo.departure.iataCode);

        selectedOffer.arrival.push(firstFlightEndInfo.arrival.iataCode);
        selectedOffer.arrival.push(secondFlightEndInfo.arrival.iataCode);

        selectedOffer.dateTime.push(firstFlightInfo.departure.localDateTime);
        selectedOffer.dateTime.push(secondFlightInfo.departure.localDateTime);

        selectedOffer.dateTimeType.push(tripTypeTimeOutbound);
        selectedOffer.dateTimeType.push(tripTypeTimeInbound);

        firstBound.forEach(findFlight);

        secondBound.forEach(findFlight);

        selectedOffer.numberOfFlights = [
          segments[0].length,
          segments[1].length,
        ];
      } else {
        borders = [firstBound[0]];

        const firstFlightInfo = flightData.bounds.find(
          (flight: any) => borders[0] === flight.id
        );

        const firstFlightEndInfo = flightData.bounds.find(
          (flight: any) => firstBound[firstBound.length - 1] === flight.id
        );

        selectedOffer.departure.push(firstFlightInfo.departure.iataCode);

        selectedOffer.arrival.push(firstFlightEndInfo.arrival.iataCode);

        selectedOffer.dateTime.push(firstFlightInfo.departure.localDateTime);

        selectedOffer.dateTimeType.push(tripTypeTimeOutbound);

        firstBound.forEach(findFlight);

        selectedOffer.numberOfFlights = segments[0].length;
      }

      currentFlight = selectedOffer;
    }
    return flightsIDs.includes(id);
  }

  function selectedFlightsIDs(id: string) {
    if (flightIDIncluded(id)) {
      setFlightsIDs(flightsIDs.filter((x) => x !== id));
    } else {
      setFlightsIDs([...flightsIDs, id]);
    }
  }

  function getInPolicyOffers() {
    let numFlights = 0;
    let numFlightsWithoutPrice = 0;
    let numFlightsWithPrice = 0;

    flightData?.offers.forEach((sf: any) => {
      if ((sf.rank && sf.rank < 4) || sf.tags) {
        if (sf.outOfPolicy === false) {
          numFlights += 1;
          if (!sf.price) {
            numFlightsWithoutPrice += 1;
          } else {
            numFlightsWithPrice += 1;
          }
        }
      }
    });
    if (numFlightsWithoutPrice > 0 && numFlightsWithPrice > 0) {
      return numFlightsWithPrice;
    }
    return numFlights;
  }

  return (
    <Flex
      data-testid="flightResultsContainer"
      column
      className="flightResultsContainer"
    >
      <EditSearch />
      {covidModalResponseV2.isLoading && <Loader />}
      {!covidModalResponseV2.isError && covidModalResponseV2?.data?.data && (
        <AlertCovidWarning />
      )}
      <CovidPopup
        open={openModal}
        setOpen={setOpenModal}
        countryCode={toCountryCode}
      />
      <Flex column className="flightSubContainer">
        <Flex column className="flightHeader">
          <h1 className="flightHeading">Select flights</h1>
          <p className="flightSubtext">
            Select one of the {getInPolicyOffers()} following <b>in-policy</b>{" "}
            flight packages to book your trip. You can also select one or
            several to share your options before booking.
          </p>
        </Flex>
        {!isLoading && !isError && flightData && (
          <FlightCard
            flightData={flightData}
            flightIDIncluded={flightIDIncluded}
            selectedFlightsIDs={selectedFlightsIDs}
          />
        )}
        {isLoading && <Loader label="Loading flight results..." />}

        <Flex hAlign="start" className="flightHeader">
          <Text className="noneOptionsFit">
            None of these flight options fit you?{" "}
            <span
              /* target="blank" href="" */ className="viewMoreOptions"
              data-testid="viewMoreOptionsId"
              onClick={() => redirectToSearchFlights(formData)}
            >
              {" "}
              View more options
            </span>{" "}
          </Text>
        </Flex>
      </Flex>
      <Flex space="between" className="flightFooter">
        <Button
          className="flightShareButton"
          text
          content="Share selection"
          disabled={true}
        />
        <Flex gap="gap.small">
          <Button content="Back" onClick={() => history.goBack()} />
          <Button
            primary
            content="Book"
            data-testid="pfcButtonId"
            disabled={flightsIDs.length !== 1}
            onClick={() => redirectToPFC(currentFlight)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FlightResults;

/* eslint-disable class-methods-use-this */

import Http from "../../common/Http";
import { RISK_ADVISORY } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";

export default class CovidModalService {
  public getCovidInformationV2 = async (countryCode: any) => {
    if (countryCode) {
      return Http.get(`${HostSettings.getBaseAPI}${RISK_ADVISORY}`, {
        params: {
          countryCode,
        },
      });
    }
    return undefined;
  };
}

import { QueryParamsModel } from "../../models/CloseCollaboratorsModel";

export default class ClassCloseCollaborators implements QueryParamsModel {
  scope = ["User.Read", "People.Read"];

  top = 200;

  select = "displayName,jobTitle,userPrincipalName,scoredEmailAddresses";

  filter;

  photoError = "./close-collaborators-images/errorAvatar.png";

  constructor() {
    const filterType1 = "personType/class eq 'Person'";
    const filterType2 = "personType/subclass eq 'OrganizationUser'";

    this.filter = ` ${filterType1} and ${filterType2}`;
  }
}

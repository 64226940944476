/* eslint-disable react/react-in-jsx-scope */
import { Text } from "@fluentui/react-northstar";
import DateUtils from "../../../../utils/date.utils";
import { IDateProps } from "./DateComponent.model";
import Styles from "./DateComponent.module.scss";

function DateComponent({ trip }: IDateProps) {
  const startDate = trip?.startDate
    ? DateUtils.formatLongDate(trip?.startDate?.substring(0, 10))
    : "";
  const endDate = trip?.endDate
    ? DateUtils.formatLongDate(trip?.endDate?.substring(0, 10))
    : "";
  const displayDate = startDate && endDate ? `${startDate} - ${endDate}` : null;
  return (
    <Text
      className={Styles.date}
      styles={(theme) => ({
        color: theme.theme.siteVariables.upcomingTrips?.header.title,
      })}
    >
      {displayDate}
    </Text>
  );
}

export default DateComponent;

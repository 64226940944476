import { Button, Divider, Flex, List } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import EmptyState from "../../empty-state/EmptyState";
import { redirectToExpenseDashboard } from "../../redirection/ExpenseRedirectionHelper";
import { ItemInterface } from "../TodoInterfaces";

interface TodoContentPropsInterface {
  items: ItemInterface[];
  counts: number;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
  didMergeAction: React.Dispatch<React.SetStateAction<boolean>>;
}

function ListItemHeader(props: { elem: ItemInterface }): JSX.Element {
  const { elem } = props;
  return (
    <>
      <b
        style={{
          display: "inline-block",
          width: "1rem",
          textAlign: "center",
        }}
      >
        {elem.count}
      </b>
      <span className="list-item-header-title">{elem.header}</span>
    </>
  );
}

function TodoContent({
  items,
  counts,
  openDialog,
  didMergeAction,
}: TodoContentPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.todo",
  });
  const emptyState = {
    image: "expenses-images/no-tasks.svg",
    title: t("emptyState.allDone"),
    message: t("emptyState.noTasks"),
  };

  const onListItemClick = (elem: ItemInterface) => {
    if (elem.keyId !== 2) {
      redirectToExpenseDashboard();
    } else {
      openDialog(true);
      didMergeAction(false);
    }
  };

  return counts !== 0 ? (
    <List className="todo-list" data-testid="list">
      {items.map((elem, index) => (
        <div className="todo-list-item" key={elem.keyId}>
          {elem.count !== 0 && (
            <>
              <List.Item
                className="list-item"
                header={<ListItemHeader elem={elem} />}
                headerMedia={
                  <Button
                    data-testid={`todo__action-button-${elem.keyId}`}
                    text
                    primary
                    iconOnly
                    styles={{ color: "#6264A7" }}
                    content={
                      elem.keyId !== 2 ? t("viewList") : t("viewDuplicates")
                    }
                    onClick={() => onListItemClick(elem)}
                  />
                }
                index={index}
                data-testid={`todo__list-item-action-${elem.keyId}`}
              />
              {items[items.length - 1].count !== 0 &&
                index !== items.length - 1 && <Divider size={0} />}
            </>
          )}
        </div>
      ))}
    </List>
  ) : (
    <Flex hAlign="center" vAlign="center">
      <EmptyState
        image={emptyState.image}
        title={emptyState.title}
        message={emptyState.message}
      />
    </Flex>
  );
}

export default TodoContent;

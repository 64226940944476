import { createAsyncThunk } from "@reduxjs/toolkit";
import amadeusCytricLogin from "../../common/login/login";
import AuthenticationService from "../../services/authentication/authentication.service";
import { INTERNAL_KEYCLOAK_FLAG } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";

export const AUTHENTICATION_STORE_NAME = "authentication";
export const AUTHENTICATION_LOGIN = "login";
export const AUTHENTICATION_LOGOUT = "logout";

export const login = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHENTICATION_LOGIN}`,
  async (data, { rejectWithValue }) => {
    if (HostSettings.isCytricLoginEnabled) {
      try {
        return await amadeusCytricLogin(
          HostSettings.getKeycloakUri,
          HostSettings.getKeycloakAuthUri
        );
      } catch (error: any) {
        return rejectWithValue(
          error.message ? `messages.${error.message}` : `messages.ERR_Teams`
        );
      }
    }

    return true;
  }
);

export const logout = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHENTICATION_LOGOUT}`,
  async () => {
    if (HostSettings.isCytricLoginEnabled) {
      await AuthenticationService.logout();
      localStorage.removeItem(INTERNAL_KEYCLOAK_FLAG);
    }
  }
);

/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Provider } from "react-redux";
import store from "../../../../store/Store";
import HotelSegmentContent from "../hotel-segment-content/HotelSegmentContent";
// eslint-disable-next-line import/no-cycle
import { SegmentFunctionData } from "../TripPanelContent";

function HotelSegmentFunction(props: SegmentFunctionData) {
  const { product, trip, index } = props;
  if (product.Trip_Product_Hotel) {
    return (
      <Provider store={store}>
        <HotelSegmentContent
          key={`hotel-segment-${index.toString()}`}
          tripId={trip}
          segmentId={index.toString()}
          hotelSegment={product.Trip_Product_Hotel}
        />
      </Provider>
    );
  }
  return null;
}

export default HotelSegmentFunction;

import { ITrip } from "./UpcomingTrips/UpcomingTrips.model";
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
import { UPCOMING_TRIPS, UPCOMING_TRIP_DETAIL } from "./constants";
import { ItineraryTypes } from "./trip-utils.model";

export const hasOnlyItineraryTypes = (
  trip: ITrip,
  ...types: ItineraryTypes[]
) =>
  trip?.products?.[0]?.hasCar === types.includes("car") &&
  trip?.products?.[0]?.hasRail === types.includes("rail") &&
  trip?.products?.[0]?.hasAir === types.includes("air") &&
  trip?.products?.[0]?.hasHotel === types.includes("hotel");

const getBounds = (trip: any) => {
  const segments = trip?.products[0]?.products;
  const bounds: any[] = [];

  let direction = "1";
  let temp: any[] = [];
  segments.forEach((segment: any) => {
    if (
      temp.length > 0 &&
      (segment?.id === "0" || segment?.direction > direction)
    ) {
      bounds.push(temp);
      direction = segment?.direction;
      temp = [];
    }
    temp.push(segment);
  });
  bounds.push(temp);

  return bounds;
};

const getRailStations = (trip: any) => {
  try {
    const bounds = getBounds(trip);

    const destinationStations = bounds.reduce((acc: any, bound: any) => {
      const lastIndex = bound.length - 1 || 0;
      const lastStation = bound?.[lastIndex]?.arrival?.name;

      acc.push(lastStation);
      return acc;
    }, []);

    const firstStation = trip?.products[0]?.products?.[0].departure?.name;
    const lastStation = destinationStations[destinationStations.length - 1];

    if (firstStation === lastStation) {
      destinationStations.pop();
    }

    return destinationStations;
  } catch {
    return [];
  }
};

const formatName = (name: string, beforeText?: string) =>
  beforeText ? `${beforeText} ${name}` : name;

export const composeTripName = (trip: any, beforeText?: string) => {
  const DELIMITER = UPCOMING_TRIPS.delimiterForDestinations;
  const TRIP_NAME_FALLBACK = UPCOMING_TRIP_DETAIL.breadcrumb.currentPage;

  try {
    if (trip?.name) {
      return trip?.name;
    }
    if (hasOnlyItineraryTypes(trip, "car")) {
      return UPCOMING_TRIPS.tripTitle;
    }
    if (
      hasOnlyItineraryTypes(trip, "rail") ||
      hasOnlyItineraryTypes(trip, "rail", "car")
    ) {
      const destinationRailStations = getRailStations(trip)?.filter(Boolean);
      if (destinationRailStations?.length > 0) {
        return formatName(destinationRailStations.join(DELIMITER), beforeText);
      }
      return TRIP_NAME_FALLBACK;
    }

    const destinationCities = trip?.destinationCities?.filter(Boolean);
    if (destinationCities?.length > 0) {
      return formatName(destinationCities.join(DELIMITER), beforeText);
    }
  } catch {
    return TRIP_NAME_FALLBACK;
  }

  return TRIP_NAME_FALLBACK;
};

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { SIMPLIFIED_BOOKING_DETAILS } from "../../../../constants";
import Styles from "./Placeholder.module.scss";

function Placeholder() {
  return (
    <Flex column hAlign="center" vAlign="center">
      <Text
        className={Styles.placeholder__image}
        as="div"
        styles={(theme) => ({
          background: theme.theme.siteVariables.simplifiedBookingDetails
            ?.imagePlaceholder
            ? theme.theme.siteVariables.simplifiedBookingDetails
                .imagePlaceholder
            : "",
        })}
      />
      <Text
        content={SIMPLIFIED_BOOKING_DETAILS.title}
        className={Styles.placeholder__title}
        data-testid="title"
        styles={(theme) => ({
          color: theme.theme.siteVariables.simplifiedBookingDetails?.title
            ? theme.theme.siteVariables.simplifiedBookingDetails.title
            : "",
        })}
      />
      <Text
        content={SIMPLIFIED_BOOKING_DETAILS.subtitle}
        className={Styles.placeholder__subtitle}
        data-testid="subtitle"
        styles={(theme) => ({
          color: theme.theme.siteVariables.simplifiedBookingDetails
            ? theme.theme.siteVariables.simplifiedBookingDetails.subtitle
            : "",
        })}
      />
    </Flex>
  );
}

export default Placeholder;

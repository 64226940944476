/* eslint-disable class-methods-use-this */

import Http from "../../common/Http";
import { SETTINGS } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";

export default class TripPurposeService {
  public getTripPurpose = async (
    from = "MAD",
    to = "MIA",
    tripType = "AIR"
  ) => {
    if (from && to) {
      return Http.get(`${HostSettings.getBaseAPI}${SETTINGS}`, {
        params: {
          tripType,
          from,
          to,
        },
      });
    }
    return undefined;
  };
}

import { Button, Flex, Loader, Segment } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import lodash from "lodash";
import React, { useEffect, useState } from "react";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import { InfoImage } from "../../common/components/generic-info-component/GenericInfo.model";
import { IError } from "../../common/models/Error";
import ProductAir from "../../common/models/ProductAir";
import ProductTrain from "../../common/models/ProductTrain";
import Trip from "../../common/models/Trip";
import TripProduct from "../../common/models/TripProduct";
import TripHelper from "../../helpers/TripHelper";
import {
  getSelectedSegments,
  getSelectedTripId,
  hasAtLeastOneSegmentSelected
} from "../../store/segment-selected/SegmentSelectedSelector";
import {
  resetSegmentSelected,
  SegmentObj
} from "../../store/segment-selected/SegmentSelectedSlice";
import Store from "../../store/Store";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import { fetchUserTrips } from "../../store/trips/TripsActions";
import {
  errorInformation,
  selectIsEmptyTripList,
  selectIsPending,
  selectTripListIsError,
  selectTrips
} from "../../store/trips/TripsSelector";
import HostSettings from "../../utils/host.settings";
import AccordionTrip from "./accordion-trip/AccordionTrip";
import "./TripList.scss";
import Welcome from "./welcome/Welcome";

function TripList() {
  const dispatch = useAppDispatch();
  const trips: Trip[] = useAppSelector((state) => selectTrips(state));

  const errorInfo: IError = useAppSelector((state) => errorInformation(state));
  const hasSegmentChecked: boolean = useAppSelector((state) =>
    hasAtLeastOneSegmentSelected(state)
  );
  const tripId = useAppSelector((state) => getSelectedTripId(state));
  const segments = useAppSelector((state) => getSelectedSegments(state));
  const isPending = useAppSelector((state) => selectIsPending(state));
  const isEmptyTripList = useAppSelector((state) =>
    selectIsEmptyTripList(state)
  );

  const tripListIsError: boolean = useAppSelector((state) =>
    selectTripListIsError(state)
  );

  // clone of trips with formatted bounds
  const [tripsWithBound, setTripWithBound] = useState(new Array<Trip>());

  // use effect on load
  useEffect(() => {
    Store.dispatch(fetchUserTrips());
  }, []);

  useEffect(() => {
    const copyOfTrips = lodash.cloneDeep(trips);
    const tripFormatedWithBounds = copyOfTrips.map((trip: Trip) => {
      const tripFormatted = {
        ...trip,
        products: TripHelper.formatTripProductsWithBound(trip.products || []),
      };
      tripFormatted.name = TripHelper.computeTripTitle(tripFormatted);
      return tripFormatted;
    });
    setTripWithBound(tripFormatedWithBounds);
  }, [trips]);

  const pushAllStopsOfBound = (
    arrayOfSegments: Array<string>,
    segment: TripProduct
  ) => {
    const airOrRailSegment: ProductAir | ProductTrain = segment.Trip_Product_Air
      ? segment.Trip_Product_Air
      : segment.Trip_Product_Train!;

    airOrRailSegment.indexesToShare?.forEach((index) => {
      const trip = trips.find((element: Trip) => element.id === tripId);

      if (trip && trip.products && trip.products[0]) {
        arrayOfSegments.push(
          JSON.stringify(
            trip.products[0].products[index][
              airOrRailSegment.productType as keyof TripProduct
            ]
          )
        );
      }
    });
  };

  const getSegments = () => {
    const arrayOfSegments = new Array<string>();

    const copyOfTrip = tripsWithBound.find(
      (element: Trip) => element.id === tripId
    );
    if (copyOfTrip && copyOfTrip.products) {
      const getAllSegments = copyOfTrip.products[0];

      segments.forEach((seg: SegmentObj) => {
        const segment: any = getAllSegments.products[parseInt(seg.id, 10)];

        if (segment.Trip_Product_Air || segment.Trip_Product_Train) {
          pushAllStopsOfBound(arrayOfSegments, segment);
        } else {
          const values = Object.keys(segment).map((key) => segment[key]);
          arrayOfSegments.push(JSON.stringify(values[0]));
        }
      });
    }

    return arrayOfSegments;
  };

  async function onSubmitButton() {
    const selectedTrip = tripsWithBound?.find(
      (trip: Trip) => trip.id === tripId
    );
    const credential = new TeamsUserCredential();
    const accessToken = await credential.getToken(HostSettings.tokenScopes);
    const data = {
      action: "tripCard",
      tripId,
      destinationPictures: selectedTrip ? selectedTrip.destinationPicture : [],
      title: selectedTrip?.name,
      purpose: selectedTrip ? selectedTrip.purpose : "",
      tripStartDateTime: selectedTrip ? selectedTrip.startDate : "",
      tripEndDateTime: selectedTrip ? selectedTrip.endDate : "",
      products: getSegments(),
      authCookie: "Authentication",
      domain: "trips",
      accesstoken: `Bearer ${accessToken?.token}`,
    };
    microsoftTeams.tasks.submitTask(data);
    // reset selected segments
    dispatch(resetSegmentSelected());
  }

  const getLenght = () => {
    let numOfEleToSend = 0;

    numOfEleToSend += segments.length ? segments.length : 0;

    return numOfEleToSend > 0 ? `(${numOfEleToSend.toString()})` : "";
  };

  return (
    <Flex className="trip-container" column>
      {isPending && (
        <Loader size="large" className="trip-loader" label="Loading Trips" />
      )}
      {tripListIsError && (
        <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />
      )}
      {isEmptyTripList && <Welcome />}
      {!isEmptyTripList && (
        <Flex>
          <Flex
            gap="gap.medium"
            hAlign="center"
            column
            className="trip-list-flex"
          >
            {tripsWithBound.map((trip) => (
              <AccordionTrip key={`accordion-trip-${trip.id}`} trip={trip} />
            ))}
          </Flex>
          <Segment className="trips-footer">
            <Flex column hAlign="end">
              <Button
                primary
                disabled={!hasSegmentChecked}
                onClick={() => onSubmitButton()}
              >
                Share {getLenght()}
              </Button>
            </Flex>
          </Segment>
        </Flex>
      )}
    </Flex>
  );
}

export default TripList;

/* eslint-disable react/react-in-jsx-scope */
import { Button, Header } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { redirectToExpenseDashboard } from "../redirection/ExpenseRedirectionHelper";
import "./Header.scss";

function DashboardHeader() {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.header",
  });

  const handleViewExpensesClick = redirectToExpenseDashboard;

  return (
    <div className="dashboard-header-container">
      <div className="header-container">
        <Header as="h2" className="dashboard-header-text" content="Dashboard" />
      </div>
      <div className="button-container">
        <Button
          id="dashboard__view-expenses__button"
          className="view-expenses-button"
          data-testid="dashboard__view-expenses__button"
          content={t("viewExpenses")}
          onClick={handleViewExpensesClick}
          primary
        />
      </div>
    </div>
  );
}

export default DashboardHeader;

import { Text } from "@fluentui/react-northstar";
import React from "react";
import { UPCOMING_TRIP_DETAIL } from "../../../constants";
import Placeholder from "./Placeholder/Placeholder";
import Styles from "./SimplifiedBookingDetails.module.scss";

function SimplifiedBookingDetails() {
  return (
    <div>
      <div className={Styles.widget__header}>
        <Text
          content={UPCOMING_TRIP_DETAIL.bookingDetails.title}
          className={Styles.widget__title}
          styles={(theme) => ({
            color: theme.theme.siteVariables.simplifiedBookingDetails
              ? theme.theme.siteVariables.simplifiedBookingDetails.title
              : "",
          })}
        />
      </div>
      <Placeholder />
    </div>
  );
}

export default SimplifiedBookingDetails;

import { TeamsUserCredential } from "@microsoft/teamsfx";
import { QueryParamsModel } from "../../models/CloseCollaboratorsModel";

export default class ClassUsersFindContact implements QueryParamsModel {
  scope = ["User.Read.All"];

  top = 3;

  select = "id, displayName, jobTitle, userPrincipalName, city";

  filter;

  photoError = "./close-collaborators-images/errorAvatar.png";

  credential: any;

  constructor(countryCode: string) {
    this.filter = ` usageLocation eq '${countryCode}'`;
    this.credential = new TeamsUserCredential();
  }
}

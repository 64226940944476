import { createMicrosoftGraphClient } from "@microsoft/teamsfx";
import ClassUsersFindContact from "../classes/ClassUsersFindContact";
import getFilteredUser from "./GetFilteredUser";
import getFinalConstructObjet from "./GetFinalConstructObjet";
import getImages from "./GetImages";

export function addImagesToUsers(users: any, images: any) {
  const usersAddImages = users.map((item: any) => {
    const user: any = item;
    user.image = images.responses.find((y: any) => y.id === item.id);
    return user;
  });
  return usersAddImages;
}

export default async function getUsersFindaContact(
  searchQuery: any,
  countryCode: string,
  setFindcontacts: any,
  setLoading: any,
  context: any
) {
  const query = new ClassUsersFindContact(countryCode);
  const client = createMicrosoftGraphClient(query.credential, query.scope);
  const requestUrl = `users?$top=${query.top}&$select=${
    query.select
  }&$search="displayName:${searchQuery.toLowerCase()}"`;

  let collaboratorsContact: any;

  try {
    const users = await client
      .api(requestUrl)
      .header("ConsistencyLevel", "eventual")
      .filter(query.filter)
      .get();

    const usersFilter = getFilteredUser(users.value, context);

    const usersList = usersFilter.map(({ id }: any) => id);
    const images = await getImages(client, usersList);

    const usersComparison = addImagesToUsers(usersFilter, images);

    collaboratorsContact = getFinalConstructObjet(
      usersComparison,
      query.photoError
    );
  } catch {
    collaboratorsContact = [{}];
  }

  setFindcontacts(collaboratorsContact);
  setLoading(false);

  return collaboratorsContact;
}

import lodash from "lodash";
import Products from "../common/models/Product";
import ProductAir from "../common/models/ProductAir";
import ProductTrain from "../common/models/ProductTrain";
import Trip from "../common/models/Trip";
import TripProduct from "../common/models/TripProduct";

const titleDelimiter: string = " \u00B7 ";
export default class TripHelper {
  public static formatTripProductsWithBound(
    tripProducts: Products[]
  ): Products[] {
    return tripProducts.map((product) => {
      // example: products = [flight1-1, flight1-2, hotel, flight2]
      const products = [...product.products];

      // products: [flight1, hotel, flight2]
      return {
        ...product,
        products: this.reduceProductByBound(products),
      };
    });
  }

  private static reduceProductByBound(products: TripProduct[]) {
    let lastAirOrRailProduct: ProductAir | ProductTrain;

    return products.reduce(
      (filteredProducts: TripProduct[], currentProduct, index) => {
        if (
          currentProduct.Trip_Product_Air ||
          currentProduct.Trip_Product_Train
        ) {
          const AirOrRailProduct = currentProduct.Trip_Product_Air
            ? currentProduct.Trip_Product_Air
            : currentProduct.Trip_Product_Train!;

          const isTheSameBound =
            lastAirOrRailProduct &&
            lastAirOrRailProduct.productType === AirOrRailProduct.productType &&
            AirOrRailProduct.id !== "0" &&
            AirOrRailProduct.direction === lastAirOrRailProduct.direction;

          // if we should add a stop in the last air or rail bound
          if (isTheSameBound) {
            // update of the last air or rail bound to support new stop and a new arrival
            this.pushStopInfoInAirOrRailSegment(
              lastAirOrRailProduct,
              AirOrRailProduct,
              index
            );

            lastAirOrRailProduct.arrival = AirOrRailProduct.arrival;
            // product volontary not push in filteredProducts in order to remove it from the list
          } else {
            // otherwise we should create a new bound
            const lastProduct = lodash.cloneDeep(currentProduct);
            lastAirOrRailProduct = lastProduct.Trip_Product_Air
              ? lastProduct.Trip_Product_Air
              : lastProduct.Trip_Product_Train!;

            lastAirOrRailProduct.boundStops = [];
            lastAirOrRailProduct.indexesToShare = [index];

            // new air bound added to filteredProducts
            filteredProducts.push(lastProduct);
          }
        } else {
          // if not air or rail we keep the product like it is
          filteredProducts.push(currentProduct);
        }
        return filteredProducts;
      },
      []
    );
  }

  private static pushStopInfoInAirOrRailSegment(
    airOrRailBoundProduct: ProductAir | ProductTrain,
    airOrRailStopProduct: ProductAir | ProductTrain,
    index: number
  ) {
    airOrRailBoundProduct.indexesToShare?.push(index);

    if (airOrRailStopProduct.productType === "Trip_Product_Air") {
      const airStopProduct = airOrRailStopProduct as ProductAir;
      airOrRailBoundProduct.boundStops?.push({
        carrierCode: airStopProduct.carrierCode,
        number: airStopProduct.number,
      });
    } else {
      const railStopProduct = airOrRailStopProduct as ProductTrain;
      airOrRailBoundProduct.boundStops?.push({
        category: railStopProduct.vehicle?.category,
        number: railStopProduct.vehicle?.number,
      });
    }
  }

  /**
   * The Trip title is set in the following way (in descending priority):
   * 1. Trip name; if it exists
   * 2. Trip to (comma separated Hotel destinations); if there are hotel bookings
   * 3. Trip to (comma separated Flight destinations); if there are flight bookings and no hotel
   * 4. Trip to (comma separated Train destinations); if there are train bookings and no air or hotel
   * 4. "Trip by car" litteral; if there is a Car booking and no rail, air or hotel
   *
   * Note: the return trip case (air and rail) is partially handled, meaning that the return
   * trip's return destination is not taken into account
   *
   * @param trip the Trip objects with bounds already created
   * @returns a ready to use Trip Title
   */
  public static computeTripTitle(trip: Trip) {
    if (trip.name) {
      return trip.name;
    }

    let tripTitle;
    const tripTo = "Trip to";

    trip.products?.forEach((tripProduct) => {
      // go through all the products
      if (tripProduct.hasHotel) {
        tripTitle = `${tripTo} ${this.getHotelDestinations(
          tripProduct.products
        )}`;
      } else if (tripProduct.hasAir) {
        // how to know if it's a round trip
        tripTitle = `${tripTo} ${this.getFlightlDestinations(
          tripProduct.products
        )}`;
      } else if (tripProduct.hasRail) {
        tripTitle = `${tripTo} ${this.getTrainlDestinations(
          tripProduct.products
        )}`;
      } else if (tripProduct.hasCar) {
        tripTitle = "Trip by car";
      } else {
        tripTitle = "Trip";
      }
    });
    return tripTitle;
  }

  private static getHotelDestinations(products: TripProduct[]): string {
    return products
      .reduce((destinations, product) => {
        if (product.Trip_Product_Hotel?.location?.cityName) {
          destinations.push(product.Trip_Product_Hotel.location.cityName);
        }
        return destinations;
      }, [] as string[])
      .join(titleDelimiter);
  }

  private static getFlightlDestinations(products: TripProduct[]): string {
    let firstOrigin: string | undefined;
    const allFlightDestinations = products.reduce((destinations, product) => {
      if (product.Trip_Product_Air?.arrival?.cityName) {
        destinations.add(product.Trip_Product_Air.arrival.cityName);
      }
      if (!firstOrigin && product.Trip_Product_Air?.id === "0") {
        firstOrigin = product.Trip_Product_Air.departure.cityName;
      }
      return destinations;
    }, new Set<string>());
    if (firstOrigin) {
      allFlightDestinations.delete(firstOrigin);
    }
    return Array.from(allFlightDestinations).join(titleDelimiter);
  }

  private static getTrainlDestinations(products: TripProduct[]): string {
    let firstOrigin: string | undefined;
    const allTrainDestinations = products.reduce((destinations, product) => {
      if (product.Trip_Product_Train?.arrival?.cityName) {
        destinations.add(product.Trip_Product_Train.arrival.cityName);
      }
      if (
        !firstOrigin &&
        product.Trip_Product_Train?.id === "0" &&
        product.Trip_Product_Train?.departure?.cityName
      ) {
        firstOrigin = product.Trip_Product_Train.departure.cityName;
      }
      return destinations;
    }, new Set<string>());
    if (firstOrigin) {
      allTrainDestinations.delete(firstOrigin);
    }
    return Array.from(allTrainDestinations).join(titleDelimiter);
  }
}

/* eslint-disable react/react-in-jsx-scope */
import { AddIcon, Button, Text } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import useGraph from "../../../../hooks/useGraph";
import { UPCOMING_TRIPS } from "../../constants";
import Styles from "./Header.module.scss";

function Header() {
  const history = useHistory();

  const { data: graphData } = useGraph(
    async (graph) => {
      const profile = await graph.api("/me").get();
      return { profile };
    },
    { scope: ["User.Read"] }
  );

  const userName = graphData ? ` ${graphData?.profile.givenName}` : "";
  const planATripHandler = () => {
    history.push("search-flights");
  };

  return (
    <div className={Styles.header}>
      <div className={Styles.header__welcome}>
        <Text
          content={`${UPCOMING_TRIPS.header.greeting}${userName}`}
          className={Styles["header__user-name"]}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.dashboardHeader?.title,
          })}
        />

        <Text
          content={UPCOMING_TRIPS.header.message}
          className={Styles["header__welcome-message"]}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.dashboardHeader?.title,
          })}
        />
      </div>
      <div>
        <Button
          content={UPCOMING_TRIPS.buttonText}
          primary
          fluid
          disabled={false}
          onClick={planATripHandler}
          icon={<AddIcon />}
        />
      </div>
    </div>
  );
}

export default Header;

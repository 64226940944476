/* eslint-disable no-param-reassign */
/* istanbul ignore file */

// Dependencies
import { createSlice } from "@reduxjs/toolkit";
import { SearchCriteria } from "../../components/searchFlights/components/search/search.model";
// Constants
import { TRIP_TYPE, TRIP_TYPE_TIME } from "../../utils/constants";

let INITIAL_STATE: SearchCriteria = {
  tripType: TRIP_TYPE.roundTrip.code,
  from: "",
  to: "",
  fromIataCode: "",
  toIataCode: "",
  fromCountryCode: "",
  toCountryCode: "",
  departureDate: "",
  returnDate: "",
  departureTime: { value: "6:00", type: "DEPARTURE" },
  returnTime: { value: "6:00", type: "DEPARTURE" },
  departureDestinationType: "AIRPORT",
  arrivalDestinationType: "AIRPORT",
  purpose: "",
  purposeId: "",
  tripTypeTimeInbound: TRIP_TYPE_TIME.departure.code,
  tripTypeTimeOutbound: TRIP_TYPE_TIME.departure.code,
};

const loadSavedFormData = () => {
  let formData: any = {};
  if (window.localStorage.getItem("formData")) {
    formData = JSON.parse(window.localStorage.getItem("formData") || "");
    if (formData) {
      INITIAL_STATE = { ...formData };
    }
  }
  return INITIAL_STATE;
};

export const searchFlightSlice = createSlice({
  name: "searchflight",
  initialState: loadSavedFormData(),
  reducers: {
    fillingFields: (state, action) => {
      const { payload } = action;
      state.tripType = payload.tripType;
      state.from = payload.from;
      state.to = payload.to;
      state.fromIataCode = payload.fromIataCode;
      state.toIataCode = payload.toIataCode;
      state.fromCountryCode = payload.fromCountryCode;
      state.toCountryCode = payload.toCountryCode;
      state.departureDate = new Date(payload.departureDate);
      state.returnDate = new Date(payload.returnDate);
      state.departureTime = payload.departureTime;
      state.returnTime = payload.returnTime;
      state.departureDestinationType = payload.departureDestinationType;
      state.arrivalDestinationType = payload.arrivalDestinationType;
      state.purpose = payload.purpose;
      state.purposeId = payload.purposeId;
      state.tripTypeTimeInbound = payload.tripTypeTimeInbound;
      state.tripTypeTimeOutbound = payload.tripTypeTimeOutbound;

      window.localStorage.setItem("formData", JSON.stringify(payload));
    },
  },
});

export const { fillingFields } = searchFlightSlice.actions;

export default searchFlightSlice.reducer;

// Dependencies
import { Button, Flex, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import useSearchFlightForm from "../../../../../hooks/useSearchFlightForm/useHook";
// Components
import EditPopup from "../edit-popup/edit-popup";
// Styles
import "./edit-search.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function EditSearch() {
  // Hooks
  const [showEdit, setShowEdit] = useState(false);

  const [
    {
      tripType,
      to,
      from,
      departureDate,
      departureTime,
      returnDate,
      returnTime,
    },
  ] = useSearchFlightForm();

  return (
    <Flex gap="gap.large" space="between" id="editContainer">
      <Flex column>
        <Text data-testid="innertext heading container" className="heading">
          {tripType.toString()[0].toUpperCase() +
            tripType.toString().substring(1, tripType.toString().length)}
        </Text>
        <Flex>
          <Text className="text"> {from}</Text>
          <div style={{ width: 3 }} />
          <Text className="heading">to</Text>
          <div style={{ width: 3 }} />
          <Text className="text"> {to}</Text>
        </Flex>
      </Flex>
      <Flex column>
        <Flex>
          <Text className="heading">Outbound:</Text>
          <div style={{ width: 3 }} />
          <Text className="text">{departureDate.toDateString()}</Text>
        </Flex>
        <Flex>
          {departureTime.type === "ARRIVAL" ? (
            <Text className="heading">Arriving before: </Text>
          ) : (
            <Text className="heading">Departing after: </Text>
          )}
          <div style={{ width: 3 }} />
          <Text className="text">{departureTime.value}</Text>
        </Flex>
      </Flex>
      {tripType === "roundtrip" ? (
        <Flex column>
          <Flex>
            <Text className="heading">Inbound: </Text>
            <div style={{ width: 3 }} />
            <Text className="text">{returnDate.toDateString()}</Text>
          </Flex>
          <Flex>
            {returnTime.type === "ARRIVAL" ? (
              <Text className="heading">Arriving before: </Text>
            ) : (
              <Text className="heading">Departing after: </Text>
            )}
            <div style={{ width: 3 }} />
            <Text className="text">{returnTime.value}</Text>
          </Flex>
        </Flex>
      ) : null}
      <Button
        data-testid="showEditButton"
        content="Edit Search"
        onClick={() => setShowEdit(!showEdit)}
      />
      <EditPopup showEdit={showEdit} setShowEdit={setShowEdit} />
    </Flex>
  );
}

export default EditSearch;

import {
  createMicrosoftGraphClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import { PresenceModel } from "../../models/CloseCollaboratorsModel";
import getPresenceCollaborators from "../../utils/getPresenceCollaborators";

export function changeActivitySintax(activity: any) {
  const parseActivity = activity === "OffWork" ? "Offline" : activity;

  const statusActivitySintax =
    parseActivity &&
    parseActivity
      .replace(/([A-Z])/g, " $1")
      .trim()
      .toLowerCase();
  const changedSintaxActivity =
    statusActivitySintax &&
    statusActivitySintax.charAt(0).toUpperCase() +
      statusActivitySintax.slice(1);

  return changedSintaxActivity;
}

export default async function intervalPresence(collaboratorsId: string[]) {
  let usersPresences: PresenceModel[] = [];

  try {
    const scope = ["Presence.Read.All"];
    const credential = new TeamsUserCredential();
    const client = createMicrosoftGraphClient(credential, scope);

    const presencesGraph = await getPresenceCollaborators(
      client,
      collaboratorsId
    );
    usersPresences = presencesGraph.responses.map((item: any) => {
      let statusActivity;

      if (item.status === 200) {
        statusActivity = changeActivitySintax(item.body.activity);
      }
      const userPresence: PresenceModel = {
        id: item.id,
        availability: item.status === 200 ? item.body.availability : "Unknown",
        activity: item.status === 200 ? statusActivity : "Unknown",
      };
      return userPresence;
    });
  } catch {
    usersPresences = [
      {
        availability: "Unknown",
        activity: "Unknown",
      },
    ];
  }

  return { usersPresences };
}

/* eslint-disable react/react-in-jsx-scope */
import { Dropdown, SearchIcon } from "@fluentui/react-northstar";
import {
  createMicrosoftGraphClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import { useEffect, useState } from "react";
import stylesCloseCollaborators from "../styles/close-collaborators.module.scss";
import getPresenceCollaborators from "../utils/getPresenceCollaborators";
import getFilteredSearch from "./utils/GetFilteredSearch";
import getUsersFindaContact from "./utils/GetUsersFindaContact";

export async function changeHandlerFunction(
  event: any,
  setCollaboratorsValue: any,
  collaboratorsValue: any,
  setLoadingCollaborators: any,
  client: any
) {
  setLoadingCollaborators(true);
  if (event.value) {
    const { responses } = await getPresenceCollaborators(client, [
      event.value.id,
    ]);
    const presenceState = responses[0].body;
    const newContactData: any = [
      {
        id: event.value.id,
        displayName: event.value.header,
        userPrincipalName: event.value.className,
        jobTitle: event.value.content.split(")")[1],
        image: event.value.image,
        activity: presenceState.activity,
        availability: presenceState.availability,
      },
    ];
    setCollaboratorsValue(newContactData);
  } else {
    setCollaboratorsValue(collaboratorsValue);
  }
  setLoadingCollaborators(false);

  return event;
}

export default function CloseCollaboratorsListComponents(
  countryCode: string,
  setCollaboratorsValue: any,
  collaboratorsValue: any,
  setLoadingCollaborators: any,
  context: any
) {
  const [findcontacts, setFindcontacts] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");

  const scope = ["Presence.Read.All"];
  const credential = new TeamsUserCredential();
  const client = createMicrosoftGraphClient(credential, scope);
  const noMatches = "We couldn't find any matches.";

  const onChangeHandler = async (_: any, event: any) => {
    changeHandlerFunction(
      event,
      setCollaboratorsValue,
      collaboratorsValue,
      setLoadingCollaborators,
      client
    );
  };

  const getA11ySelectionMessage = {
    onAdd: (item: any) =>
      `${item.header} selected. Press left or right arrow keys to navigate selected items.`,
    onRemove: (item: any) => `${item.header} has been removed.`,
  };

  useEffect(() => {
    let timer: any;
    setFindcontacts([]);

    if (query.length) {
      timer = setTimeout(() => {
        getUsersFindaContact(
          query,
          countryCode,
          setFindcontacts,
          setLoading,
          context
        );
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [query, countryCode, context]);

  return (
    <Dropdown
      search={(items: any) => getFilteredSearch(items, loading)}
      fluid
      clearable
      items={findcontacts}
      loading={loading}
      loadingMessage="Loading..."
      placeholder="Find a contact"
      data-testid="select"
      getA11ySelectionMessage={getA11ySelectionMessage}
      noResultsMessage={noMatches}
      a11ySelectedItemsMessage="Press Delete or Backspace to remove"
      toggleIndicator={<SearchIcon />}
      className={stylesCloseCollaborators.findaContact}
      onChange={onChangeHandler}
      onSearchQueryChange={(_e: any, { searchQuery }: any) => {
        setLoading(true);
        setQuery(searchQuery);
      }}
    />
  );
}

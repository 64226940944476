/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Breadcrumb,
  ChevronDownIcon,
  ChevronEndIcon,
  MenuButton,
  Text,
} from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import Responsive from "../../../../common/components/responsive/Responsive";

import { UPCOMING_TRIP_DETAIL } from "../../constants";
import { composeTripName } from "../../trip-utils";
import Styles from "./Breadcrumb.module.scss";

function TripBreadcrumb({ trip }: any) {
  const history = useHistory();
  const breadcrumbClickHandler = () => {
    history.push("/travel");
  };

  const tripName = composeTripName(trip, "Trip to");

  const breadcrumbMobile = (
    <div className={Styles.breadcrumb__mobileMenuWrapper}>
      <Text
        content={tripName}
        aria-label="Click button"
        className={Styles.breadcrumb__mobileMenu}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.currentPage,
        })}
      />
      <ChevronDownIcon
        size="small"
        className={Styles.breadcrumb__divider}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.path,
        })}
      />
    </div>
  );

  return (
    <Box
      className={Styles.breadcrumb}
      styles={(theme) => ({
        background: theme.theme.siteVariables.tripBreadcrumb?.background,
        borderBottomColor:
          theme.theme.siteVariables.tripBreadcrumb?.mobileBorderBottom,
      })}
    >
      <Responsive forMinSize="desktopSmall">
        <div className={Styles.desktop}>
          <Breadcrumb aria-label="breadcrumb">
            <Breadcrumb.Item
              onClick={breadcrumbClickHandler}
              className={Styles["breadcrumb__my-trips"]}
              styles={(theme) => ({
                color: theme.theme.siteVariables.tripBreadcrumb?.path,
              })}
            >
              {UPCOMING_TRIP_DETAIL.breadcrumb.previousPage}
            </Breadcrumb.Item>
            <Breadcrumb.Divider>
              <ChevronEndIcon
                size="small"
                className={Styles.breadcrumb__divider}
                styles={(theme) => ({
                  color: theme.theme.siteVariables.tripBreadcrumb?.path,
                })}
              />
            </Breadcrumb.Divider>
            <Breadcrumb.Item
              style={{ gap: "4px" }}
              className={Styles.breadcrumb__trip}
              role="document"
              styles={(theme) => ({
                color: theme.theme.siteVariables.tripBreadcrumb?.currentPage,
              })}
            >
              <span className={Styles["breadcrumb__destination-name"]}>
                {tripName}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Responsive>
      <Responsive forMaxSize="tablet">
        <MenuButton
          className={Styles.mobile}
          trigger={breadcrumbMobile}
          menu={["Travel"]}
          on="click"
          onMenuItemClick={breadcrumbClickHandler}
        />
      </Responsive>

      {/* <div>
        <Button
          content={UPCOMING_TRIP_DETAIL.breadcrumb.buttons.share}
          disabled={false}
          text
          onClick={shareMyTripHandler}
          icon={<ShareGenericIcon />}
          style={{ height: "1rem" }}
        />
        <Button
          content={UPCOMING_TRIP_DETAIL.breadcrumb.buttons.manage}
          disabled={false}
          text
          onClick={manageMyTripHandler}
          icon={<IconOpen />}
          style={{ height: "1rem" }}
        />
      </div> */}
    </Box>
  );
}

export default TripBreadcrumb;

import {
  Alert,
  CheckmarkCircleIcon,
  InfoIcon,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { DONT_MERGE_TYPE, MERGE_TYPE } from "../../../../utils/constants";

interface AlertInterface {
  mergeType: string | null;
  setMergeType: React.Dispatch<React.SetStateAction<string | null>>;
}

function MergeAlert({ mergeType, setMergeType }: AlertInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.alert",
  });
  const totalMergedExpenses = Number(
    localStorage.getItem("totalMergedExpenses")
  );

  return (
    <Alert
      id="alert__message"
      success={mergeType === MERGE_TYPE}
      info={mergeType === DONT_MERGE_TYPE}
      icon={mergeType === MERGE_TYPE ? <CheckmarkCircleIcon /> : <InfoIcon />}
      content={
        mergeType &&
        (mergeType === MERGE_TYPE ? (
          <b>
            {t("successMergeMessage", {
              number: totalMergedExpenses,
            })}
          </b>
        ) : (
          <b>{t("cancelMergeMessage")}</b>
        ))
      }
      dismissible
      dismissAction={{
        "aria-label": "close",
        onClick: () => {
          setMergeType(null);
        },
      }}
    />
  );
}

export default MergeAlert;

import React from "react";
import { Provider } from "react-redux";
import store from "../../../../store/Store";
import CarSegmentContent from "../car-segment-content/CarSegmentContent";
// eslint-disable-next-line import/no-cycle
import { SegmentFunctionData } from "../TripPanelContent";

function CarSegmentFunction(props: SegmentFunctionData) {
  const { product, trip, index } = props;
  if (product.Trip_Product_Car) {
    return (
      <Provider store={store}>
        <CarSegmentContent
          key={`car-segment-${product.Trip_Product_Car.confirmationNumber}`}
          tripId={trip}
          segmentId={index.toString()}
          carSegment={product.Trip_Product_Car}
        />
      </Provider>
    );
  }
  return null;
}

export default CarSegmentFunction;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// Dependencies
import { ShiftActivityIcon } from "@fluentui/react-icons-northstar";
import {
  Button,
  Datepicker,
  Flex,
  FlexItem,
  Header,
  Input,
  Label,
  Popup,
  RadioGroup,
  RadioGroupItemProps,
  Segment,
  Text,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import useSearchFlightForm from "../../../../../../hooks/useSearchFlightForm/useHook";
// Services
import AutoCompleteService from "../../../../../../services/search-flight/autocomplete.service";

import { TRIP_TYPE, TRIP_TYPE_TIME } from "../../../../../../utils/constants";
import AirportOrCity from "../../../search/AirportOrCity/AirpotOrCity";
import CustomSlider from "../../../search/customSlider/customSlider";
// Components
import CytricFromDropDown from "../../../search/cytric-fromDropDown/cytric-fromDropDown";
import CytricToDropDown from "../../../search/cytric-toDropDown/cytric-toDropDown";
import { LocationItem, LocationResponse } from "../../../search/search.model";
// Styles
import "./edit-searchform.scss";

const styles = {
  formDirection: "formDirection",
  formSchedule: "formSchedule",
  formPurpose: "formPurpose",
  arrowIcon: "arrowIcon",
  formMargins: "formMargins",
  formMarginsEditSearch: "formMarginsEditSearch",
  breadcrumb: "breadcrumb",
  breadcrumbLink: "breadcrumbLink",
  breadcrumbBorder: "breadcrumbBorder",
  warningLink: "warningLink",
  warningText: "warningText",
  headerTitle: "headerTitle",
  subheader: "subheader",
  warningAlert: "warningAlert",
  footerButtons: "footerButtons",
  cityIcon: "cityIcon",
  cityName: "cityName",
  dropdownFlag: "dropdownFlag",
  iataCode: "iataCode",
  airportIcon: "airportIcon",
  airportName: "airportName",
};

const optionsTrip = [
  {
    key: TRIP_TYPE.roundTrip.code,
    value: TRIP_TYPE.roundTrip.code,
    label: TRIP_TYPE.roundTrip.label,
  },
  {
    key: TRIP_TYPE.oneWay.code,
    value: TRIP_TYPE.oneWay.code,
    label: TRIP_TYPE.oneWay.label,
  },
];

const optionsTripTime = [
  {
    key: "1",
    value: TRIP_TYPE_TIME.departure.code,
    label: TRIP_TYPE_TIME.departure.label,
  },
  {
    key: "2",
    value: TRIP_TYPE_TIME.arrival.code,
    label: TRIP_TYPE_TIME.arrival.label,
  },
];

export default function EditSearchForm({ setShowEdit }: { setShowEdit: any }) {
  // Services
  const autoCompleteService = new AutoCompleteService();

  // Hooks

  const [data, { dispatchFillingFields }] = useSearchFlightForm();

  const [state, setState] = useState(data);

  const {
    tripType,
    tripTypeTimeInbound,
    tripTypeTimeOutbound,
    from,
    to,
    departureDate,
    returnDate,
    departureTime,
    returnTime,
  } = state;

  const [value, setValue] = useState(departureTime.value);
  const [valueInbound, setValueInbound] = useState(returnTime.value);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);

  // Functions
  const airportsAndCitiesFromResponse: UseQueryResult<any | undefined> =
    useQuery(
      ["airportsAndCitiesFrom", state.from],
      () => autoCompleteService.getAirportsAndCitiesv2(state.from),
      {
        refetchOnWindowFocus: false,
      }
    );

  const airportsAndCitiesToResponse: UseQueryResult<any | undefined> = useQuery(
    ["airportsAndCitiesTo", state.to],
    () => autoCompleteService.getAirportsAndCitiesv2(state.to),
    {
      refetchOnWindowFocus: false,
    }
  );

  const fromResponse: LocationResponse =
    airportsAndCitiesFromResponse?.data?.data;
  const toResponse: LocationResponse = airportsAndCitiesToResponse?.data?.data;

  const createOptions = (airportsAndCities: LocationItem[]) => {
    const options: any = [];
    airportsAndCities.forEach((city: LocationItem, index: number) => {
      if (city?.associated) {
        options.push({
          name: `${city.location.name} (${city.location.iataCode})`,
          iatacode: city.location.iataCode,
          countrycode: city.location.address.countryCode,
          key: index,
          city: true,
          content: <AirportOrCity city={city} />,
        });

        city?.associated.forEach((airport) =>
          options.push({
            name: `${airport.name} (${airport.iataCode})`,
            iatacode: airport.iataCode,
            countrycode: airport.address.countryCode,
            key: `${index} ${airport.name}`,
            city: false,

            content: <AirportOrCity city={airport} />,
          })
        );
      } else {
        options.push({
          name: `${city.location.name} (${city.location.iataCode})`,
          iatacode: city.location.iataCode,
          countrycode: city.location.address.countryCode,
          key: index,
          city: false,
          content: <AirportOrCity city={city} />,
        });
      }
    });

    return options;
  };

  useEffect(() => {
    setFromOptions([]);
    if (
      airportsAndCitiesFromResponse.data?.data?.data &&
      !airportsAndCitiesFromResponse.isLoading &&
      !airportsAndCitiesFromResponse.isError
    ) {
      setFromOptions(createOptions(fromResponse?.data));
    }
  }, [airportsAndCitiesFromResponse.data?.data]);

  useEffect(() => {
    setToOptions([]);
    if (
      airportsAndCitiesToResponse.data?.data?.data &&
      !airportsAndCitiesToResponse.isLoading &&
      !airportsAndCitiesToResponse.isError
    ) {
      setToOptions(createOptions(toResponse?.data));
    }
  }, [airportsAndCitiesToResponse.data?.data]);

  const handleTypeChange = (_: any, props: RadioGroupItemProps | undefined) =>
    setState({ ...state, tripType: props?.value });

  const handleTypeTimeInboundChange = (
    _: any,
    props: RadioGroupItemProps | undefined
  ) => setState({ ...state, tripTypeTimeInbound: props?.value });

  const handleTypeTimeOutboundChange = (
    _: any,
    props: RadioGroupItemProps | undefined
  ) => setState({ ...state, tripTypeTimeOutbound: props?.value });

  const handleDepDateChange = (_: any, props: any | undefined) =>
    setState({
      ...state,
      departureDate: props?.value ? props?.value : null,
    });

  const handleRetDateChange = (_: any, props: any | undefined) =>
    setState({
      ...state,
      returnDate: props?.value ? props?.value : null,
    });

  const handleDepTimeChange = () => {
    setState({
      ...state,
      departureTime: { value, type: tripTypeTimeOutbound },
    });
  };

  const handleRetTimeChange = () => {
    setState({
      ...state,
      returnTime: { value: valueInbound, type: tripTypeTimeInbound },
    });
  };

  const handleApplyClick = () => {
    dispatchFillingFields(state);
    setShowEdit(false);
  };

  const handleCancelClick = () => {
    setShowEdit(false);
  };

  const getDefaultDate = (date: string | number | Date) =>
    date ? new Date(date) : undefined;

  const getSearchButtonEnabled =
    !from ||
    !to ||
    !departureDate ||
    !departureTime ||
    !tripType ||
    (tripType === TRIP_TYPE.roundTrip.code
      ? !returnDate || !returnTime
      : false);

  const fromCity = from.split(" ")[0];
  const toCity = to.split(" ")[0];

  const formLabelStyle = (theme: any) => ({
    backgroundColor: theme.theme.siteVariables?.formLabel.background,
    color: theme.theme.siteVariables?.formLabel.color,
  });

  const formDropdownStyle = (theme: any) => ({
    backgroundColor: theme.theme.siteVariables?.formDropdown.background,
    color: theme.theme.siteVariables?.formDropdown.color,
  });
  return (
    <Flex
      id="editSearchFormContainer"
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Flex
        column
        className={styles.formMargins}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables?.editSearchContainer.background,
        })}
      >
        <Text className="formMargins__header">Edit Search</Text>
        <form action="/search">
          <Segment
            className="formMargins__tripType"
            styles={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <Flex gap="gap.medium">
              <Flex.Item>
                <RadioGroup
                  checkedValue={tripType}
                  items={optionsTrip}
                  onCheckedValueChange={handleTypeChange}
                />
              </Flex.Item>
            </Flex>
          </Segment>

          <Segment
            className="body"
            styles={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <Flex
              gap="gap.small"
              padding="padding.medium"
              className={styles.formDirection}
            >
              <Flex.Item size="size.half">
                <div>
                  <Flex column gap="gap.small" vAlign="start" hAlign="start">
                    <Label styles={formLabelStyle} content="From" />
                    <CytricFromDropDown
                      data-testid="cytric-fromDropDown"
                      options={fromOptions}
                      isLoading={airportsAndCitiesFromResponse.isLoading}
                      state={state}
                      setState={setState}
                    />
                  </Flex>
                </div>
              </Flex.Item>
              <Flex.Item size="size.half">
                <Flex column gap="gap.small" vAlign="start" hAlign="start">
                  <Label styles={formLabelStyle} content="To" />
                  <CytricToDropDown
                    data-testid="cytric-toDropDown"
                    options={toOptions}
                    isLoading={airportsAndCitiesToResponse.isLoading}
                    state={state}
                    setState={setState}
                  />
                </Flex>
              </Flex.Item>
            </Flex>

            <Flex
              gap="gap.small"
              padding="padding.medium"
              className={styles.formSchedule}
              wrap
            >
              <Flex.Item size="size.half">
                <Flex gap="gap.small" wrap>
                  <Flex.Item>
                    <Text className="dateHeader">Date range</Text>
                  </Flex.Item>
                  <Flex.Item>
                    <Flex
                      id="outboundDate"
                      column
                      gap="gap.small"
                      vAlign="start"
                      hAlign="start"
                    >
                      <Label styles={formLabelStyle} content="Outbound" />
                      <Datepicker
                        inputOnly
                        styles={formDropdownStyle}
                        placeholder="Select departure date"
                        minDate={new Date()}
                        defaultSelectedDate={getDefaultDate(departureDate)}
                        onDateChange={handleDepDateChange}
                      />
                    </Flex>
                  </Flex.Item>
                  {TRIP_TYPE.roundTrip.code === tripType && (
                    <Flex.Item>
                      <Flex
                        id="inboundDate"
                        column
                        gap="gap.small"
                        vAlign="start"
                        hAlign="start"
                      >
                        <Label styles={formLabelStyle} content="Inbound" />
                        <Datepicker
                          inputOnly
                          styles={formDropdownStyle}
                          placeholder="Select return date"
                          minDate={getDefaultDate(departureDate)}
                          defaultSelectedDate={getDefaultDate(returnDate)}
                          selectedDate={
                            getDefaultDate(returnDate)! <
                            getDefaultDate(departureDate)!
                              ? undefined
                              : getDefaultDate(returnDate)
                          }
                          onDateChange={handleRetDateChange}
                        />
                      </Flex>
                    </Flex.Item>
                  )}
                </Flex>
              </Flex.Item>
              <FlexItem size="size.half">
                <Flex gap="gap.small" wrap className="hourContainer">
                  <Flex.Item grow>
                    <Text className="hourContainer__header">Select Timing</Text>
                  </Flex.Item>
                  <Flex.Item grow>
                    <Text className="hourContainer__info">{`Outbound -- ${fromCity} to ${toCity}`}</Text>
                  </Flex.Item>
                  <Flex.Item grow>
                    <Flex column gap="gap.small" vAlign="start" hAlign="start">
                      <Label
                        styles={formLabelStyle}
                        content={
                          tripTypeTimeOutbound ===
                          TRIP_TYPE_TIME.departure.code ? (
                            <p>Departing after</p>
                          ) : (
                            <p>Arriving before</p>
                          )
                        }
                      />
                      <Popup
                        position="below"
                        onOpenChange={handleDepTimeChange}
                        trapFocus
                        trigger={
                          <Input
                            id="outboundTime"
                            icon={<ShiftActivityIcon />}
                            styles={formDropdownStyle}
                            fluid
                            value={`${value}`}
                            placeholder="00:00"
                          />
                        }
                        content={
                          <>
                            <Header as="h4">
                              Select timing for your booking
                            </Header>
                            <RadioGroup
                              checkedValue={tripTypeTimeOutbound}
                              items={optionsTripTime}
                              onCheckedValueChange={
                                handleTypeTimeOutboundChange
                              }
                            />
                            {tripTypeTimeOutbound ===
                            TRIP_TYPE_TIME.departure.code ? (
                              <p>Departing after</p>
                            ) : (
                              <p>Arriving before</p>
                            )}
                            <CustomSlider
                              id="0"
                              value={value}
                              setValue={(props: any) => setValue(`${props}:00`)}
                              departureDate={departureDate}
                              departureTime={departureTime}
                              returnDate={returnDate}
                            />
                          </>
                        }
                      />
                    </Flex>
                  </Flex.Item>
                  {TRIP_TYPE.roundTrip.code === tripType && (
                    <>
                      <Flex.Item grow>
                        <Text className="hourContainer__info">{`Inbound -- ${toCity} to ${fromCity}`}</Text>
                      </Flex.Item>
                      <Flex.Item grow>
                        <Flex
                          column
                          gap="gap.small"
                          vAlign="start"
                          hAlign="start"
                        >
                          <Label
                            styles={formLabelStyle}
                            content={
                              tripTypeTimeInbound ===
                              TRIP_TYPE_TIME.departure.code ? (
                                <p>Departing after</p>
                              ) : (
                                <p>Arriving before</p>
                              )
                            }
                          />
                          <Popup
                            position="below"
                            onOpenChange={handleRetTimeChange}
                            trapFocus
                            trigger={
                              <Input
                                icon={<ShiftActivityIcon />}
                                id="inboundTime"
                                styles={formDropdownStyle}
                                fluid
                                value={`${valueInbound}`}
                                placeholder="00:00"
                              />
                            }
                            content={
                              <>
                                <Header as="h4">
                                  Select timing for your booking
                                </Header>
                                <RadioGroup
                                  checkedValue={tripTypeTimeInbound}
                                  items={optionsTripTime}
                                  onCheckedValueChange={
                                    handleTypeTimeInboundChange
                                  }
                                />
                                {tripTypeTimeInbound ===
                                TRIP_TYPE_TIME.departure.code ? (
                                  <p>Departing after</p>
                                ) : (
                                  <p>Arriving before</p>
                                )}
                                <CustomSlider
                                  id="1"
                                  value={valueInbound}
                                  setValue={(props: any) =>
                                    setValueInbound(`${props}:00`)
                                  }
                                  departureDate={departureDate}
                                  departureTime={departureTime}
                                  returnDate={returnDate}
                                />
                              </>
                            }
                          />
                        </Flex>
                      </Flex.Item>
                    </>
                  )}
                </Flex>
              </FlexItem>
            </Flex>
          </Segment>
          <Segment
            className="footerButtons"
            styles={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <Flex space="between">
              <Button content="Cancel" onClick={handleCancelClick} secondary />
              <Button
                content="Apply"
                onClick={handleApplyClick}
                primary
                disabled={getSearchButtonEnabled}
              />
            </Flex>
          </Segment>
        </form>
      </Flex>
    </Flex>
  );
}

import { Flex, InfoIcon } from "@fluentui/react-northstar";
import React from "react";
import { getThemeName, TeamsTheme } from "../ChartTypes";

export default function ChartErrorState({ theme }: any) {
  let textColor;
  switch (getThemeName(theme)) {
    case TeamsTheme.Dark:
      textColor = theme.siteVariables.colors.red["300"];
      break;
    case TeamsTheme.HighContrast:
      textColor = theme.siteVariables.colors.white;
      break;
    case TeamsTheme.Default:
    default:
      textColor = theme.siteVariables.colors.red["400"];
      break;
  }
  return (
    <Flex
      styles={{
        height: "100%",
        minHeight: "14rem",
        backgroundColor: theme.siteVariables.colorScheme.grey.background,
        color: textColor,
      }}
      vAlign="center"
      hAlign="center"
    >
      <InfoIcon outline styles={{ marginRight: ".5rem" }} /> Could not load data
    </Flex>
  );
}

import React from "react";
import IconProps from "./Icon.model";
import * as Icons from "./Icons";

function Icon({ name, styles, size }: IconProps) {
  const IconComponent = Object.values(Icons).find(
    (icon) => icon.displayName === name
  );

  if (!IconComponent) {
    return null;
  }

  return <IconComponent styles={styles} size={size} />;
}

Icon.defaultProps = {
  styles: () => {},
};

export default Icon;

/*
Usage example: 
<Icon name="Arrow" />
<Icon name="Arrow" size="large" />
*/

import { VehicleCar24Filled } from "@fluentui/react-icons";
import {
  Card,
  cardSelectableBehavior,
  Checkbox,
  Flex,
  FlexItem,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import CarSegment from "../../../../common/models/ProductCar";
import DateHelper from "../../../../helpers/DateHelper";
import { isSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSelector";
import { updateSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/StoreHooks";
import "./CarSegmentContent.scss";

interface CarSegmentContentProps {
  carSegment: CarSegment;
  tripId: string;
  segmentId: string;
}

function CarSegmentContent(props: CarSegmentContentProps) {
  const { carSegment, tripId, segmentId } = props;

  const carImg = <VehicleCar24Filled className="segment-icon" />;

  const checked: boolean = useAppSelector((state) =>
    isSegmentSelected(state, {
      productType: carSegment.productType,
      id: segmentId,
    })
  );
  const dispatch = useAppDispatch();

  const header = <Text weight="bold">{carSegment.serviceProviderName}</Text>;

  const checkbox = <Checkbox data-testid="air-checkbox" checked={checked} />;

  const content = (
    <Text>
      {carSegment.pickup.location ? carSegment.pickup?.location.name : ""}
    </Text>
  );

  return (
    <Flex column>
      <Text weight="bold">
        {DateHelper.formatDateTimeToDateRange(
          carSegment.pickup.dateTime,
          carSegment.dropoff.dateTime
        )}
      </Text>
      <Card
        accessibility={cardSelectableBehavior}
        selected={checked}
        className="card-segment"
        onClick={() =>
          dispatch(
            updateSegmentSelected({
              tripId,
              segmentId: { productType: carSegment.productType, id: segmentId },
              isSelected: !checked,
            })
          )
        }
      >
        <Card.Header fitted>
          <Flex gap="gap.small" vAlign="center">
            {carImg}
            <Flex column>
              {header}
              {content}
            </Flex>
            <FlexItem push>{checkbox}</FlexItem>
          </Flex>
        </Card.Header>
      </Card>
    </Flex>
  );
}

export default CarSegmentContent;

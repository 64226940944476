import { Image } from "@fluentui/react-northstar";
import React from "react";
import { IDestinationImageProps } from "./DestinationImage.model";
import Styles from "./DestinationImage.module.scss";

function DestinationImage({ trip }: IDestinationImageProps) {
  const handleImageError = (e: React.BaseSyntheticEvent) => {
    e.currentTarget.src = "./upcoming-trips-mock-images/default_image.png";
  };

  return (
    <div className={Styles.container}>
      <Image
        className={Styles.container__image}
        src={
          trip?.destinationPicture?.[0] ??
          "./upcoming-trips-mock-images/default_image.png"
        }
        data-testid="image"
        onError={handleImageError}
      />
    </div>
  );
}

export default DestinationImage;

const checkboxComponentStyle = {
  checkbox: {
    backgroundRepeat: "repeat",
  },
};

export const light = {
  styles: {
    backgroundColor: "#F5F5F5",
    bodyBackground: "#F5F5F5",
    color: "#000000",
    bodyColor: "#000000",
    subTitle: "#292929",
  },
  theme: {
    componentStyles: {
      Checkbox: checkboxComponentStyle,
    },
    siteVariables: {
      welcome: {
        subtitle: "#292929",
      },
      dashboardHeader: {
        title: "#242424",
      },
      tripBreadcrumb: {
        background: "#F5F5F5",
        path: "#424242",
        currentPage: "#242424",
        mobileBorderBottom: "#E0E0E0",
      },
      widgetContainer: { widget: "#FFFFFF" },
      upcomingTrips: {
        header: {
          title: "#242424",
        },
        title: "#242424",
      },
      tripCard: {
        background: "#FFFFFF",
        divider: "#E0E0E0",
        title: "#242424",
      },
      tabNavItem: { tabActive: "#6264A7", tabInactive: "#605E5C" },
      segmentIcons: {
        icons: "#1f74e2",
        text: "#616161",
      },
      acordion: { arrowIcon: "#424242", arrowIconHover: "#5B5FC7" },
      bookingDetail: {
        background: "#FFFFFF",
        borderColor: "#F0F0F0",
        lighterText: "#484644",
        providerLogoBorder: "#EDEBE9",
        accordionFooterOpen: "#252423",
        accordionFooterClosed: "#000000",
      },
      simplifiedBookingDetails: {
        title: "#242424",
        subtitle: "#424242",
        imagePlaceholder: `transparent url('${process.env.PUBLIC_URL}/upcoming-trips-mock-images/simplifiedPlaceholderLight.svg') no-repeat center center`,
      },
      extendedDetails: {
        circleColor: "#6264A7",
        duration: "#616161",
        destinationBorder: "#E9EAF6",
        stop: {
          background: "#E5E5F1",
          borderColor: "#BDBDE6",
          color: "#585A96",
        },
        tripData: "#616161",
      },
      status: {
        "to-be-approved": {
          background: "#e0e0e0",
          borderColor: "#c7c7c7",
          color: "#292929",
        },
        preapproved: {
          background: "#e5e5f1",
          borderColor: "#bdbde6",
          color: "#585a96",
        },
        approved: {
          background: "#e7f8d1",
          borderColor: "#237b4b",
          color: "#237b4b",
        },
        rejected: {
          background: "#fcf4f6",
          borderColor: "#f3d6d8",
          color: "#c4314b",
        },
        "rejected-not-canceled": {
          background: "#fcf4f6",
          borderColor: "#f3d6d8",
          color: "#c4314b",
        },
        "rejected-not-cancelled": {
          background: "#fcf4f6",
          borderColor: "#f3d6d8",
          color: "#c4314b",
        },
        unknown: {
          background: "#F5F5F5",
          borderColor: "#D1D1D1",
          color: "#616161",
        },
      },
      formLabel: {
        background: "transparent",
      },
      formDropdown: {
        background: "#FFFFFF",
      },
      tagSelected: {
        background: "#E5EFFC",
        color: "#1F74E2",
      },
      tag: {
        background: "#EBEBEB",
      },
      tagIconSelected: {
        color: "#1F74E2",
      },
      tagIcon: {
        color: "#616161",
      },
      co2Container: {
        background: "#F5F5F5",
      },
      editSearchContainer: {
        background: "#FFFFFF",
      },
    },
  },
};
export const dark = {
  styles: {
    // backgroundColor: "#1F1F1F",
    bodyBackground: "#1F1F1F",
    color: "#FFFFFF",
    bodyColor: "#FFFFFF",
    subTitle: "#D6D6D6",
  },
  theme: {
    componentStyles: {
      Provider: {
        root: {
          // To override the default background color
          backgroundColor: `#1F1F1F !important`,
        },
      },
      Checkbox: checkboxComponentStyle,
    },
    siteVariables: {
      welcome: {
        subtitle: "#D6D6D6",
      },
      dashboardHeader: {
        title: "#FFFFFF",
      },
      tripBreadcrumb: {
        background: "#292929",
        path: "##D6D6D6",
        currentPage: "#FFFFFF",
        mobileBorderBottom: "#0F0F0F",
      },
      widgetContainer: { widget: "#292929" },
      upcomingTrips: {
        header: {
          title: "#FFFFFF",
        },
        title: "#FFFFFF",
      },
      tripCard: {
        background: "#292929",
        divider: "#0F0F0F",
        title: "#FFFFFF",
      },
      tabNavItem: { tabActive: "#9EA2FF", tabInactive: "#EBEBEB" },
      segmentIcons: {
        icons: "#A5C7F3",
        text: "#ADADAD",
      },
      acordion: { arrowIcon: "#D6D6D6", arrowIconHover: "#7F85F5" },
      bookingDetail: {
        background: "#3D3D3D",
        borderColor: "#292929",
        lighterText: "#FFFFFF",
        providerLogoBorder: "#292929",
        accordionFooterOpen: "#FFFFFF",
        accordionFooterClosed: "#FFFFFF",
      },
      simplifiedBookingDetails: {
        title: "#FFFFFF",
        subtitle: "#D6D6D6",
        imagePlaceholder: `transparent url('${process.env.PUBLIC_URL}/upcoming-trips-mock-images/simplifiedPlaceholderDark.svg') no-repeat center center`,
      },
      extendedDetails: {
        circleColor: "#FFFFFF",
        duration: "#FFFFFF",
        destinationBorder: "#292929",
        stop: {
          background: "#323348",
          borderColor: "#464775",
          color: "#C7C9FF",
        },
        tripData: "#FFFFFF",
      },
      status: {
        "to-be-approved": {
          background: "#3D3D3D",
          borderColor: "#616161",
          color: "#FFFFFF",
        },
        preapproved: {
          background: "#323348",
          borderColor: "#464775",
          color: "#C7C9FF",
        },
        approved: {
          background: "#0D2E0D",
          borderColor: "#032003",
          color: "#92C353",
        },
        rejected: {
          background: "#3E1F25",
          borderColor: "#1E040A",
          color: "#F9526B",
        },
        "rejected-not-canceled": {
          background: "#3E1F25",
          borderColor: "#1E040A",
          color: "#F9526B",
        },
        "rejected-not-cancelled": {
          background: "#3E1F25",
          borderColor: "#1E040A",
          color: "#F9526B",
        },
        unknown: {
          background: "#1F1F1F",
          borderColor: "#616161",
          color: "#ADADAD",
        },
      },
      formLabel: {
        background: "transparent",
        color: "white",
      },
      formDropdown: {
        background: "#0A0A0A",
        color: "#FFFFFF",
      },
      tagSelected: {
        background: "#1F74E2",
      },
      tag: {
        background: "#0A0A0A",
        color: "#616161",
      },
      tagIconSelected: {
        color: "#E8F1FC",
      },
      tagIcon: {
        color: "#ADADAD",
      },
      co2Container: {
        background: "#1F1F1F",
      },
      editSearchContainer: {
        background: "#282829",
      },
    },
  },
};

/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
import { Dialog, Flex, Text } from "@fluentui/react-northstar";
import React, { Fragment, useState } from "react";
import convertSentence from "../../../../../../helpers/StringHelper";
import CheckIcon from "./checked.png";
import Co2Icon from "./co2.png";
import "./flight-information.scss";
import ServicesPopup from "./services-popup/services-popup";

const equals = (a: any, b: any) =>
  a.length === b.length && a.every((v: any, i: any) => v === b[i]);

const co2EmissionParser = (co2Emission: any) => {
  const co2EmissionInfo = co2Emission.map((emision: any) => {
    const pound = 2.2046226218;

    if (emision.weight === 0) {
      return `${convertSentence(emision?.cabin?.travelClass)}: N/A`;
    }
    const convertedToPounds = Math.trunc(emision.weight * pound);
    return `${convertSentence(
      emision?.cabin?.travelClass
    )}: ${convertedToPounds}lbrs`;
  });

  return co2EmissionInfo.join(" - ").replace(/[^a-zA-Z0-9-]/g, " ");
};

function FlightInformation({
  flightDictionary,
  flightBounds,
  pricingOptions,
  segments,
  Co2Emissions,
  fromIataCode,
  toIataCode,
}: {
  flightDictionary: any;
  flightBounds: any;
  pricingOptions: any;
  segments: any;
  Co2Emissions: any;
  fromIataCode: any;
  toIataCode: any;
}) {
  const [open, setOpen] = useState(false);

  const flightSegments = segments.map((sm: any) => {
    const currentSegment = flightBounds.find((cs: any) => sm === cs.id);
    return currentSegment;
  });

  let familyServices: any;
  let firstService: any;
  let secondService: any;

  return (
    <Fragment>
      <Flex column className="flightInformation">
        {flightSegments.map((sm: any, index: any) => {
          const departureTime = new Date(
            sm.departure?.localDateTime
          ).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          });

          const arrivalTime = new Date(
            sm?.arrival.localDateTime
          ).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          });

          const flightDuration = sm.duration.slice(2).toLowerCase();

          const hoursDuration = flightDuration.substring(
            0,
            flightDuration.indexOf("h") + 1
          );

          const minutesDuration = flightDuration.substring(
            flightDuration.indexOf("h") + 1,
            flightDuration.length
          );

          const firstTerminal = sm.departure.terminal
            ? `Terminal: ${sm.departure.terminal},`
            : "";
          const secondTerminal = sm.arrival.terminal
            ? `Terminal: ${sm.arrival.terminal},`
            : "";

          const airlineName = flightDictionary.find(
            (e: any) => e.iataCode === sm.carrierCode
          ).commonName;

          const ticketInformation = sm?.electronicTicket
            ? "Electronic tickets available"
            : "No electronic tickets";

          if (pricingOptions) {
            familyServices = pricingOptions.find((po: any) =>
              equals(po.segmentsId, segments)
            )?.fareDetails[index]?.fareFamily?.fareFamilyServices;

            if (familyServices) {
              let [firstIncluded, secondIncluded] = familyServices.filter(
                (s: any) => s.type === "INCLUDED"
              );

              if (firstIncluded) {
                firstIncluded = firstIncluded.description
                  .split(" ")
                  .map(
                    (e: string) =>
                      e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
                  )
                  .join(" ");
                firstService = firstIncluded;
              }

              if (secondIncluded) {
                secondIncluded = secondIncluded.description
                  .split(" ")
                  .map(
                    (e: string) =>
                      e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
                  )
                  .join(" ");
                secondService = secondIncluded;
              }
            }
          }

          return (
            <Fragment key={index}>
              <Flex className="container" key={index}>
                <Flex column className="verticalSymbolContainer">
                  <Flex className="filledCircle" />
                  <Flex className="verticalLine" />
                  <Flex className="emptyCircle" />
                </Flex>
                <Flex column className="flightInformation">
                  <Flex>
                    <Text className="darkText">{departureTime}</Text>
                    <Flex className="spacer" />
                    <Text className="darkText">{`${sm.departure.name}, ${firstTerminal} (${sm.departure.iataCode})`}</Text>
                  </Flex>
                  <Flex>
                    <Text className="lightText">{`Duration: ${hoursDuration} ${minutesDuration}`}</Text>
                  </Flex>
                  <Flex>
                    <Text className="darkText">{arrivalTime}</Text>
                    <Flex className="spacer" />
                    <Text className="darkText">{`${sm.arrival.name}, ${secondTerminal} (${sm.arrival.iataCode})`}</Text>
                  </Flex>
                </Flex>
                <Flex className="airlineInformation" column>
                  <Flex column>
                    <Text className="darkText">{`${airlineName}, ${sm.flightNumber}`}</Text>
                    <Text className="lightText">{sm.aircraftName}</Text>
                  </Flex>
                  <Flex>
                    <Text className="darkText">{ticketInformation}</Text>
                  </Flex>
                </Flex>
              </Flex>
              {pricingOptions && familyServices ? (
                <Flex className="servicesContainer">
                  <Flex className="checkContainer">
                    <img
                      className="checkIcon"
                      src={CheckIcon}
                      alt="check-icon"
                    />
                    <Text className="serviceText">Services included</Text>
                  </Flex>
                  <Flex className="servicesSubContainer">
                    {firstService && (
                      <Text className="lightText">{firstService}</Text>
                    )}
                    <Flex className="middleDot" />
                    {secondService && (
                      <Text className="lightText serviceOption">
                        {secondService}
                      </Text>
                    )}
                  </Flex>
                  <Text
                    className="moreAboutServices"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOpen(true);
                    }}
                  >
                    See more about services
                  </Text>
                  <Dialog
                    open={open}
                    confirmButton="Close"
                    onConfirm={(e: any) => {
                      e.stopPropagation();
                      setOpen(false);
                    }}
                    content={<ServicesPopup familyServices={familyServices} />}
                  />
                </Flex>
              ) : (
                <Flex className="servicesContainer">
                  <Text className="noServiceText">No Services included</Text>
                </Flex>
              )}
            </Fragment>
          );
        })}
      </Flex>
      <Flex
        className="co2InfoContainer"
        styles={(theme) => ({
          backgroundColor: theme.theme.siteVariables?.co2Container.background,
        })}
      >
        <Flex>
          <img
            className="co2InfoContainer__image"
            src={Co2Icon}
            alt="Co2 Icon"
          />
          <Text className="co2InfoContainer__text">{`CO2 emissions from ${fromIataCode} to ${toIataCode}:`}</Text>
          <Flex styles={{ width: 4 }} />
          <Text className="co2InfoContainer__co2-text">{`${co2EmissionParser(
            Co2Emissions
          )}`}</Text>
        </Flex>
      </Flex>
    </Fragment>
  );
}

export default FlightInformation;

import { Flex } from "@fluentui/react-northstar";
import React from "react";
import Trip from "../../../common/models/Trip";
import TripProduct from "../../../common/models/TripProduct";
/* eslint-disable import/no-cycle */
import AirSegmentFunction from "./functions/AirSegmentFunction";
import CarSegmentFunction from "./functions/CarSegmentFunction";
import HotelSegmentFunction from "./functions/HotelSegmentFunction";
import TrainSegmentFunction from "./functions/TrainSegmentFunction";

interface TripPanelContentProps {
  trip: Trip;
}
export interface SegmentFunctionData {
  trip: string;
  product: TripProduct;
  index: number;
}

function TripPanelContent({ trip }: TripPanelContentProps) {
  const { id } = trip;

  return (
    <Flex gap="gap.medium" column>
      {trip.products?.map((productTrip) =>
        productTrip.products?.map((segment, index: number) => {
          const sectionValues: SegmentFunctionData = {
            trip: id,
            product: segment,
            index,
          };
          if (segment.Trip_Product_Air) {
            return AirSegmentFunction(sectionValues);
          }
          if (segment.Trip_Product_Hotel) {
            return HotelSegmentFunction(sectionValues);
          }
          if (segment.Trip_Product_Train) {
            return TrainSegmentFunction(sectionValues);
          }
          if (segment.Trip_Product_Car) {
            return CarSegmentFunction(sectionValues);
          }
          return null;
        })
      )}
    </Flex>
  );
}

export default TripPanelContent;

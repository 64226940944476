import {
  ChevronDown20Regular,
  ChevronUp20Regular,
} from "@fluentui/react-icons";
import {
  Divider,
  Flex,
  Image,
  ItemLayout,
  Segment,
  Text,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import Trip from "../../../common/models/Trip";
import DateHelper from "../../../helpers/DateHelper";
import { getSelectedTripId } from "../../../store/segment-selected/SegmentSelectedSelector";
import {
  resetSegmentSelected,
  updateTripSelected,
} from "../../../store/segment-selected/SegmentSelectedSlice";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import TripPanelContent from "../trip-panel-content/TripPanelContent";
import "./AccordionTrip.scss";

interface TripPanelTitleProps {
  trip: Trip;
}

function AccordionTrip({ trip }: TripPanelTitleProps) {
  const [isActive, setIsActive] = useState(false);
  const tripId = trip.id;
  const tripChecked: string = useAppSelector((state) =>
    getSelectedTripId(state)
  );
  const dispatch = useAppDispatch();

  // use effect on load
  useEffect(() => {
    setIsActive(tripChecked === tripId);
  }, [tripChecked, tripId]);

  function collapseExpand() {
    if (!isActive) {
      dispatch(updateTripSelected({ selectedTripId: tripId }));
    } else {
      dispatch(updateTripSelected({ selectedTripId: "" }));
      dispatch(resetSegmentSelected());
    }
  }

  const contentTitle = (
    <Text color="lightgray">
      {DateHelper.formatDateTimeToDateRange(
        trip.startDate ? trip.startDate : "",
        trip.endDate ? trip.endDate : ""
      )}
    </Text>
  );

  const imgTitle = (
    <Image
      id="city-img"
      className="city-image"
      src={trip.destinationPicture ? trip.destinationPicture[1] : ""}
      alt="City Image"
    />
  );

  const header = (
    <Text weight="bold" size="large">
      {trip.name}
    </Text>
  );

  return (
    <Flex column className="card-trip">
      <Segment key={`card-${trip.id}`}>
        <ItemLayout
          className="segment-panel"
          onClick={() => collapseExpand()}
          media={imgTitle}
          endMedia={
            isActive ? (
              <ChevronUp20Regular data-testid="collapse-icon" />
            ) : (
              <ChevronDown20Regular data-testid="expand-icon" />
            )
          }
          header={header}
          content={contentTitle}
        />

        {isActive && (
          <Flex column>
            <Divider className="divider-header-body" />
            <TripPanelContent trip={trip} />
          </Flex>
        )}
      </Segment>
    </Flex>
  );
}

export default AccordionTrip;

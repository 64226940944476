import React from "react";
import { Chart } from "../../../common/components/chart/Chart";
import {
  EChartTypes,
  IChartData,
} from "../../../common/components/chart/ChartTypes";

interface GraphicInterface {
  info: IChartData;
  currency: string;
}

function Graphic({ info, currency }: GraphicInterface) {
  return (
    <div style={{ height: "380px" }}>
      <Chart
        data={info}
        title=""
        type={EChartTypes.BarHorizontal}
        currency={currency}
      />
    </div>
  );
}

export default Graphic;

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/react-in-jsx-scope */

import { IResponsiverProps } from "./Responsive.model";
import useScreenSize from "./useScreenSize";

const mobile = 320;
const tablet = 640;
const desktopSmall = 956;
const desktopLarge = 1372;

const sizeMap = (size: string) => {
  switch (size) {
    case "mobile":
      return mobile;
    case "tablet":
      return tablet;
    case "desktopSmall":
      return desktopSmall;
    case "desktopLarge":
      return desktopLarge;
    default:
      return desktopLarge;
  }
};

const sizeMapMax = (size: string) => {
  switch (size) {
    case "mobile":
      return tablet;
    case "tablet":
      return desktopSmall;
    case "desktopSmall":
      return desktopLarge;
    default:
      return 99999;
  }
};
export default function Responsive({
  children,
  forRange,
  forMinSize,
  forMaxSize,
  customInterval,
}: IResponsiverProps) {
  const screenSize = useScreenSize();

  if (forRange) {
    switch (forRange) {
      case "mobile":
        if (screenSize < tablet) {
          return <>{children}</>;
        }
        break;

      case "tablet":
        if (screenSize >= tablet && screenSize < desktopSmall) {
          return <>{children}</>;
        }
        break;

      case "desktopSmall":
        if (screenSize >= desktopSmall && screenSize < desktopLarge) {
          return <>{children}</>;
        }
        break;

      case "desktopLarge":
        if (screenSize >= desktopLarge) {
          return <>{children}</>;
        }
        break;

      default:
        return null;
    }
  } else if (forMinSize) {
    switch (forMinSize) {
      case "mobile":
        if (screenSize >= mobile) {
          return <>{children}</>;
        }
        break;

      case "tablet":
        if (screenSize >= tablet) {
          return <>{children}</>;
        }
        break;

      case "desktopSmall":
        if (screenSize >= desktopSmall) {
          return <>{children}</>;
        }
        break;

      case "desktopLarge":
        if (screenSize >= desktopLarge) {
          return <>{children}</>;
        }
        break;

      default:
        return null;
    }
  } else if (forMaxSize) {
    switch (forMaxSize) {
      case "mobile":
        if (screenSize < tablet) {
          return <>{children}</>;
        }
        break;

      case "tablet":
        if (screenSize < desktopSmall) {
          return <>{children}</>;
        }
        break;

      case "desktopSmall":
        if (screenSize < desktopLarge) {
          return <>{children}</>;
        }
        break;

      case "desktopLarge":
        if (screenSize < 99999) {
          return <>{children}</>;
        }
        break;

      default:
        return null;
    }
  } else if (customInterval) {
    if (
      screenSize >= sizeMap(customInterval.min) &&
      screenSize < sizeMapMax(customInterval.max)
    ) {
      return <>{children}</>;
    }
    return null;
  }

  return null;
}

Responsive.defaultProps = {
  forRange: null,
  forMinSize: null,
  forMaxSize: null,
  customInterval: null,
};

/*
Usage example: 

<Responsive forRange="desktopSmall">
  <div>desktopSmall</div>
</Responsive>

<Responsive forMinSize="desktopSmall">
  <div>desktopSmall</div>
</Responsive>

<Responsive forMaxSize="desktopSmall">
  <div>desktopSmall</div>
</Responsive>

<Responsive customInterval={{ min: "tablet", max: "desktopSmall" }}>
  <div>customInterval!</div>
</Responsive>

*/

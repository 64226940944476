/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Provider } from "react-redux";
import store from "../../../../store/Store";
import RailSegmentContent from "../rail-segment-content/RailSegmentContent";
// eslint-disable-next-line import/no-cycle
import { SegmentFunctionData } from "../TripPanelContent";

function TrainSegmentFunction(props: SegmentFunctionData) {
  const { product, trip, index } = props;
  if (product.Trip_Product_Train) {
    return (
      <Provider store={store}>
        <RailSegmentContent
          key={`rail-segment-${product.Trip_Product_Train.id}`}
          tripId={trip}
          segmentId={index.toString()}
          railSegment={product.Trip_Product_Train}
        />
      </Provider>
    );
  }
  return null;
}

export default TrainSegmentFunction;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckboxProps,
  CloseIcon,
  Dialog,
  Loader,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DONT_MERGE_TYPE, MERGE_TYPE } from "../../../../../utils/constants";
import DialogContent from "../dialog-content/DialogContent";
import DialogHeader from "../dialog-header/DialogHeader";
import "../Dialog.scss";
import { DuplicatePairsInterface } from "../DuplicatesInterfaces";
import { getDuplicatesExpenses } from "../PopupRequests";

interface DialogPropsInterface {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMergeType: React.Dispatch<React.SetStateAction<string | null>>;
  setDidMergeAction: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogPopup({
  openDialog,
  setOpenDialog,
  setMergeType,
  setDidMergeAction,
}: DialogPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.popup",
  });
  const [duplicatePairs, setDuplicatePairs] = useState<
    DuplicatePairsInterface[]
  >([]);

  const [checkboxesSelected, setCheckboxesSelected] = useState({});
  const [hasSelectedPairs, setHasSelectedPairs] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // *TO BE REMOVED WHEN CONNECTED TO REAL BE
  const getPairsFromStorage = localStorage.getItem("duplicatePairs");

  const getDuplicatesList = async () => {
    setIsLoading(true);
    const list = await getDuplicatesExpenses();
    if (list) {
      setDuplicatePairs(list);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (getPairsFromStorage) {
      setDuplicatePairs(JSON.parse(getPairsFromStorage));
    } else {
      getDuplicatesList();
    }
  }, []);

  useEffect(() => {
    if (duplicatePairs) {
      const localCheckboxesSelected: any = {};
      duplicatePairs.forEach((pair) => {
        localCheckboxesSelected[pair.id] = false;
      });
      setCheckboxesSelected(localCheckboxesSelected);
    }
  }, [duplicatePairs]);

  useEffect(() => {
    if (checkboxesSelected) {
      const pairsNotSelected = Object.values(checkboxesSelected).filter(
        (value) => value === false
      );
      setHasSelectedPairs(
        pairsNotSelected.length < Object.keys(checkboxesSelected).length &&
          pairsNotSelected.every((value) => value === false)
      );
    }
  }, [checkboxesSelected]);

  function closeDialog() {
    setOpenDialog(false);
  }

  const manageCheckboxes = (
    e: React.FormEvent<HTMLElement>,
    checked: CheckboxProps | undefined,
    pairId: number
  ) => {
    setCheckboxesSelected({
      ...checkboxesSelected,
      [pairId]: checked?.checked,
    });
  };

  const getSelectedPairsId = () => {
    const selectedCheckboxesArray = Object.entries(checkboxesSelected);

    const selectedPairsId: string[] = [];
    selectedCheckboxesArray.forEach((pair) => {
      if (pair[1] === true || selectedCheckboxesArray.length === 1) {
        selectedPairsId.push(pair[0]);
      }
    });
    return selectedPairsId;
  };

  const calculateTotalExpensesMerged = (selectedIds: string[]) => {
    const expensesSelectedForMerge = duplicatePairs.filter((pair) =>
      selectedIds.includes(pair.id.toString())
    );
    let totalMergedExpenses = 0;
    expensesSelectedForMerge.forEach((expense) => {
      totalMergedExpenses += expense.duplicateExpenses.length;
    });
    localStorage.setItem("totalMergedExpenses", totalMergedExpenses.toString());
  };

  // *TO BE REMOVED WHEN CONNECTED TO REAL BE
  const getRemainingPairsAfterMerge = (selectedIds: string[]) => {
    const remainingPairs = duplicatePairs.filter(
      (pair) => !selectedIds.includes(pair.id.toString())
    );
    localStorage.setItem("duplicatePairs", JSON.stringify(remainingPairs));
  };

  const onMergeSelectedDuplicates = async (mergeType: string) => {
    const selectedIds = getSelectedPairsId();
    if (selectedIds.length === 0) {
      duplicatePairs.forEach((pair) => {
        selectedIds.push(String(pair.id));
      });
    }
    if (mergeType === MERGE_TYPE) {
      calculateTotalExpensesMerged(selectedIds);
    }
    getRemainingPairsAfterMerge(selectedIds);

    // * TO BE IMPLEMENTED WHEN CONNECTED TO THE REAL BACKEND
    // * MERGE API & PUT for duplicates counter
    // * POST for merging => send the ids for pairs
    // * CALL BE API for Don't merge
  };

  function onMergeButtonsClick(mergeType: string) {
    setMergeType(mergeType);
    closeDialog();
    onMergeSelectedDuplicates(mergeType);
    setDidMergeAction(true);
  }

  return (
    <Dialog
      data-testid="popup__dialog"
      header={<DialogHeader />}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: closeDialog,
      }}
      content={
        isLoading ? (
          <Loader />
        ) : (
          <DialogContent
            duplicatePairs={duplicatePairs}
            manageCheckboxes={manageCheckboxes}
          />
        )
      }
      cancelButton={{
        content: t("dontMergeButton", {
          buttonText: DONT_MERGE_TYPE,
          interpolation: { escapeValue: false },
        }),
        className: "popup__dontmerge__btn",
      }}
      confirmButton={{
        content: t("mergeButton", {
          buttonText: MERGE_TYPE,
        }),
        disabled: duplicatePairs.length > 1 && !hasSelectedPairs,
        className: "popup__merge__btn",
      }}
      open={openDialog}
      onCancel={() => onMergeButtonsClick(DONT_MERGE_TYPE)}
      onConfirm={() => {
        onMergeButtonsClick(MERGE_TYPE);
      }}
      closeOnOutsideClick={false}
    />
  );
}

export default DialogPopup;

/* eslint-disable react/react-in-jsx-scope */
import CloseCollaborators from "../../../close-collaborators/CloseCollaborators";
import { hasOnlyItineraryTypes } from "../../trip-utils";
import WidgetContainer from "../WidgetContainer/WidgetContainer";
import SimplifiedBookingDetails from "../Widgets/SimplifiedBookingDetails/SimplifiedBookingDetails";
import { IWidgetsGridProps } from "./WidgetsGrid.model";
import Styles from "./WidgetsGrid.module.scss";

function WidgetsGrid({ trip }: IWidgetsGridProps) {
  const city = trip?.destinationCities ? trip?.destinationCities[0] : "";
  const countryCode = trip?.destinationCountryCode
    ? trip?.destinationCountryCode[0]
    : "";

  return (
    <div className={Styles["widgets-grid-container"]}>
      <WidgetContainer>
        <SimplifiedBookingDetails />
      </WidgetContainer>
      {countryCode && !hasOnlyItineraryTypes(trip, "car") && (
        <CloseCollaborators destination={{ city, countryCode }} />
      )}
    </div>
  );
}

export default WidgetsGrid;

/* istanbul ignore file */
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ITelemetryProviderProps } from "./TelemetryModel";
import { appInsightService } from "./TelemetryService";

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking
 * that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<
  ITelemetryProviderProps & RouteComponentProps
> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    initialized: false,
  };

  componentDidMount() {
    const { history, enabled, instrumentationKey, after } = this.props;
    const { initialized } = this.state;
    if (
      !initialized &&
      Boolean(instrumentationKey) &&
      Boolean(history) &&
      Boolean(enabled)
    ) {
      appInsightService.initialize(instrumentationKey, history);
      this.setState({ initialized: true });
    }

    if (after) {
      after();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(
  withAITracking(appInsightService.reactPlugin, TelemetryProvider)
);

/* eslint-disable react/react-in-jsx-scope */
import { createSvgIcon } from "@fluentui/react-northstar";

export const Air = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 17 18" className={classes.svg}>
      <g>
        <path d="M7.75462 7.28958L7.06954 1.80623C6.98994 1.16938 7.44167 0.58858 8.07852 0.508974L8.15045 0.502246L8.31864 0.5C8.91459 0.5 9.45401 0.852791 9.69288 1.39879L12.2049 7.14109L14.9568 7.05025C15.9847 7.01599 16.8533 7.7833 16.9614 8.78967L16.9716 8.93501L16.9727 9C16.9727 10.0774 16.0992 10.9508 15.0135 10.9508L12.2058 10.858L9.69288 16.6012C9.45401 17.1472 8.91459 17.5 8.31864 17.5H8.22266C7.58085 17.5 7.06057 16.9797 7.06057 16.3379C7.06057 16.2897 7.06357 16.2416 7.06954 16.1938L7.75463 10.7094L5.61228 10.6378L4.65485 12.3139C4.41243 12.7382 3.96127 13 3.47266 13C2.92037 13 2.47266 12.5523 2.47266 12V10.5336L2.31141 10.5215C1.55375 10.4252 0.972656 9.77859 0.972656 9C0.972656 8.22141 1.55375 7.57483 2.31058 7.47851L2.47266 7.46635V6C2.47266 5.44772 2.92037 5 3.47266 5C3.96127 5 4.41243 5.26181 4.65483 5.68603L5.6122 7.36121L7.75462 7.28958ZM8.31864 1.5L8.20255 1.50125C8.11373 1.51236 8.05072 1.59336 8.06182 1.68222L8.88269 8.25242L5.04311 8.38079L3.7866 6.18219C3.72223 6.06953 3.60242 6 3.47266 6V8.43165L2.48876 8.46641C2.20098 8.476 1.97266 8.71206 1.97266 9C1.97266 9.28794 2.20098 9.524 2.48876 9.53359L3.47266 9.56639V12L3.54895 11.9919C3.64812 11.9705 3.73509 11.908 3.78656 11.8179L5.04303 9.61821L8.88268 9.74658L8.06057 16.3379C8.06057 16.4274 8.13314 16.5 8.22266 16.5H8.31864C8.51729 16.5 8.6971 16.3824 8.77673 16.2004L11.5615 9.83598L15.0218 9.95083C15.547 9.95083 15.9727 9.52513 15.9727 9L15.9721 8.96832C15.9546 8.44348 15.515 8.0322 14.99 8.0497L11.5604 8.16291L8.77672 1.79959C8.6971 1.6176 8.51729 1.5 8.31864 1.5Z" />
      </g>
    </svg>
  ),
  displayName: "Air",
});

export const Arrow = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 16 8" className={classes.svg}>
      <g>
        <path d="M15 9.15527e-05C15.1354 9.15527e-05 15.2526 0.0495707 15.3516 0.148529C15.4505 0.247487 15.5 0.364675 15.5 0.500092C15.5 0.635508 15.4505 0.752696 15.3516 0.851654L8.35156 7.85165C8.2526 7.95061 8.13542 8.00009 8 8.00009C7.86458 8.00009 7.7474 7.95061 7.64844 7.85165L0.648438 0.851654C0.549479 0.752696 0.5 0.635508 0.5 0.500092C0.5 0.364675 0.549479 0.247487 0.648438 0.148529C0.747396 0.0495707 0.864583 9.15527e-05 1 9.15527e-05C1.13542 9.15527e-05 1.2526 0.0495707 1.35156 0.148529L8 6.78915L14.6484 0.148529C14.7474 0.0495707 14.8646 9.15527e-05 15 9.15527e-05Z" />
      </g>
    </svg>
  ),
  displayName: "Arrow",
});

export const Car = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 17 15" className={classes.svg}>
      <g>
        <path d="M12.833 0C14.0248 0 15.0508 0.841149 15.2845 2.00971L15.4817 3H16.2227C16.6024 3 16.9161 3.28215 16.9658 3.64823L16.9727 3.75C16.9727 4.1297 16.6905 4.44349 16.3244 4.49315L16.2227 4.5H15.7817L15.8938 5.05991C16.5172 5.24191 16.9727 5.81777 16.9727 6.5V11.5C16.9727 12.3284 16.3011 13 15.4727 13H14.9727V14C14.9727 14.5523 14.5249 15 13.9727 15C13.4204 15 12.9727 14.5523 12.9727 14V13H4.97266V14C4.97266 14.5523 4.52494 15 3.97266 15C3.42037 15 2.97266 14.5523 2.97266 14V13H2.47266C1.64423 13 0.972656 12.3284 0.972656 11.5V6.5C0.972656 5.81777 1.42812 5.24191 2.05156 5.05991L2.16266 4.5H1.72266C1.30844 4.5 0.972656 4.16421 0.972656 3.75C0.972656 3.33579 1.30844 3 1.72266 3H2.46266L2.66081 2.00971C2.89452 0.841149 3.92056 0 5.11226 0H12.833ZM15.4727 6H2.47266C2.19651 6 1.97266 6.22386 1.97266 6.5V11.5C1.97266 11.7761 2.19651 12 2.47266 12H15.4727C15.7488 12 15.9727 11.7761 15.9727 11.5V6.5C15.9727 6.22386 15.7488 6 15.4727 6ZM4.97266 8C5.52494 8 5.97266 8.44772 5.97266 9C5.97266 9.55228 5.52494 10 4.97266 10C4.42037 10 3.97266 9.55228 3.97266 9C3.97266 8.44772 4.42037 8 4.97266 8ZM12.9727 8C13.5249 8 13.9727 8.44772 13.9727 9C13.9727 9.55228 13.5249 10 12.9727 10C12.4204 10 11.9727 9.55228 11.9727 9C11.9727 8.44772 12.4204 8 12.9727 8ZM12.833 1H5.11226C4.39724 1 3.78162 1.50469 3.64139 2.20583L3.08256 5H14.8628L14.3039 2.20583C14.1637 1.50469 13.5481 1 12.833 1Z" />
      </g>
    </svg>
  ),
  displayName: "Car",
});

export const Train = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 15 16" className={classes.svg}>
      <g>
        <path d="M5.9729 11C5.9729 11.5523 5.52519 12 4.9729 12C4.42062 12 3.9729 11.5523 3.9729 11C3.9729 10.4477 4.42062 10 4.9729 10C5.52519 10 5.9729 10.4477 5.9729 11ZM10.9729 12C11.5252 12 11.9729 11.5523 11.9729 11C11.9729 10.4477 11.5252 10 10.9729 10C10.4206 10 9.9729 10.4477 9.9729 11C9.9729 11.5523 10.4206 12 10.9729 12ZM6.4729 2C6.19676 2 5.9729 2.22386 5.9729 2.5C5.9729 2.77614 6.19676 3 6.4729 3H9.4729C9.74904 3 9.9729 2.77614 9.9729 2.5C9.9729 2.22386 9.74904 2 9.4729 2H6.4729ZM0.9729 3C0.9729 1.34315 2.31605 0 3.9729 0H11.9729C13.6298 0 14.9729 1.34315 14.9729 3V11C14.9729 12.5492 13.7986 13.8242 12.2915 13.9833L14.6761 15.0431C14.9284 15.1552 15.0421 15.4507 14.9299 15.7031C14.8178 15.9554 14.5223 16.0691 14.27 15.9569L9.86691 14H6.07889L1.67585 15.9569C1.42351 16.0691 1.12803 15.9554 1.01588 15.7031C0.903723 15.4507 1.01737 15.1552 1.26971 15.0431L3.65429 13.9833C2.14723 13.8242 0.9729 12.5492 0.9729 11V3ZM9.98433 13H11.9729C13.0775 13 13.9729 12.1046 13.9729 11V9H1.9729V11C1.9729 12.1046 2.86833 13 3.9729 13H5.96147C5.96884 12.9998 5.9762 12.9998 5.98354 13H9.96226C9.9696 12.9998 9.97696 12.9998 9.98433 13ZM1.9729 3V8H13.9729V3C13.9729 1.89543 13.0775 1 11.9729 1H3.9729C2.86833 1 1.9729 1.89543 1.9729 3Z" />
      </g>
    </svg>
  ),
  displayName: "Train",
});

export const Hotel = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 17 14" className={classes.svg}>
      <g>
        <path d="M12.4727 0C13.7981 0 14.8827 1.03154 14.9673 2.33562L14.9727 2.5L14.9731 5.0501C16.0597 5.27086 16.8902 6.19636 16.9669 7.32867L16.9727 7.5V13.5C16.9727 13.7761 16.7488 14 16.4727 14C16.2272 14 16.023 13.8231 15.9807 13.5899L15.9727 13.5V11H1.97266V13.5C1.97266 13.7761 1.7488 14 1.47266 14C1.2272 14 1.02305 13.8231 0.980712 13.5899L0.972656 13.5V7.5C0.972656 6.29032 1.83182 5.28131 2.97323 5.04989L2.97266 2.5C2.97266 1.17452 4.00419 0.0899613 5.30828 0.00531769L5.47266 0H12.4727ZM14.4727 6H3.47266C2.69296 6 2.05221 6.59489 1.97952 7.35554L1.97266 7.5V10H15.9727V7.5C15.9727 6.7203 15.3778 6.07955 14.6171 6.00687L14.4727 6ZM12.4727 1H5.47266C4.69296 1 4.05221 1.59489 3.97952 2.35554L3.97266 2.5V5H4.97266V4.5C4.97266 4.22386 5.19651 4 5.47266 4H7.97266C8.2488 4 8.47266 4.22386 8.47266 4.5V5H9.47266V4.5C9.47266 4.22386 9.69651 4 9.97266 4H12.4727C12.7488 4 12.9727 4.22386 12.9727 4.5V5H13.9727V2.5C13.9727 1.7203 13.3778 1.07955 12.6171 1.00687L12.4727 1Z" />
      </g>
    </svg>
  ),
  displayName: "Hotel",
});

// Presence Icons

export const StatusAvailable = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <g>
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7069 6.70739L7.70361 10.7074C7.51595 10.8949 7.26147 11.0002 6.99619 11C6.7309 10.9998 6.47655 10.8943 6.28912 10.7065L4.29233 8.70654C3.90212 8.3157 3.90262 7.68254 4.29346 7.29233C4.6843 6.90212 5.31746 6.90262 5.70767 7.29346L6.99765 8.58551L10.2932 5.29261C10.6839 4.90224 11.3171 4.9025 11.7074 5.29318C12.0978 5.68386 12.0975 6.31703 11.7069 6.70739Z" />
      </g>
    </svg>
  ),
  displayName: "StatusAvailable",
});

export const StatusAway = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <g>
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.5 4.5V7.51937L10.6247 9.21913C11.056 9.56414 11.1259 10.1934 10.7809 10.6247C10.4359 11.056 9.80657 11.1259 9.37531 10.7809L6.87531 8.78087C6.63809 8.5911 6.5 8.30378 6.5 8V4.5C6.5 3.94772 6.94772 3.5 7.5 3.5C8.05229 3.5 8.5 3.94772 8.5 4.5Z" />
      </g>
    </svg>
  ),
  displayName: "StatusAway",
});

export const StatusBusy = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <g>
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" />
      </g>
    </svg>
  ),
  displayName: "StatusBusy",
});

export const StatusDoNotDisturb = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <g>
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.24902 7H10.7499C11.3022 7 11.7499 7.44772 11.7499 8C11.7499 8.55229 11.3022 9 10.7499 9H5.24902C4.69674 9 4.24902 8.55229 4.24902 8C4.24902 7.44772 4.69674 7 5.24902 7Z" />
      </g>
    </svg>
  ),
  displayName: "StatusDoNotDisturb",
});

export const StatusOffline = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <g>
        <path d="M10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L9.41421 8L10.7071 9.29289C11.0976 9.68342 11.0976 10.3166 10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29289 10.7071L8 9.41421L6.70711 10.7071C6.31658 11.0976 5.68342 11.0976 5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289L6.58579 8L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L8 6.58579L9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z" />
      </g>
    </svg>
  ),
  displayName: "StatusOffline",
});

export const StatusUnknown = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <g>
        <path d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" />
      </g>
    </svg>
  ),
  displayName: "StatusUnknown",
});
export const Cheapest = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
    >
      <g>
        <path d="M8 4C8 5.10457 7.10457 6 6 6C4.89543 6 4 5.10457 4 4C4 2.89543 4.89543 2 6 2C7.10457 2 8 2.89543 8 4ZM7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5C6.55228 5 7 4.55228 7 4ZM0 1.25C0 0.559644 0.559644 0 1.25 0H10.75C11.4404 0 12 0.559644 12 1.25V6.75C12 7.44036 11.4404 8 10.75 8H1.25C0.559644 8 0 7.44036 0 6.75V1.25ZM1.25 1C1.11193 1 1 1.11193 1 1.25V2H1.5C1.77614 2 2 1.77614 2 1.5V1H1.25ZM1 6.75C1 6.88807 1.11193 7 1.25 7H2V6.5C2 6.22386 1.77614 6 1.5 6H1V6.75ZM3 6.5V7H9V6.5C9 5.67157 9.67157 5 10.5 5H11V3H10.5C9.67157 3 9 2.32843 9 1.5V1H3V1.5C3 2.32843 2.32843 3 1.5 3H1V5H1.5C2.32843 5 3 5.67157 3 6.5ZM10 7H10.75C10.8881 7 11 6.88807 11 6.75V6H10.5C10.2239 6 10 6.22386 10 6.5V7ZM11 2V1.25C11 1.11193 10.8881 1 10.75 1H10V1.5C10 1.77614 10.2239 2 10.5 2H11ZM3.5 10C2.8334 10 2.26836 9.56517 2.07304 8.96369C2.21179 8.98756 2.35444 9 2.5 9H10.75C11.9926 9 13 7.99264 13 6.75V2.08535C13.5826 2.29127 14 2.84689 14 3.5V6.75C14 8.54493 12.5449 10 10.75 10H3.5Z" />
      </g>
    </svg>
  ),
  displayName: "Cheapest",
});

export const Shortest = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
    >
      <path d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1ZM5.50153 3C5.74699 3 5.95114 3.17688 5.99347 3.41012L6.00153 3.5V6H7.5C7.77614 6 8 6.22386 8 6.5C8 6.74546 7.82312 6.94961 7.58988 6.99194L7.5 7H5.50153C5.25607 7 5.05192 6.82312 5.00958 6.58988L5.00153 6.5V3.5C5.00153 3.22386 5.22538 3 5.50153 3Z" />
    </svg>
  ),
  displayName: "Shortest",
});

export const Greenest = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
    >
      <path
        id="1234"
        d="M4.49995 11.0209V12.4991C4.49995 12.7752 4.72381 12.9991 4.99995 12.9991C5.27609 12.9991 5.49995 12.7752 5.49995 12.4991V11.0209C6.57883 10.9105 7.628 10.442 8.45465 9.61539C10.4315 7.63851 10.3495 4.40898 8.27478 2.53503L5.83824 0.334258C5.36211 -0.0957947 4.63779 -0.0957938 4.16166 0.334258L1.72512 2.53503C-0.349581 4.40898 -0.431625 7.63851 1.54525 9.61539C2.3719 10.442 3.42107 10.9105 4.49995 11.0209ZM7.60448 3.27713C9.25454 4.76752 9.31979 7.33603 7.74754 8.90828C7.11626 9.53956 6.32181 9.90822 5.49995 10.0143V5.49976C5.49995 5.22362 5.27609 4.99976 4.99995 4.99976C4.72381 4.99976 4.49995 5.22362 4.49995 5.49976V10.0143C3.67809 9.90822 2.88364 9.53956 2.25236 8.90828C0.680109 7.33603 0.74536 4.76752 2.39541 3.27713L4.83195 1.07636C4.92737 0.990173 5.07253 0.990173 5.16795 1.07636L7.60448 3.27713Z"
      />
    </svg>
  ),
  displayName: "Greenest",
});

export const Ranked = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
    >
      <path d="M6 5C6.27614 5 6.5 5.22386 6.5 5.5V8.5C6.5 8.77614 6.27614 9 6 9C5.72386 9 5.5 8.77614 5.5 8.5V5.5C5.5 5.22386 5.72386 5 6 5ZM6.00001 4.24907C6.41369 4.24907 6.74905 3.91371 6.74905 3.50003C6.74905 3.08635 6.41369 2.751 6.00001 2.751C5.58633 2.751 5.25098 3.08635 5.25098 3.50003C5.25098 3.91371 5.58633 4.24907 6.00001 4.24907ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1Z" />
    </svg>
  ),
  displayName: "Ranked",
});

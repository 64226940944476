import { Text } from "@fluentui/react-northstar";
import React from "react";
import DateUtils from "../../../utils/date.utils";
import { UPCOMING_TRIPS } from "../constants";
import Header from "./Header/Header";
import TripCard from "./TripCard/TripCard";
import { ITrip, IUpcomingTripsProps } from "./UpcomingTrips.model";
import Styles from "./UpcomingTrips.module.scss";

function UpcomingTrips({ data }: IUpcomingTripsProps) {
  const sortedTrips: ITrip[] = [...data].sort(
    (a, b): number =>
      DateUtils.getDateInMiliseconds(a.startDate) -
      DateUtils.getDateInMiliseconds(b.startDate)
  );

  return (
    <div className={Styles["main-container"]}>
      <Header />
      <div>
        <Text
          as="div"
          content={UPCOMING_TRIPS.title}
          className={Styles["list-title"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.upcomingTrips?.title,
          })}
        />
        <div className={Styles["grid-container"]}>
          {sortedTrips.map((trip: ITrip) => (
            <TripCard trip={trip} key={trip.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default UpcomingTrips;

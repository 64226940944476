/* eslint-disable react/react-in-jsx-scope */
import { Menu, Table } from "@fluentui/react-northstar";
import stylesCloseCollaborators from "../styles/close-collaborators.module.scss";
import getTheme from "../utils/getTheme";
import CloseCollaboratorsNoItems from "./close-collaborators-table-components/CloseCollaboratorsNoItems";
import {
  header,
  optionItemsCollaborator,
  role,
} from "./close-collaborators-table-components/CloseCollaboratorsTableComponents";
import User from "./close-collaborators-table-components/CloseCollaboratorsUser";
import UsersPresence from "./close-collaborators-user-status/UsersPresence";

export const getUser = (data: any, index: any, presence: any) => ({
  content: (
    <User
      id={data.id}
      displayName={data.displayName}
      image={data.image}
      presence={{
        availability:
          presence.collaboratorAvailability.find((cc: any) => cc.id === data.id)
            ?.availability || data.availability,
        activity:
          presence.collaboratorAvailability.find((cc: any) => cc.id === data.id)
            ?.activity || data.activity,
      }}
    />
  ),
  key: `${index}-user`,
  className: stylesCloseCollaborators.firstCol,
});
const getRole = (data: any, index: any) => ({
  content: role(data.jobTitle),
  key: `${index}-role`,
  className: stylesCloseCollaborators.roleCol,
});
export const getMenu = (data: any, index: any) => {
  const items = optionItemsCollaborator(
    data.displayName,
    data.userPrincipalName
  );
  return {
    content: <Menu iconOnly items={items} />,
    key: `${index}-options`,
    className: stylesCloseCollaborators.options,
  };
};

export default function CloseCollaboratorsTable({
  collaborators,
  context,
}: any) {
  const customTheme = getTheme(context);
  const presence = UsersPresence(collaborators.map((collab: any) => collab.id));
  const rows = collaborators.map((collaborator: any, index: any) => ({
    key: `${index}-row`,
    items: [
      getUser(collaborator, index, presence),
      getRole(collaborator, index),
      getMenu(collaborator, index),
    ],
  }));

  const themeName = customTheme.theme.name;

  return (
    <div>
      {collaborators.length ? (
        <Table
          header={header}
          rows={rows}
          aria-label="Close Collaborators list"
          className={[
            themeName === "white"
              ? stylesCloseCollaborators.white
              : stylesCloseCollaborators.dark,
            stylesCloseCollaborators.table,
          ].join(" ")}
        />
      ) : (
        <CloseCollaboratorsNoItems />
      )}
    </div>
  );
}

import stylesCloseCollaborators from "../../styles/close-collaborators.module.scss";

export default function statusIconClass(availability: string) {
  switch (availability) {
    case "Available":
    case "AvailableIdle":
      return {
        icon: {
          class: stylesCloseCollaborators.StatusAvailable,
          icon: "StatusAvailable",
        },
      };
    case "DoNotDisturb":
      return {
        icon: {
          class: stylesCloseCollaborators.StatusDoNotDisturb,
          icon: "StatusDoNotDisturb",
        },
      };
    case "Busy":
    case "BusyIdle":
      return {
        icon: {
          class: stylesCloseCollaborators.StatusBusy,
          icon: "StatusBusy",
        },
      };
    case "BeRightBack":
    case "Away":
      return {
        icon: {
          class: stylesCloseCollaborators.StatusAway,
          icon: "StatusAway",
        },
      };
    case "Offline":
      return {
        icon: {
          class: stylesCloseCollaborators.StatusOffline,
          icon: "StatusOffline",
        },
      };
    default:
      return {
        icon: {
          class: stylesCloseCollaborators.StatusUnknown,
          icon: "StatusUnknown",
        },
      };
  }
}

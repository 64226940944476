import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";

export default async function getPresenceCollaborators(
  client: any,
  collabList: any
) {
  const batchRequestContent = new MicrosoftGraph.BatchRequestContent([]);
  let userPresenceRequestStep: MicrosoftGraph.BatchRequestStep;

  for (let index = 0; index < collabList.length; index += 1) {
    userPresenceRequestStep = {
      id: collabList[index],
      request: new Request(`/users/${collabList[index]}/presence`, {
        method: "GET",
      }),
    };
    batchRequestContent.addRequest(userPresenceRequestStep);
  }

  let presencesGraph;

  try {
    const contentPresence = await batchRequestContent.getContent();
    presencesGraph = await client.api("/$batch").post(contentPresence);

    return presencesGraph;
  } catch {
    presencesGraph = {
      responses: [
        {
          body: {
            availability: "Unknown",
            activity: "Unknown",
          },
        },
      ],
    };
  }

  return presencesGraph;
}

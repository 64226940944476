/* eslint-disable react/react-in-jsx-scope */
import { Loader } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import GenericInfo from "../../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfoAction,
  InfoImage,
} from "../../../common/components/generic-info-component/GenericInfo.model";
import Http from "../../../common/Http";
import { IError } from "../../../common/models/Error";
import HostSettings from "../../../utils/host.settings";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import Header from "./Header/Header";
import { IUpcomingTripDetailProps } from "./UpcomingTripDetail.model";
import Styles from "./UpcomingTripDetail.module.scss";
import WidgetsGrid from "./WidgetsGrid/WidgetsGrid";

function Error() {
  const history = useHistory();
  const { t } = useTranslation();

  const errorInfo: IError = {
    status: 0,
    code: 0,
    title: t(`messages.ERR_Generic`),
    detail: t(`upcoming_trips_dashboard.ERR_api_call`),
  };
  const action: IGenericInfoAction = {
    text: t(`upcoming_trips_dashboard.Button_try_again`),
    command: () => {
      history.go(0);
    },
  };

  return (
    <GenericInfo infoData={errorInfo} action={action} image={InfoImage.ERROR} />
  );
}

function UpcomingTripDetail() {
  const { tripId } = useParams<IUpcomingTripDetailProps>();
  const [content, setContent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getTripDetails = async () => {
      const url = `${HostSettings.getBaseAPI}${HostSettings.getTripList}${tripId}`;

      setIsLoading(true);

      const response = await Http.get(url);
      const data = (await response.data.data) || (await response.data);

      return data;
    };

    getTripDetails()
      .then((data) => {
        setIsLoading(false);
        setContent(
          <div>
            <Breadcrumb trip={data} />
            <div className={Styles["main-container"]}>
              <Header trip={data} />
              <WidgetsGrid trip={data} />
            </div>
          </div>
        );
      })
      .catch(() => {
        setIsLoading(false);
        setContent(<Error />);
      });
  }, [tripId]);

  if (isLoading) {
    return <Loader style={{ margin: 100 }} data-testid="tab-loader" />;
  }

  return content;
}

export default UpcomingTripDetail;

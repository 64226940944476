/* eslint-disable react/react-in-jsx-scope */
import { Flex, Loader } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import { InfoImage } from "../../common/components/generic-info-component/GenericInfo.model";
import LoginComponent from "../../common/components/login-component/login-component";
import { login, logout } from "../../store/authentication/AuthenticationAction";
import {
  isAuthenticationErrorSelector,
  isAuthenticationPendingSelector,
  isLoggedInSelector,
} from "../../store/authentication/AuthenticationSelector";
import { resetCytricContext } from "../../store/cytric-context/CytricContextSlice";
import Store from "../../store/Store";
import { useAppSelector } from "../../store/StoreHooks";

function Settings() {
  const { t } = useTranslation("translation");

  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );
  const isPending: boolean = useAppSelector((state) =>
    isAuthenticationPendingSelector(state)
  );
  const isError: boolean = useAppSelector((state) =>
    isAuthenticationErrorSelector(state)
  );

  const clickOnLogin = () => {
    Store.dispatch(login());
  };

  const clickOnLogout = () => {
    Store.dispatch(logout());
    Store.dispatch(resetCytricContext());
  };

  useEffect(() => {
    microsoftTeams.initialize();
    if (isLoggedIn) {
      microsoftTeams.getContext((context) => {
        if (context?.subEntityId === "logout") {
          clickOnLogout();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPending) {
    return (
      <Flex vAlign="center" hAlign="center" column>
        <Loader style={{ margin: 100 }} />
      </Flex>
    );
  }

  if (isError && isLoggedIn) {
    return (
      <GenericInfo
        infoData={{
          title: t("messages.ERR_Generic"),
          detail: isLoggedIn
            ? t("Settings.ERR_while_log_out")
            : t("Settings.ERR_while_log_in"),
        }}
        action={{
          text: t("App.Try_again"),
          command: isLoggedIn ? clickOnLogout : clickOnLogin,
        }}
        image={InfoImage.ERROR}
      />
    );
  }

  if (isLoggedIn) {
    return (
      <GenericInfo
        infoData={{
          title: t("Settings.You_are_logged_in"),
          detail: t("Settings.You_can_log_out"),
        }}
        action={{
          text: t("Settings.Logout_btn"),
          command: clickOnLogout,
        }}
        image={InfoImage.CYTRIC_EASY}
      />
    );
  }

  return <LoginComponent />;
}

export default Settings;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import Icon from "../../../../../../../common/icon";
import convertSentence from "../../../../../../../helpers/StringHelper";

import "./services-popup.scss";

const SERVICE_TYPE = {
  included: "INCLUDED",
  atCharge: "AT_CHARGE",
  notIncluded: "NOT_INCLUDED",
};

export default function ServicesPopup({
  familyServices,
}: {
  familyServices: any;
}) {
  return (
    <Flex column id="servicesPopupContainer">
      <Flex>
        <Text className="header">Flight services</Text>
      </Flex>
      <Flex className="section">
        <Flex>
          <Icon name="Checked" />
          <Text className="section__header">Included</Text>
        </Flex>
        <Flex className="section__items">
          {familyServices.map((fs: any) => {
            if (fs.type === SERVICE_TYPE.included) {
              return (
                <Text key={fs.description} className="section__items-item">
                  {fs.description && convertSentence(fs.description)}
                </Text>
              );
            }
            return null;
          })}
        </Flex>
      </Flex>
      <Flex className="section">
        <Flex>
          <Icon name="Cheapest" />
          <Text className="section__header">At charge</Text>
        </Flex>
        <Flex className="section__items">
          {familyServices.map((fs: any) => {
            if (fs.type === SERVICE_TYPE.atCharge) {
              return (
                <Text key={fs.description} className="section__items-item">
                  {fs.description && convertSentence(fs.description)}
                </Text>
              );
            }
            return null;
          })}
        </Flex>
      </Flex>
      <Flex className="section">
        <Flex>
          <Icon name="UnChecked" />
          <Text className="section__header">Not Included</Text>
        </Flex>
        <Flex className="section__items">
          {familyServices.map((fs: any) => {
            if (fs.type === SERVICE_TYPE.notIncluded) {
              return (
                <Text key={fs.description} className="section__items-item">
                  {fs.description && convertSentence(fs.description)}
                </Text>
              );
            }
            return null;
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  ApprovalsAppbarIcon,
  CalendarIcon,
  CanvasAddPageIcon,
  Divider,
  Header,
  List,
  Loader,
  Segment,
} from "@fluentui/react-northstar";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { KPI_ROUTE_ITEM } from "../../../utils/constants";
import { ErrorAccessInterface } from "../ExpenseDashboardInterfaces";
import { getDashboardInfo } from "../ExpenseDashboardRequests";
import "./Summary.scss";

interface SummaryPropsInterface {
  currency: string;
  setAccessError: React.Dispatch<
    React.SetStateAction<ErrorAccessInterface | null>
  >;
}

export interface KpiSummaryInterface {
  toSubmit: number;
  toBeProcessed: number;
  underReimbursement: number;
}

function Summary({ currency, setAccessError }: SummaryPropsInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMounted = useRef(false);

  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.summary",
  });

  const [kpiSummary, setKpiSummary] = useState<KpiSummaryInterface>({
    toSubmit: -1,
    toBeProcessed: -1,
    underReimbursement: -1,
  });

  const getSums = async () => {
    setIsLoading(true);
    const allInfo = await getDashboardInfo(KPI_ROUTE_ITEM);
    if (allInfo.isError) {
      setAccessError(allInfo);
    } else {
      setKpiSummary(allInfo.kpiSum);
    }
    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getSums();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Segment className="summary-container">
      <Header as="h4" content={t("yourExpenses")} className="summary-title" />
      {isLoading ? (
        <Loader />
      ) : (
        <List className="summary-list" data-testid="list">
          <List.Item
            media={
              <CanvasAddPageIcon
                outline
                xSpacing="both"
                size="larger"
                styles={{ color: "#8A8A8A" }}
              />
            }
            header={`${kpiSummary?.toSubmit?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ${currency}`}
            content={t("toSubmit")}
            index={0}
          />
          <Divider size={0} />
          <List.Item
            media={
              <ApprovalsAppbarIcon
                outline
                xSpacing="both"
                size="larger"
                styles={{ color: "#8A8A8A" }}
              />
            }
            header={`${kpiSummary?.toBeProcessed?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ${currency}`}
            content={t("waitingToBeProcessed")}
            index={1}
          />
          <Divider size={0} />
          <List.Item
            media={
              <CalendarIcon
                outline
                xSpacing="both"
                size="larger"
                styles={{ color: "#8A8A8A" }}
              />
            }
            header={`${kpiSummary?.underReimbursement?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )} ${currency}`}
            content={t("underReimbursement")}
            index={2}
          />
        </List>
      )}
    </Segment>
  );
}

export default Summary;

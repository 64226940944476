import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";

interface EmptyStatePropsInterface {
  image: string;
  title: string;
  message: string;
}

function EmptyState({ image, title, message }: EmptyStatePropsInterface) {
  return (
    <Flex
      id="empty-state__flex-container"
      data-testid="empty-state__flex-container"
      column
      gap="gap.small"
      padding="padding.medium"
      hAlign="center"
      vAlign="center"
    >
      <Image id="empty-state__img" data-testid="empty-state__img" src={image} />
      <Text
        id="empty-state__title"
        size="larger"
        weight="semibold"
        content={title}
      />
      <Text
        id="empty-state__subtitle"
        data-testid="empty-state__subtitle"
        size="medium"
        content={message}
      />
    </Flex>
  );
}

export default EmptyState;

import React from "react";
import { useTranslation } from "react-i18next";
import { login } from "../../../store/authentication/AuthenticationAction";
import store from "../../../store/Store";
import GenericInfo from "../generic-info-component/GenericInfo";
import { InfoImage } from "../generic-info-component/GenericInfo.model";

const dispatchLogin = () => {
  store.dispatch(login());
};

function LoginComponent() {
  const { t } = useTranslation();
  return (
    <GenericInfo
      infoData={{
        title: t("LoginComponent.welcome"),
        detail: t("LoginComponent.start"),
      }}
      action={{
        text: t("LoginComponent.loginButton"),
        command: () => dispatchLogin(),
      }}
      image={InfoImage.WELCOME}
      isLoginComponent
    />
  );
}

export default LoginComponent;

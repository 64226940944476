import { teamsTheme } from "@fluentui/react-northstar";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { getThemeName, TeamsTheme } from "../ChartTypes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

let globalCurrency: string;

export const options = {
  maintainAspectRatio: false,
  indexAxis: "y" as const,
  layout: {
    padding: {
      right: 70,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      ticks: {
        color: teamsTheme.siteVariables.colors?.grey["440"],
        mirror: true,
        labelOffset: 18,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
  elements: {
    bar: {
      // borderWidth: 15,
      // borderColor: "rgb(255, 0, 0, 0)", // transparent
      // thickness: 10,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      anchor: "end" as const,
      align: "right" as const,
      formatter: (value: number) => `${Math.round(value)} ${globalCurrency}`,
      font: {
        weight: "bold" as const,
      },
      offset: 5,
      color: teamsTheme.siteVariables.colors?.black,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
      // position: "bottom" as const,
      // align: "start" as const,
      // onClick: (e: any) => e.stopPropagation(),
    },
  },
};

function createDiagonalPattern(color = "black") {
  const shape = document.createElement("canvas");
  shape.width = 10;
  shape.height = 10;
  const ctx = shape.getContext("2d");
  /* istanbul ignore next */
  if (ctx) {
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(2, 0);
    ctx.lineTo(10, 8);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(0, 8);
    ctx.lineTo(2, 10);
    ctx.stroke();
    return ctx.createPattern(shape, "repeat");
  }
  return color;
}

export function HorizontalBar({
  data,
  theme,
  currency,
}: any): React.ReactElement {
  const [chartOptions, setChartOptions] = useState(options);
  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    globalCurrency = currency;
    setChartOptions(options);
  }, [currency]);

  useEffect(() => {
    const myOptions = { ...chartOptions };
    const myTheme = getThemeName(theme);
    const myData: any = { ...data };
    if (myData && myData.datasets) {
      myData.datasets = myData.datasets.map((dataset: any) => ({
        ...dataset,
        barThickness: 15,
        borderSkipped: false,
        borderWidth: myTheme === TeamsTheme.HighContrast ? 1 : 0,
        borderColor: theme.siteVariables.colors?.white,
        hoverBorderWidth: myTheme === TeamsTheme.HighContrast ? 3 : 0,
        hoverBorderColor: theme.siteVariables.colors?.backgroundHover,
        backgroundColor:
          myTheme === TeamsTheme.HighContrast
            ? createDiagonalPattern(
                theme.siteVariables.colorScheme?.brand.background
              )
            : theme.siteVariables.colorScheme?.brand.background,
        hoverBackgroundColor:
          myTheme === TeamsTheme.HighContrast
            ? createDiagonalPattern(
                theme.siteVariables.colorScheme?.brand.backgroundHover
              )
            : theme.siteVariables.colorScheme?.brand.backgroundHover,
      }));
    }

    switch (myTheme) {
      // Default
      case TeamsTheme.Default:
        myOptions.plugins.datalabels.color = theme.siteVariables.colors?.black;
        myOptions.scales.y.ticks.color =
          theme.siteVariables.colors?.grey["440"];
        break;
      // High Contrast
      case TeamsTheme.HighContrast:
        myOptions.plugins.datalabels.color = theme.siteVariables.colors?.white;
        myOptions.scales.y.ticks.color = theme.siteVariables.colors?.white;
        break;
      // Dark
      default:
        myOptions.scales.y.ticks.color =
          theme.siteVariables.colors?.grey["400"];
        myOptions.plugins.datalabels.color = theme.siteVariables.colors?.white;
        break;
    }
    setChartOptions(myOptions);
    setChartData(myData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return (
    <Bar plugins={[ChartDataLabels]} options={chartOptions} data={chartData} />
  );
}

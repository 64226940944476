/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Header, Loader, Segment } from "@fluentui/react-northstar";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IChartData } from "../../../common/components/chart/ChartTypes";
import {
  MONTH_CHART_FILTER_TITLE,
  MONTH_CHART_PERIOD,
  QUARTER_CHART_FILTER_TITLE,
  QUARTER_CHART_PERIOD,
  YEAR_CHART_FILTER_TITLE,
  YEAR_CHART_PERIOD,
} from "../../../utils/constants";
import { ErrorAccessInterface } from "../ExpenseDashboardInterfaces";
import "./Chart.scss";
import { getChartSpendingsResults } from "./ChartRequests";
import Graphic from "./Graphic";

interface CustomChartPropsInterface {
  currency: string;
  setAccessError: React.Dispatch<
    React.SetStateAction<ErrorAccessInterface | null>
  >;
}

interface ChartElementInterface {
  type: string;
  sum: number;
}

export const capitalizeString = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1);

export const splitChartLabels = (initialLabel: string) => {
  const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;

  const splittedResult = initialLabel.match(wordRegex);
  const expectedResult = splittedResult?.map((chartLabel) =>
    chartLabel.toLowerCase()
  );
  const finalResult = expectedResult?.join(" ");
  return capitalizeString(finalResult || "");
};

function CustomChart({ currency, setAccessError }: CustomChartPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.chart",
  });
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [key, setKey] = useState<number>(0);
  const [selectedPeriod, setSelectedPeriod] =
    useState<string>(QUARTER_CHART_PERIOD);
  const [spendingsInfo, setSpendingsInfo] = useState<ChartElementInterface[]>(
    []
  );

  const changeKeyBasedOnPeriod = (period: string) => {
    switch (period) {
      case MONTH_CHART_PERIOD:
        setKey(1);
        break;
      case QUARTER_CHART_PERIOD:
        setKey(2);
        break;
      case YEAR_CHART_PERIOD:
        setKey(3);
        break;
      default:
        break;
    }
  };

  const spendingsData: IChartData = {
    labels: [
      ...spendingsInfo.map((spending) => splitChartLabels(spending.type)),
    ],
    datasets: [
      {
        label: "Spendings",
        data: [...spendingsInfo.map((spending) => spending.sum)],
      },
    ],
  };

  const getSpendingsInfos = async (period: string) => {
    setIsLoading(true);
    const allInfo = await getChartSpendingsResults(period);
    if (allInfo.isError) {
      setAccessError(allInfo);
    } else {
      setSpendingsInfo(allInfo);
      changeKeyBasedOnPeriod(period);
    }
    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  const onPeriodsButtonsClick = async (period: string) => {
    setSelectedPeriod(period);
    getSpendingsInfos(period);
  };

  useEffect(() => {
    isMounted.current = true;
    getSpendingsInfos(QUARTER_CHART_PERIOD);
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Segment className="chart-container">
      <Header
        as="h4"
        content={t("spendingByStatus")}
        className="spendings-title"
      />
      <div className="periods-btns-container">
        <Button
          id="customChart__month-button"
          data-testid="customChart__month-button"
          text
          content={capitalizeString(
            t("month", {
              month: MONTH_CHART_FILTER_TITLE,
            })
          )}
          size="small"
          primary={selectedPeriod === MONTH_CHART_PERIOD}
          onClick={() => onPeriodsButtonsClick(MONTH_CHART_PERIOD)}
          className={selectedPeriod === MONTH_CHART_PERIOD ? "bolderBtn" : ""}
        />
        <Button
          id="customChart__quarter-button"
          data-testid="customChart__quarter-button"
          text
          content={t("lastQuarter", {
            quarter: QUARTER_CHART_FILTER_TITLE,
          })}
          size="small"
          primary={selectedPeriod === QUARTER_CHART_PERIOD}
          onClick={() => onPeriodsButtonsClick(QUARTER_CHART_PERIOD)}
          className={selectedPeriod === QUARTER_CHART_PERIOD ? "bolderBtn" : ""}
        />
        <Button
          id="customChart__year-button"
          data-testid="customChart__year-button"
          text
          content={capitalizeString(
            t("year", {
              year: YEAR_CHART_FILTER_TITLE,
            })
          )}
          size="small"
          primary={selectedPeriod === YEAR_CHART_PERIOD}
          onClick={() => onPeriodsButtonsClick(YEAR_CHART_PERIOD)}
          className={selectedPeriod === YEAR_CHART_PERIOD ? "bolderBtn" : ""}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Graphic info={spendingsData} currency={currency} key={key} />
      )}
    </Segment>
  );
}

export default CustomChart;

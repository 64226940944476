export interface IGenericInfo {
  title: string;
  detail: string;
  subdetail?: string;
}

export interface IGenericInfoAction {
  text: string;
  command: () => void;
}

export enum InfoImage {
  ERROR = "ERROR",
  SESSION_EXPIRED = "SESSION_EXPIRED",
  CYTRIC_EASY = "CYTRIC_EASY",
  WELCOME = "WELCOME",
}

/* eslint-disable react/require-default-props */
import {
  Button,
  Flex,
  Image,
  ProviderConsumer,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../utils/host.settings";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
} from "./GenericInfo.model";
import "./GenericInfo.scss";
import cytricEasy from "./images/cytric-easy.svg";
import sessionExpired from "./images/session-expired.svg";
import welcome from "./images/welcome.svg";
import DarkError from "./textImages/DarkError";
import Error from "./textImages/Error";

interface IInfoProps {
  infoData: IGenericInfo;
  action?: IGenericInfoAction;
  image: InfoImage;
  isLoginComponent?: boolean;
}

const defaultError = Error();

const darkError = DarkError();
/* Order of theme images array is : Default, DarkMode, HighContrast */
const imageMap = {
  ERROR: [defaultError, darkError, defaultError],
  SESSION_EXPIRED: [sessionExpired, sessionExpired, sessionExpired],
  CYTRIC_EASY: [cytricEasy, cytricEasy, cytricEasy],
  WELCOME: [welcome, welcome, welcome],
};

const getThemedImage = (theme: any, image: InfoImage) => {
  switch (theme.siteVariables.bodyBackground) {
    /* HighContrast */
    case "#000":
      return imageMap[image][2];
    /* Default */
    case "#fff":
      return imageMap[image][0];
    /* DarkMode */
    default:
      return imageMap[image][1];
  }
};

function GenericInfo({
  infoData,
  action,
  image,
  isLoginComponent,
}: IInfoProps) {
  const { title, detail, subdetail } = infoData;
  const { t } = useTranslation();

  const loginButton = (
    <Button
      className="generic-info__loginButton"
      flat
      size="medium"
      icon={
        <Image
          src="MicrosoftLogo.png"
          alt="Microsoft Logo"
          className="generic-info__imgButton"
        />
      }
      content={action?.text}
      onClick={action?.command}
    />
  );

  const loginSubtitle = (
    <Text className="generic-info__subtitle">
      {t("LoginComponent.pleaseContact")}{" "}
      <a
        href={HostSettings.getUrlContactSupport}
        target="_blank"
        rel="noreferrer"
        className="generic-info__link"
      >
        {" "}
        {t("LoginComponent.support")}
      </a>{" "}
      {t("LoginComponent.details")}
    </Text>
  );

  const defaultButton = (
    <Button className="generic-info__button" primary onClick={action?.command}>
      {action?.text}
    </Button>
  );

  const renderError = (theme: any) => (
    <Flex vAlign="center" hAlign="center" className="generic-info" column>
      <Image
        src={getThemedImage(theme, image)}
        className="generic-info__img"
        alt="info-message"
      />
      <Text className="generic-info__header">{title} </Text>
      <Text className="generic-info__content">{detail}</Text>
      {subdetail && <Text>{subdetail}</Text>}
      {action && isLoginComponent ? loginButton : action && defaultButton}
      {isLoginComponent && loginSubtitle}
    </Flex>
  );

  return (
    <ProviderConsumer render={(globalTheme) => renderError(globalTheme)} />
  );
}

export default GenericInfo;

/* eslint-disable no-param-reassign */
import GetCloseCollaboratorsParams from "../classes/ClassCloseCollaborators";

export function MapCollabsUsers(
  usersByCity: any,
  collaboratorsGraph: any,
  collaborators: any[],
  context: any
) {
  collaboratorsGraph.value.forEach((cg: any) => {
    if (
      usersByCity.responses.find((us: any) => us.id === cg.id).body.value
        .length > 0 &&
      cg.id !== context.userObjectId &&
      collaborators.find((aux: any) => aux.id === cg.id) === undefined &&
      collaborators.length < 10
    ) {
      collaborators.push(
        collaboratorsGraph.value.find((x: any) => x.id === cg.id)
      );
    }
  });

  return collaborators;
}

export function mapCollabImages(
  collaborators: any,
  images: any,
  presencesGraph: any
) {
  const queryCollabs = new GetCloseCollaboratorsParams();
  collaborators.forEach((x: any) => {
    const ccimage = images.responses.find((im: any) => im.id === x.id);
    const ccpresence = presencesGraph.responses.find(
      (pr: any) => pr.id === x.id
    );
    if (ccimage.status === 200) {
      x.image = `data:image/jpeg;base64,${ccimage.body}`;
    } else {
      x.image = queryCollabs.photoError;
    }

    const parseActivity =
      ccpresence?.body.activity === "OffWork"
        ? "Offline"
        : ccpresence?.body.activity;
    const statusActivitySintax =
      parseActivity &&
      parseActivity
        .replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase();
    const statusActivity =
      statusActivitySintax &&
      statusActivitySintax.charAt(0).toUpperCase() +
        statusActivitySintax.slice(1);

    x.availability =
      ccpresence.status === 200 ? ccpresence?.body.availability : "Unknown";
    x.activity = ccpresence.status === 200 ? statusActivity : "Unknown";
  });

  return collaborators;
}

/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
// Dependencies
import { Card, Flex } from "@fluentui/react-northstar";
import { Fragment, useState } from "react";
import FlightBody from "../flight-body/flight-body";
import FlightFooter from "../flight-footer/flight-footer";
import FlightHeader from "../flight-header/flight-header";
// Styles
import "./flight-card.scss";

function FlightBodyParent({
  flightData,
  currentOffer,
}: {
  flightData: any;
  currentOffer: any;
}) {
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  return currentOffer.bounds.map((bd: any, index: any) => (
    <Fragment key={index}>
      <FlightBody
        index={index}
        boundsInfo={bd}
        flightData={flightData}
        showExtraInfo={showExtraInfo}
        setShowExtraInfo={setShowExtraInfo}
      />
    </Fragment>
  ));
}

function FlightCard({
  flightData,
  flightIDIncluded,
  selectedFlightsIDs,
}: {
  flightData: any;
  flightIDIncluded: any;
  selectedFlightsIDs: any;
}) {
  const displayed: string[] = [""];
  let position = 0;
  let showFlightsWithoutPrice = true;

  function displayFlightOffer(tags: any) {
    const options: string[] = [""];
    if (tags) {
      tags.forEach((tag: any) => options.push(tag.type));
    }
    if (options.includes("NON_PREFERED_STOP_OVER") && options.length < 2) {
      return false;
    }
    return true;
  }

  function priceCase(flights: any) {
    let caseWithPrice = 0;
    let caseWithoutPrice = 0;

    flights?.offers.map((sf: any) => {
      if (sf.price) {
        caseWithPrice += 1;
      } else {
        caseWithoutPrice += 1;
      }
      return null;
    });

    if (caseWithPrice > 0 && caseWithoutPrice > 0) {
      showFlightsWithoutPrice = false;
    }
  }

  priceCase(flightData);
  return (
    <Flex column style={{ width: "100%" }}>
      {flightData?.offers.map((sf: any) => {
        if (
          sf.outOfPolicy === false &&
          !displayed.includes(sf.id) &&
          (showFlightsWithoutPrice || sf.price) &&
          (sf.tags || (sf.rank && displayed.length < 4)) &&
          displayFlightOffer(sf.tags)
        ) {
          position += 1;
          displayed.push(sf.id);
          return (
            <Flex style={{ marginBottom: 16 }} key={sf?.rank}>
              <Card
                data-testid="flight-card"
                key={sf.rank}
                elevated
                className={
                  flightIDIncluded(sf.id)
                    ? "cardContainer_selected"
                    : "cardContainer"
                }
                onClick={() => selectedFlightsIDs(sf.id)}
              >
                <Card.Header className="cardHeader">
                  <FlightHeader offer={sf} position={position} />
                </Card.Header>
                <Card.Body fitted className="cardBody">
                  <FlightBodyParent flightData={flightData} currentOffer={sf} />
                </Card.Body>
                <Card.Footer fitted>
                  {sf.price ? (
                    flightData?.pricingOptions.map((tp: any) =>
                      sf.price.pricingGroupIds[0] === tp.id ? (
                        <FlightFooter
                          key={sf.id}
                          travelClass={tp.fareDetails}
                          price={sf.price}
                          currency={tp.currencyCode}
                          noPrice={false}
                          flightData={flightData}
                        />
                      ) : null
                    )
                  ) : (
                    <FlightFooter
                      key={sf.id}
                      travelClass=""
                      price={0}
                      currency=""
                      noPrice
                      flightData=""
                    />
                  )}
                </Card.Footer>
              </Card>
            </Flex>
          );
        }
        return null;
      })}
    </Flex>
  );
}

export default FlightCard;

/* eslint-disable @typescript-eslint/no-shadow */
import { Text } from "@fluentui/react-northstar";
import React from "react";
import { composeTripName } from "../../trip-utils";

import Styles from "./Destination.module.scss";

function Destination({ trip }: any) {
  return (
    <Text
      className={Styles.destination}
      role="document"
      styles={(theme) => ({
        color: theme.theme.siteVariables.tripCard?.title,
      })}
    >
      {composeTripName(trip)}
    </Text>
  );
}

export default Destination;

/* eslint-disable class-methods-use-this */
/* istanbul ignore file */

import Http from "../../common/Http";
import { FLIGHT_OFFERS } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";

export default class SearchFlightsService {
  public getFlightsv2 = async (
    {
      fromIataCode,
      toIataCode,
      purposeId,
      tripType,
      tripTypeTimeInbound,
      tripTypeTimeOutbound,
      departureDestinationType,
      arrivalDestinationType,
    }: any,
    departureDateTime: any,
    returnDateTime: any
  ) => {
    if (tripType === "roundtrip") {
      return Http.get(`${HostSettings.getBaseAPI}${FLIGHT_OFFERS}`, {
        params: {
          from: fromIataCode,
          to: toIataCode,
          departureDateTime,
          returnDateTime,
          tripPurpose: purposeId,
          departureTimeType: tripTypeTimeOutbound,
          returnTimeType: tripTypeTimeInbound,
          destinationLocationType: arrivalDestinationType,
          originLocationType: departureDestinationType,
        },
      });
    }
    return Http.get(`${HostSettings.getBaseAPI}${FLIGHT_OFFERS}`, {
      params: {
        from: fromIataCode,
        to: toIataCode,
        departureDateTime,
        tripPurpose: purposeId,
        departureTimeType: tripTypeTimeOutbound,
        destinationLocationType: arrivalDestinationType,
        originLocationType: departureDestinationType,
      },
    });
  };
}

/* eslint-disable react/react-in-jsx-scope */
import useGraph from "../../hooks/useGraph";
import { ICloseCollaboratorsProps } from "./CloseCollaborators.model";

const UsersinCityGraph = ({ destination }: ICloseCollaboratorsProps) => {
  const { countryCode } = destination;
  const requestUrl = `users/$count?$filter=usageLocation eq '${countryCode}'`;

  const { data: dataUsersinCity, loading: loadingUsersinCity } = useGraph(
    async (graph) => {
      let users: any;
      try {
        users = await graph
          .api(requestUrl)
          .header("ConsistencyLevel", "eventual")
          .get();
        return users;
      } catch {
        users = [];
      }

      return users;
    },
    {
      scope: ["User.Read", "User.Read.All"],
    }
  );
  return { dataUsersinCity, loadingUsersinCity };
};

export default UsersinCityGraph;

const Error = () =>
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYwIiBoZWlnaHQ9IjE2MCIgdmlld0JveD0iMCAwIDE2MCA" +
  "xNjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL" +
  "3N2ZyI+DQo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfODQ1XzkwODAyKSI+DQo8c" +
  "GF0aCBkPSJNMTM5LjUgNTkuMDk5OUwxMDUuMiA5My42OTk5QzEwMy4yIDk1LjY5O" +
  "TkgMTA0LjEgOTkuMiAxMDYuOSA5OS45TDE1NCAxMTIuNEMxNTYuOCAxMTMuMSAxN" +
  "TkuMyAxMTAuNiAxNTguNiAxMDcuOEwxNDUuOCA2MC43OTk5QzE0NC45IDU3Ljk5O" +
  "TkgMTQxLjUgNTYuOTk5OSAxMzkuNSA1OS4wOTk5WiIgZmlsbD0idXJsKCNwYWludD" +
  "BfbGluZWFyXzg0NV85MDgwMikiLz4NCjxwYXRoIGQ9Ik0zOS43IDE5LjlMMTkuMyA" +
  "2NC4yQzE4LjEgNjYuOCAyMC4yIDY5LjcgMjMgNjkuNUw3MS41IDY1Qzc0LjQgNjQu" +
  "NyA3NS45IDYxLjUgNzQuMiA1OS4xTDQ2LjEgMTkuM0M0NC40IDE2LjkgNDAuOSAxN" +
  "y4zIDM5LjcgMTkuOVoiIGZpbGw9InVybCgjcGFpbnQxX2xpbmVhcl84NDVfOTA4MD" +
  "IpIi8+DQo8cGF0aCBkPSJNNzguNSAyNi4wOTk5TDE5LjYgMTEzLjRDMTYuMSAxMTg" +
  "uNSAxOS41IDEyNS41IDI1LjcgMTI2TDEzMC44IDEzMy40QzEzNyAxMzMuOCAxNDEu" +
  "MyAxMjcuNCAxMzguNiAxMjEuOEw5Mi40IDI3LjA5OTlDODkuNyAyMS40OTk5IDgyI" +
  "DIwLjk5OTkgNzguNSAyNi4wOTk5WiIgZmlsbD0iI0ZGRDU5MCIvPg0KPHBhdGggZD" +
  "0iTTgwLjUgOTcuMUM3OC4zIDk2LjkgNzYuNiA5NSA3Ni44IDkyLjhMNzkuNyA1MS45" +
  "Qzc5LjkgNDkuNyA4MS44IDQ4IDg0IDQ4LjJDODYuMiA0OC40IDg3LjkgNTAuMyA4Ny" +
  "43IDUyLjVMODQuOCA5My40Qzg0LjYgOTUuNiA4Mi43IDk3LjIgODAuNSA5Ny4xWiI" +
  "gZmlsbD0id2hpdGUiLz4NCjxwYXRoIGQ9Ik03OS45IDExMS4xQzgyLjM4NTMgMTExL" +
  "jEgODQuNCAxMDkuMDg1IDg0LjQgMTA2LjZDODQuNCAxMDQuMTE1IDgyLjM4NTMgMTAy" +
  "LjEgNzkuOSAxMDIuMUM3Ny40MTQ3IDEwMi4xIDc1LjQgMTA0LjExNSA3NS40IDEwNi4" +
  "2Qzc1LjQgMTA5LjA4NSA3Ny40MTQ3IDExMS4xIDc5LjkgMTExLjFaIiBmaWxsPSJ3aG" +
  "l0ZSIvPg0KPHBhdGggZD0iTTM0LjYgNDcuN0MzMi41IDQ4LjMgMzAuMyA0Ny4xIDI5" +
  "LjcgNDQuOUwyMS41IDE2QzIwLjkgMTMuOSAyMi4xIDExLjcgMjQuMyAxMS4xQzI2LjQ" +
  "gMTAuNSAyOC42IDExLjcgMjkuMiAxMy45TDM3LjQgNDIuOEMzNy45IDQ0LjkgMzYuN" +
  "yA0Ny4xIDM0LjYgNDcuN1oiIGZpbGw9IiMyOEMyRDEiLz4NCjxwYXRoIGQ9Ik0zNy4" +
  "yIDYxLjRDMzkuNjg1MyA2MS40IDQxLjcgNTkuMzg1MyA0MS43IDU2LjlDNDEuNyA1N" +
  "C40MTQ3IDM5LjY4NTMgNTIuNCAzNy4yIDUyLjRDMzQuNzE0NyA1Mi40IDMyLjcgNTQ" +
  "uNDE0NyAzMi43IDU2LjlDMzIuNyA1OS4zODUzIDM0LjcxNDcgNjEuNCAzNy4yIDYxL" +
  "jRaIiBmaWxsPSIjMjhDMkQxIi8+DQo8cGF0aCBkPSJNMTQ0LjEgODIuNkMxNDIgOD" +
  "IgMTQwLjggNzkuNyAxNDEuNCA3Ny42TDE1MCA0OC45QzE1MC42IDQ2LjggMTUyLjk" +
  "gNDUuNiAxNTUgNDYuMkMxNTcuMSA0Ni44IDE1OC4zIDQ5LjEgMTU3LjcgNTEuMkwx" +
  "NDkgODBDMTQ4LjQgODIuMSAxNDYuMiA4My4zIDE0NC4xIDgyLjZaIiBmaWxsPSIjM0" +
  "U4RUVEIi8+DQo8cGF0aCBkPSJNMTQxLjMgOTYuMjk5OUMxNDMuNzg1IDk2LjI5OTkg" +
  "MTQ1LjggOTQuMjg1MiAxNDUuOCA5MS43OTk5QzE0NS44IDg5LjMxNDYgMTQzLjc4NS" +
  "A4Ny4yOTk5IDE0MS4zIDg3LjI5OTlDMTM4LjgxNSA4Ny4yOTk5IDEzNi44IDg5LjMx" +
  "NDYgMTM2LjggOTEuNzk5OUMxMzYuOCA5NC4yODUyIDEzOC44MTUgOTYuMjk5OSAxNDE" +
  "uMyA5Ni4yOTk5WiIgZmlsbD0iIzNFOEVFRCIvPg0KPHBhdGggZD0iTTM0LjYgNDcuN0" +
  "MzMi41IDQ4LjMgMzAuMyA0Ny4xIDI5LjcgNDQuOUwyMS41IDE2QzIwLjkgMTMuOSAyM" +
  "i4xIDExLjcgMjQuMyAxMS4xQzI2LjQgMTAuNSAyOC42IDExLjcgMjkuMiAxMy45TDM3" +
  "LjQgNDIuOEMzNy45IDQ0LjkgMzYuNyA0Ny4xIDM0LjYgNDcuN1oiIGZpbGw9IiMyOE" +
  "MyRDEiLz4NCjxwYXRoIGQ9Ik0zNy4yIDYxLjRDMzkuNjg1MyA2MS40IDQxLjcgNTku" +
  "Mzg1MyA0MS43IDU2LjlDNDEuNyA1NC40MTQ3IDM5LjY4NTMgNTIuNCAzNy4yIDUyLj" +
  "RDMzQuNzE0NyA1Mi40IDMyLjcgNTQuNDE0NyAzMi43IDU2LjlDMzIuNyA1OS4zODUz" +
  "IDM0LjcxNDcgNjEuNCAzNy4yIDYxLjRaIiBmaWxsPSIjMjhDMkQxIi8+DQo8cGF0aC" +
  "BkPSJNOTUuNCAxNS40Qzk0LjggMTYuNCA5NSAxNy42IDk1LjggMTguNEM5NS45IDE4" +
  "LjUgOTYuMSAxOC43IDk2LjMgMTguN0M5Ny41IDE5LjQgOTkgMTkgOTkuNiAxNy45TD" +
  "EwMi43IDEyLjdDMTAzIDEyLjEgMTAzLjEgMTEuNSAxMDMgMTAuOEMxMDIuOSAxMC4z" +
  "IDEwMi42IDkuNzk5OTcgMTAyLjIgOS40OTk5N0MxMDIuMSA5LjM5OTk3IDEwMiA5Lj" +
  "M5OTk3IDEwMS45IDkuMjk5OTdDMTAwLjcgOC41OTk5NyA5OS4yIDguOTk5OTYgOTgu" +
  "NiAxMC4xTDk1LjQgMTUuNFoiIGZpbGw9IiNFMURGREQiLz4NCjxwYXRoIGQ9Ik0xMD" +
  "QuOSAyNi41QzEwNSAyNi43IDEwNS4yIDI3IDEwNS40IDI3LjJDMTA2LjIgMjcuOSAx" +
  "MDcuMyAyOC4xIDEwOC4yIDI3LjZMMTEzLjQgMjVDMTE0IDI0LjcgMTE0LjQgMjQuMi" +
  "AxMTQuNiAyMy42QzExNC44IDIzIDExNC44IDIyLjMgMTE0LjUgMjEuN0MxMTQuMyAy" +
  "MS40IDExNC4xIDIxLjEgMTEzLjggMjAuOUMxMTMuNiAyMC43IDExMy4zIDIwLjYgMT" +
  "EzLjEgMjAuNUMxMTIuNSAyMC4zIDExMS44IDIwLjMgMTExLjIgMjAuNkwxMDYgMjMu" +
  "MkMxMDUuNCAyMy41IDEwNSAyNCAxMDQuOCAyNC42QzEwNC42IDI1LjMgMTA0LjYgMj" +
  "UuOSAxMDQuOSAyNi41WiIgZmlsbD0iI0UxREZERCIvPg0KPHBhdGggZD0iTTgzLjIg" +
  "MTMuMkM4My4zIDEzLjcgODMuNSAxNC4yIDgzLjkgMTQuNkM4NC40IDE1LjEgODUuMi" +
  "AxNS40IDg1LjkgMTUuM0M4Ny4yIDE1LjEgODguMiAxMy45IDg4IDEyLjVMODcuMSA2" +
  "LjA5OTk3Qzg3IDUuNDk5OTcgODYuNyA0Ljg5OTk3IDg2LjIgNC40OTk5N0M4NS43ID" +
  "QuMDk5OTcgODUgMy44OTk5NyA4NC4zIDMuOTk5OTdDODMgNC4xOTk5NyA4MiA1LjM5" +
  "OTk3IDgyLjIgNi43OTk5N0w4My4yIDEzLjJaIiBmaWxsPSIjRTFERkREIi8+DQo8L2" +
  "c+DQo8ZGVmcz4NCjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl84NDVf" +
  "OTA4MDIiIHgxPSIxMDIuMTEyIiB5MT0iOTQuODc0MyIgeDI9IjEzOS4xOTkiIHkyPS" +
  "I4Ny4xNTU5IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQo8c3RvcCBv" +
  "ZmZzZXQ9IjAuMDAwOTg0MjUyIiBzdG9wLWNvbG9yPSIjNzJBQ0YxIi8+DQo8c3RvcC" +
  "BvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNBN0NCRjYiLz4NCjwvbGluZWFyR3JhZGll" +
  "bnQ+DQo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MV9saW5lYXJfODQ1XzkwODAyIi" +
  "B4MT0iNzAuODk0IiB5MT0iNjYuMzE5NiIgeDI9IjQzLjE2MjgiIHkyPSI0OS43MjE1" +
  "IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQo8c3RvcCBvZmZzZXQ9Ij" +
  "AuMDAwOTg0MjUyIiBzdG9wLWNvbG9yPSIjN0JEREVGIi8+DQo8c3RvcCBvZmZzZXQ9" +
  "IjEiIHN0b3AtY29sb3I9IiNDM0YyRjQiLz4NCjwvbGluZWFyR3JhZGllbnQ+DQo8Y2" +
  "xpcFBhdGggaWQ9ImNsaXAwXzg0NV85MDgwMiI+DQo8cmVjdCB3aWR0aD0iMTU2LjYi" +
  "IGhlaWdodD0iMTUxLjEiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZS" +
  "gyIDQpIi8+DQo8L2NsaXBQYXRoPg0KPC9kZWZzPg0KPC9zdmc+DQo=";

export default Error;

/* eslint-disable react/react-in-jsx-scope */
import Icon from "../../../../common/icon";
import statusIconClass from "./StatusIconClass";

export default function statusIcon(collaboratorAvailability: string) {
  const contentStatusIcon = (
    <div className={statusIconClass(collaboratorAvailability).icon.class}>
      <Icon name={statusIconClass(collaboratorAvailability).icon.icon} />
    </div>
  );
  return contentStatusIcon;
}

import { useEffect, useState } from "react";
import { PresenceModel } from "../../models/CloseCollaboratorsModel";
import intervalPresence from "../utils/GetIntervalPresence";

export default function UsersPresence(collabsIds: string[]) {
  const inicialPresente: PresenceModel[] = [];
  const [collaboratorAvailability, setCollaboratorAvailability] =
    useState(inicialPresente);

  useEffect(() => {
    const interval = setInterval(async () => {
      const userPresence = await intervalPresence(collabsIds);

      setCollaboratorAvailability(userPresence.usersPresences);
    }, 60000000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorAvailability]);

  return { collaboratorAvailability };
}

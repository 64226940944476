/* eslint-disable class-methods-use-this */
/* eslint consistent-return: off */

import Http from "../../common/Http";
import { LOCATION } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";

export default class AutoCompleteService {
  public getAirportsAndCitiesv2 = async (keyword = "Madrid") => {
    if (keyword && keyword.length > 2) {
      return Http.get(`${HostSettings.getBaseAPI}${LOCATION}`, {
        params: {
          keyword,
        },
      });
    }
    return undefined;
  };
}

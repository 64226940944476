/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */

// Dependencies
import {
  ArrowRightIcon,
  ChevronDownIcon,
  Flex,
  Image,
  Text,
} from "@fluentui/react-northstar";
import { Fragment } from "react";
// Styles
import "./flight-body.scss";
// Components
import FlightInformation from "./flight-information/flight-information";
import image from "./placeholder.png";

function FlightBody(props: any) {
  // Hooks
  // JSX methods
  const timeAndAirline = (
    departure: any,
    arrival: any,
    airlineName: any,
    flightNumber: any
  ) => {
    const departureTime = new Date(departure?.localDateTime).toLocaleTimeString(
      "en-GB",
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    );
    const arrivalTime = new Date(arrival?.localDateTime).toLocaleTimeString(
      "en-GB",
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    );

    return (
      <Flex>
        <Flex column className="flightAndNumber">
          <Flex>
            <Text className="flightTime">{`${departureTime} - ${arrivalTime}`}</Text>
          </Flex>
          <Flex>
            <Text className="flightNumber">{`${airlineName} - ${flightNumber}`}</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const departureAndDestination = (
    departure: any,
    arrival: any,
    duration: any
  ) => {
    const departureCode = departure.iataCode;
    const arrivalCode = arrival.iataCode;
    const flightDuration = duration.slice(2).toLowerCase();
    const hoursDuration = flightDuration.substring(
      0,
      flightDuration.indexOf("h") + 1
    );
    const minutesDuration = flightDuration.substring(
      flightDuration.indexOf("h") + 1,
      flightDuration.length
    );

    return (
      <Flex column>
        <Flex style={{ alignItems: "center" }}>
          <Text className="departureCode">{departureCode}</Text>
          <ArrowRightIcon className="arrowRight" />
          <Text className="departureCode">{arrivalCode}</Text>
        </Flex>
        <Text>
          {hoursDuration} {minutesDuration}
        </Text>
      </Flex>
    );
  };

  const {
    duration,
    segments,
    Co2Emissions,
    // eslint-disable-next-line react/destructuring-assignment
  } = props.boundsInfo;

  let firstFlight: any;
  let lastFlight: any;

  props.flightData?.bounds.forEach((flight: any) => {
    if (flight.id === segments[0]) {
      firstFlight = flight;
    }
    if (flight.id === segments[segments.length - 1]) {
      lastFlight = flight;
    }
  });

  const getStopsInfo = () => {
    let firstFlightDateTime: string;
    let secondFlightDateTime: string;
    let secondFlightDepartureIataCode: string;
    let stopDuration: any;

    return (
      <Flex column>
        {segments.map((_: any, index: any) => {
          props.flightData?.bounds.map((segmentFlight: any) => {
            if (index + 1 < segments.length) {
              if (segments[index] === segmentFlight.id) {
                firstFlightDateTime = segmentFlight.arrival.localDateTime;
              }
              if (segments[index + 1] === segmentFlight.id) {
                secondFlightDateTime = segmentFlight.departure.localDateTime;
                secondFlightDepartureIataCode =
                  segmentFlight.departure.iataCode;
              }
            }
            return null;
          });

          stopDuration =
            new Date(secondFlightDateTime).getTime() -
            new Date(firstFlightDateTime).getTime();

          const hours = Math.trunc(stopDuration / (1000 * 3600));
          const minutes = Math.trunc((stopDuration / (1000 * 60)) % 60);

          if (index < segments.length - 1) {
            return (
              <Text
                key={index}
                className="stopInfoText"
              >{`${hours}h ${minutes}m in ${secondFlightDepartureIataCode} airport`}</Text>
            );
          }
          return null;
        })}
      </Flex>
    );
  };

  return (
    <Flex style={{ margin: 0 }}>
      <Flex column className="flightsContainer">
        <Flex key={props.key} className="hContainer">
          <div
            className={
              props.index === 0 ? "sublineFlightContainer" : "flightContainer"
            }
          >
            {props.flightData?.airlineDictionary?.map((ad: any, index: any) =>
              ad.iataCode === firstFlight.carrierCode ? (
                <Fragment key={`${ad.iataCode}${index}`}>
                  <Image
                    className="image"
                    src={ad.logo?.href ? ad.logo?.href : image}
                  />
                  <Flex className="subContainer" space="between">
                    {timeAndAirline(
                      firstFlight.departure,
                      lastFlight.arrival,
                      ad.commonName,
                      firstFlight.flightNumber
                    )}
                    {departureAndDestination(
                      firstFlight.departure,
                      lastFlight.arrival,
                      duration
                    )}
                    <Flex>
                      <Flex className="stopsContainer" column>
                        {segments.length - 1 > 0 ? (
                          segments.length - 1 > 1 ? (
                            <Text className="nonStop">{`${
                              segments.length - 1
                            } stops`}</Text>
                          ) : (
                            <Text className="nonStop">1 stop</Text>
                          )
                        ) : (
                          <Text className="withStop">Nonstop</Text>
                        )}
                        {segments.length - 1 > 0 ? getStopsInfo() : null}
                      </Flex>
                      {props.index === 0 ? (
                        <ChevronDownIcon
                          className={props.showExtraInfo ? "extraInfoIcon" : ""}
                          data-testid="extraInfoButton"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            props.setShowExtraInfo(!props.showExtraInfo);
                          }}
                        />
                      ) : (
                        <Flex style={{ width: 16 }} />
                      )}
                    </Flex>
                  </Flex>
                </Fragment>
              ) : null
            )}
          </div>
        </Flex>
        {props.showExtraInfo && (
          <FlightInformation
            flightDictionary={props.flightData?.airlineDictionary}
            flightBounds={props.flightData?.bounds}
            pricingOptions={props.flightData?.pricingOptions}
            Co2Emissions={Co2Emissions}
            fromIataCode={firstFlight?.departure.iataCode}
            toIataCode={lastFlight?.arrival.iataCode}
            segments={segments}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default FlightBody;

import * as microsoftTeams from "@microsoft/teams-js";
import {
  FLAG_LOGIN,
  INTERNAL_KEYCLOAK_FLAG,
  KEYCLOAK_QS_URL,
} from "../../utils/constants";

const ERROR_LOGIN_CYTRIC = "ERR_LoginCytric";

const cytricLogin = async (uri: string, keycloakAuthUri: string) =>
  new Promise<void>((resolve, reject) => {
    if (localStorage.getItem(INTERNAL_KEYCLOAK_FLAG) === FLAG_LOGIN) {
      resolve();
    } else {
      // Use qs to send the keycloak_uri to avoid firefox issues in private
      const url = `${keycloakAuthUri}&${KEYCLOAK_QS_URL}=${encodeURIComponent(
        uri
      )}`;
      microsoftTeams.authentication.authenticate({
        url,
        width: 650,
        height: 535,
        successCallback: () => {
          localStorage.setItem(INTERNAL_KEYCLOAK_FLAG, FLAG_LOGIN);
          resolve();
        },
        failureCallback: () => {
          reject();
        },
      });
    }
  });

const amadeusCytricLogin = async (
  keycloackUri: string,
  keycloakAuthUri: string
) =>
  new Promise<void>((resolve, reject) => {
    cytricLogin(keycloackUri, keycloakAuthUri)
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject(new Error(ERROR_LOGIN_CYTRIC));
      });
  });
export default amadeusCytricLogin;

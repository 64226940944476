/* eslint-disable */
// Dependencies
import { ExclamationCircleIcon } from "@fluentui/react-icons-northstar";
import {
  Dialog,
  Divider,
  Flex,
  Image,
  Loader,
  Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useQuery } from "react-query";
// Services
import CovidModalService from "../../../../../services/search-flight/covid-modal.service";
// Styles
import "./covid-popup.scss";
// Images
import globe from "./globe.png";

function SelectedTab({ summary, questions }: { summary: any; questions: any }) {
  return (
    <Flex column className="tabContainer">
      {questions.map((q: any) => (
        <div key={q.question}>
          <Flex
            style={{ marginTop: 21, marginLeft: 2, marginBottom: 3 }}
            key={q.question}
          >
            <span style={{ fontWeight: "700" }}>{q.question}</span>
            <div style={{ width: 10 }} />
          </Flex>
          <Flex>
            {!Array.isArray(q.answer) ? (
              <span style={{ marginTop: 9, marginLeft: 2, marginBottom: 3 }}>
                {q.answer}
              </span>
            ) : (
              <span>
                <ul style={{ marginTop: 9, marginBottom: 3 }}>
                  {q.answer.map((item: any, index: any) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </span>
            )}
          </Flex>
        </div>
      ))}
      <Divider style={{ marginTop: 17, marginBottom: 3, paddingBottom: 0 }} />
      <Flex style={{ marginTop: 21, marginLeft: 2, marginBottom: 3 }}>
        <span style={{ fontWeight: "700" }}>Summary</span>
        <div style={{ width: 10 }} />
      </Flex>
      <div
        style={{ marginLeft: 2 }}
        dangerouslySetInnerHTML={{ __html: summary }}
      />
    </Flex>
  );
}

export function RiskAdvisoryContent({
  countryCode,
}: {
  countryCode: string;
}): JSX.Element {
  // Services
  const covidModalService = new CovidModalService();

  // Hooks
  const [selected, setSelected] = useState("testing");

  const { isLoading, data } = useQuery(["covidDataPopupV2", countryCode], () =>
    covidModalService.getCovidInformationV2(countryCode)
  );

  if (isLoading) return <Loader label="Loading..." />;

  const {
    areaAccessRestriction: {
      diseaseTesting,
      diseaseVaccination,
      quarantineModality,
    },
    area: { name },
    diseaseRiskLevel,
    dataSources: { governmentSiteLink, covidDashboardLink },
  } = data?.data?.data[0];

  const testingQuestions = [
    {
      question: "Am I required to take a test?",
      answer: diseaseTesting?.isRequired,
    },
    {
      question: "When do I have to take the test?",
      answer: diseaseTesting?.when,
    },
    {
      question: "What type of test should I take?",
      answer: diseaseTesting?.testType,
    },
  ];

  const vaccinationQuestions = [
    {
      question: "Am I required to be vaccinated?",
      answer: diseaseVaccination?.isRequired,
    },
    {
      question: "What are the accepted certificates?",
      answer: diseaseVaccination?.acceptedCertificates,
    },
    {
      question: "What are the qualified vaccines?",
      answer: diseaseVaccination?.qualifiedVaccines,
    },
  ];

  const quarantineQuestions = [
    {
      question: "Who is eligible for quarantine?",
      answer: quarantineModality?.eligiblePerson,
    },
    {
      question: "How many days does the quarantine last?",
      answer: 10,
    },
  ];

  return (
    <Flex column>
      <Flex className="covidAlert" column>
        <Flex>
          <ExclamationCircleIcon className="iconAlert" />
          <Text>
            COVID-19 Risk level in {name} is{" "}
            <Text weight="bold"> {diseaseRiskLevel}</Text>.{" "}
          </Text>
        </Flex>
        <Flex style={{ marginTop: "12" }}>
          <Image className="globe" src={globe} />
          {covidDashboardLink ? (
            <Text style={{ marginLeft: 9.4 }}>
              For more information visit{" "}
              <a
                className="covidLink"
                target="_blank"
                href={covidDashboardLink}
              >
                COVID dashboard
              </a>{" "}
              and{" "}
              <a
                className="covidLink"
                target="_blank"
                href={governmentSiteLink}
              >
                Government site
              </a>{" "}
              websites.
            </Text>
          ) : (
            <Text style={{ marginLeft: 9.4 }}>
              For more information visit{" "}
              <a
                className="covidLink"
                target="_blank"
                href={governmentSiteLink}
              >
                Government site
              </a>{" "}
              website.
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex>
        <Text
          data-testid="testing"
          className={selected === "testing" ? "selectedTab" : "nonSelectedTab"}
          content="Testing"
          onClick={() => setSelected("testing")}
        />
        <Text
          data-testid="vaccination"
          className={
            selected === "vaccination" ? "selectedTab" : "nonSelectedTab"
          }
          content="Vaccination"
          onClick={() => setSelected("vaccination")}
        />
        <Text
          data-testid="quarantine"
          className={
            selected === "quarantine" ? "selectedTab" : "nonSelectedTab"
          }
          content="Quarantine"
          onClick={() => setSelected("quarantine")}
        />
      </Flex>
      {selected === "testing" && (
        <SelectedTab
          summary={diseaseTesting?.text === "null" ? "" : diseaseTesting?.text}
          questions={testingQuestions}
        />
      )}
      {selected === "vaccination" && (
        <SelectedTab
          summary={
            diseaseVaccination?.text === "null" ? "" : diseaseVaccination?.text
          }
          questions={vaccinationQuestions}
        />
      )}
      {selected === "quarantine" && (
        <SelectedTab
          summary={
            quarantineModality?.text === "null" ? "" : quarantineModality?.text
          }
          questions={quarantineQuestions}
        />
      )}
    </Flex>
  );
}

function CovidPopup({
  open,
  setOpen,
  countryCode,
}: {
  open: any;
  setOpen: any;
  countryCode: string;
}): JSX.Element {
  return (
    <Dialog
      className="dialogContainer"
      backdrop
      confirmButton="Close"
      onConfirm={() => setOpen(!open)}
      header="Travel safety advisory"
      open={open}
      content={<RiskAdvisoryContent countryCode={countryCode} />}
    />
  );
}

export default CovidPopup;

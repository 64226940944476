import Http from "../../common/Http";

export default class TripListService {
  private static readonly SERVER_URL = process.env.REACT_APP_BASE_API;

  private static readonly API_URL = process.env.REACT_APP_TRIP_LIST;

  public static getTrips(): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.API_URL}`);
  }
}

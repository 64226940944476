/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Dependencies
import { Dropdown } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import UserPreferredLocationService from "../../../../../services/search-flight/user-preferred-location.service";
import { DropdownItem, SearchCriteria } from "../search.model";
// Styles

function CytricFromDropDown({
  options,
  isLoading,
  state,
  setState,
}: {
  options: DropdownItem[];
  isLoading: boolean;
  state: SearchCriteria;
  setState: (_: SearchCriteria) => void;
}) {
  const [dropDownMessage, setDropDownMessage] = useState(
    "Please enter 3 or more characters."
  );
  // Functions
  const handleSearchQuery = (items: any, searchQuery: any) => {
    if (searchQuery.length < 3) {
      if (dropDownMessage !== "Please enter 3 or more characters") {
        setDropDownMessage("Please enter 3 or more characters.");
      }
      return [];
    }
    if (
      dropDownMessage !==
      "No results found. Try entering more or different characters."
    ) {
      setDropDownMessage(
        "No results found. Try entering more or different characters."
      );
    }
    return items;
    // eslint-disable-next-line consistent-return
  };

  const handleFromChange = (e: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    setState({
      ...state,
      from: props.value?.name,
      fromIataCode: props.value?.iatacode,
      fromCountryCode: props.value?.countrycode,
      departureDestinationType: props.value?.city ? "CITY" : "AIRPORT",
    });
  };

  const removeCityFrom = () =>
    options.filter((op: DropdownItem) => op.iatacode !== state.toIataCode);

  const userPreferredLocationService = new UserPreferredLocationService();

  const userPreferredLocationResponse = useQuery(
    "userPreferredLocation",
    userPreferredLocationService.getPreferredLocation,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      !userPreferredLocationResponse.isLoading &&
      !userPreferredLocationResponse.isError &&
      Object.keys(userPreferredLocationResponse?.data?.data).length !== 0 &&
      userPreferredLocationResponse?.data?.data?.departureLocation
    ) {
      const preferredLocation =
        userPreferredLocationResponse.data?.data?.departureLocation;

      setState({
        ...state,
        from: `${preferredLocation.name} (${preferredLocation.iataCode})`,
        fromIataCode: preferredLocation.iataCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferredLocationResponse.data]);

  return (
    <Dropdown
      data-testid="cytric-fromDropDown"
      styles={(theme) => ({
        backgroundColor: theme.theme.siteVariables?.formDropdown.background,
        color: theme.theme.siteVariables?.formDropdown.color,
      })}
      search={handleSearchQuery}
      loading={isLoading || userPreferredLocationResponse.isLoading}
      loadingMessage="Loading..."
      items={removeCityFrom()}
      searchQuery={state.from}
      noResultsMessage={dropDownMessage}
      onSearchQueryChange={(e, { searchQuery }) =>
        searchQuery !== undefined && setState({ ...state, from: searchQuery })
      }
      fluid
      onChange={handleFromChange}
      placeholder="City / Airport"
    />
  );
}

export default CytricFromDropDown;

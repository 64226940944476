/* eslint-disable react/react-in-jsx-scope */
import { Flex } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import WidgetContainer from "../upcoming-trips-dashboard/UpcomingTripDetail/WidgetContainer/WidgetContainer";
import CloseCollaboratorsList from "./close-collaborators-list/CloseCollaboratorsList";
import { ICloseCollaboratorsProps } from "./CloseCollaborators.model";
import stylesCloseCollaborators from "./styles/close-collaborators.module.scss";
import UsersinCityGraph from "./UsersinCityGraph";

function isCloseCollabotatorEnabled() {
  return process.env.REACT_APP_CLOSE_COLLABORATORS === "1";
}

export default function CloseCollaborators({
  destination,
}: ICloseCollaboratorsProps) {
  const [context, setContext] = useState({});
  const { loadingUsersinCity, dataUsersinCity } = UsersinCityGraph({
    destination,
    context,
  });

  const enabled = isCloseCollabotatorEnabled();

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext((ctx) => {
      setContext(ctx);
    });
  }, []);

  const CloseCollaboratorCard: any = !loadingUsersinCity &&
    dataUsersinCity > 0 &&
    context &&
    enabled && (
      <WidgetContainer>
        <div className={stylesCloseCollaborators.closeCollaborators}>
          <Flex>
            <Flex.Item grow>
              <CloseCollaboratorsList
                destination={destination}
                context={context}
              />
            </Flex.Item>
          </Flex>
        </div>
      </WidgetContainer>
    );

  return CloseCollaboratorCard;
}

import * as microsoftTeams from "@microsoft/teams-js";

export default function getChat(emailUser: string) {
  microsoftTeams.initialize();
  const chatUrl = `https://teams.microsoft.com/l/chat/0/0?users=${emailUser}`;

  const openChat = microsoftTeams.executeDeepLink(chatUrl);

  return openChat;
}

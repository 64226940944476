export const TRIP_TYPE = {
  roundTrip: { code: "roundtrip", label: "Roundtrip" },
  oneWay: { code: "oneway", label: "Oneway" },
};

export const TRIP_TYPE_TIME = {
  departure: { code: "DEPARTURE", label: "Departure" },
  arrival: { code: "ARRIVAL", label: "Arrival" },
};

export const PROVIDER_LOGOS_KEY = "TXT06x6zYu1mELCfJHaob9WV5YlEhZm743X72ZXd";

// Endpoints
export const LOCATION = "loc/v0/corporation/reference-data/locations";
export const SETTINGS = "system/v0/corporation/settings/trip-purposes";
export const RISK_ADVISORY =
  "travel-safety/v0/duty-of-care/diseases/covid19-area-report";
export const USER_PREFERENCES = "um/v0/corporation/users/current/preferences";
export const FLIGHT_OFFERS = "fo/v0/corporation/shopping/flight-offers";

// expenses dashboard constants
export const SERVER = "http://localhost:3000";
export const TRAVELER = "Traveler";
export const APPROVER = "Approver";
export const TYPE_OF_SPENDING = "Type of spending";
export const STATUS_OF_EXPENSES = "Status of expenses";
export const APPROVER_CREDENTIALS =
  "pin=RFjD8U&psc=51098759&role=expenseApproverRestricted";
// "pin=KLj711&psc=64572822&role=expenseApproverFinal";
export const TRAVELER_CREDENTIALS = "pin=KNj21K&psc=20113039&role=traveller";
export const MERGE_TYPE = "Merge";
export const DONT_MERGE_TYPE = "Don't merge";
export const CLOSE_TYPE = "Close";
export const MONTH_CHART_PERIOD = "month";
export const QUARTER_CHART_PERIOD = "quarter";
export const YEAR_CHART_PERIOD = "year";
export const MONTH_CHART_FILTER_TITLE = "Last 30 days";
export const QUARTER_CHART_FILTER_TITLE = "Last 90 days";
export const YEAR_CHART_FILTER_TITLE = "Last 12 months";
export const TODO_ROUTE_ITEM = "todo";
export const KPI_ROUTE_ITEM = "kpi";

export const EXPENSE_DENIED_ACCESS_CODE = 39002;
export const MSNT_DENIED_ACCESS_CODE = 39001;
export const CYTRIC_EASY_DENIED_ACCESS_CODE = 39000;

export const FLAG_LOGIN = "ok";
export const INTERNAL_KEYCLOAK_FLAG = "keycloakLogin";
export const KEYCLOAK_QS_URL = "keycloakUrl";

export const USER_OBJECT_ID = "userObjectId";

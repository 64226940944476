import { Flex, Loader } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
} from "../../common/components/generic-info-component/GenericInfo.model";
import { IError } from "../../common/models/Error";
import RedirectionInput from "../../common/models/redirection/RedirectionInput";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import { logout } from "../../store/authentication/AuthenticationAction";
import { isLoggedInSelector } from "../../store/authentication/AuthenticationSelector";
import {
  selectLastUrlDateTimeout,
  selectLastVisitedUrl,
} from "../../store/cytric-context/CytricContextSelector";
import {
  resetCytricContext,
  updateCytricContext,
} from "../../store/cytric-context/CytricContextSlice";
import { loadCytricUrl } from "../../store/redirection/RedirectionActions";
import {
  errorInformation,
  selectCytricUrl,
  selectRedirectionIsError,
  selectRedirectionIsPending,
} from "../../store/redirection/RedirectionSelector";
import { updateCytricUrl } from "../../store/redirection/RedirectionSlice";
import Store from "../../store/Store";
import { useAppSelector } from "../../store/StoreHooks";
import HostSettings from "../../utils/host.settings";
import "./PersonalTab.scss";

function PersonalTab() {
  const sessionExpiredPageName = "page-session-Expired";
  const logoutCytricPageName = "page-session-Logout";

  const dashboardInput: RedirectionInput = {
    segmentType: RedirectionTypeEnum.DASHBOARD,
  };

  const [isCytricSessionExpired, setIsCytricSessionExpired] = useState(false);

  const cytricUrl: string = useAppSelector((state) => selectCytricUrl(state));

  const errorInfo: IError = useAppSelector((state) => errorInformation(state));
  const redirectionIsPending: boolean = useAppSelector((state) =>
    selectRedirectionIsPending(state)
  );
  const redirectionIsError: boolean = useAppSelector((state) =>
    selectRedirectionIsError(state)
  );
  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );

  const lastUrlVisited: string = useAppSelector((state) =>
    selectLastVisitedUrl(state)
  );

  const isSessionExpired: boolean = useAppSelector(
    (state) => selectLastUrlDateTimeout(state) < new Date().getTime()
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "PersonalTab",
  });

  const isFromTrustedOrigin = (event: MessageEvent<any>) => {
    const trustedOrigins = HostSettings.iFrameTrustedOrigins;
    return (
      trustedOrigins.length > 0 &&
      trustedOrigins.some((pattern) => event.origin.match(pattern) != null)
    );
  };

  const isValidUrl = (urlString: string) => {
    try {
      const url = new URL(urlString);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      return false;
    }
  };

  const refreshActionButton = () => {
    setIsCytricSessionExpired(false);
    Store.dispatch(resetCytricContext());
    Store.dispatch(loadCytricUrl(dashboardInput));
  };

  const containsRequiredData = (event: MessageEvent<any>) =>
    event.data &&
    event.data.currentUrl &&
    event.data.currentUrl.length > 0 &&
    isValidUrl(event.data.currentUrl);

  const postMessageListener = (event: MessageEvent<any>) => {
    if (
      containsRequiredData(event) &&
      (isFromTrustedOrigin(event) || event.isTrusted)
    ) {
      const url = event.data.currentUrl;
      const pageName = event.data.currentPage;

      if (sessionExpiredPageName === pageName) {
        setIsCytricSessionExpired(true);
        Store.dispatch(resetCytricContext());
        return;
      }

      if (logoutCytricPageName === pageName) {
        Store.dispatch(resetCytricContext());
        Store.dispatch(logout());
        const encodedContext = encodeURI('{"subEntityId": "logout"}');
        microsoftTeams.executeDeepLink(
          `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/settings?context=${encodedContext}`
        );
        return;
      }

      Store.dispatch(
        updateCytricContext({
          url,
          pageName,
        })
      );
    }
  };

  // use effect on load
  useEffect(() => {
    window.addEventListener("message", postMessageListener, false);
    if (isLoggedIn) {
      microsoftTeams.getContext((context) => {
        if (context?.subEntityId) {
          try {
            const params = JSON.parse(context?.subEntityId);
            Store.dispatch(loadCytricUrl(params as any));
          } catch (error) {
            Store.dispatch(loadCytricUrl(dashboardInput));
          }
        } else if (lastUrlVisited && !isSessionExpired) {
          Store.dispatch(updateCytricUrl({ url: lastUrlVisited }));
        } else {
          Store.dispatch(resetCytricContext());
          Store.dispatch(loadCytricUrl(dashboardInput));
        }
      });
    }
    // cleanup this component
    return () => {
      window.removeEventListener("message", postMessageListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const actionRefresh: IGenericInfoAction = {
    text: t("Refresh"),
    command: refreshActionButton,
  };

  const refreshInfo: IGenericInfo = {
    title: t("Cytric_session_expired_title"),
    detail: t("Cytric_session_expired_detail"),
  };

  if (isCytricSessionExpired) {
    return (
      <GenericInfo
        infoData={refreshInfo}
        action={actionRefresh}
        image={InfoImage.SESSION_EXPIRED}
      />
    );
  }

  return (
    <Flex
      className="personal-tab-container"
      id="personal-tab-container"
      data-testid="personal-tab-container"
    >
      {redirectionIsError && (
        <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />
      )}
      {redirectionIsPending && (
        <Loader
          size="large"
          className="trip-loader"
          label="Loading Cytric Page"
        />
      )}
      {cytricUrl && (
        <iframe
          className="cytric-iframe"
          id="dynamic-tab"
          title="cytric"
          src={cytricUrl}
          scrolling="true"
          sandbox="allow-forms allow-popups allow-scripts allow-top-navigation allow-modals allow-presentation allow-same-origin allow-downloads"
        />
      )}
    </Flex>
  );
}

export default PersonalTab;

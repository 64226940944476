export interface IChartData {
  labels: string[];
  datasets: IChartDataSet[];
}

export interface IChartDataSet {
  label: string;
  data: number[];
  hidden?: boolean;
}

export enum EChartTypes {
  BarHorizontal = "barHorizontal",
}

export enum EShapes {
  Square = "square",
  DiagonalRightLeft = "diagonalRightLeft",
  Grid = "grid",
  Diagonal = "diagonal",
  VerticalLine = "verticalLine",
  GridRightLeft = "gridRightLeft",
}

export enum TeamsTheme {
  HighContrast = "HighContrast",
  Dark = "Dark",
  Default = "Default",
}

export function getThemeName(theme: any): TeamsTheme {
  switch (theme.siteVariables.bodyBackground) {
    case "#000":
      return TeamsTheme.HighContrast;
    case "#fff":
      return TeamsTheme.Default;
    default:
      return TeamsTheme.Dark;
  }
}

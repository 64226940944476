// Dependencies
import React from "react";
// Styles
import "./edit-popup.scss";
// Components
import EditSearchForm from "./edit-searchform/edit-searchform";

function EditPopup({
  showEdit,
  setShowEdit,
}: {
  showEdit: any;
  setShowEdit: any;
}) {
  return (
    showEdit && (
      <div data-testid="editSearchContainer" className="showPopup">
        <EditSearchForm setShowEdit={setShowEdit} />
      </div>
    )
  );
}

export default EditPopup;

/* eslint-disable react/prop-types */
// Dependencies
import { Dropdown } from "@fluentui/react-northstar";
import React, { useState } from "react";
// Models
import { DropdownItem, SearchCriteria } from "../search.model";
// Styles
import "./cytric-toDropDown.scss";

function CytricToDropDown({
  options,
  isLoading,
  state,
  setState,
}: {
  options: DropdownItem[];
  isLoading: boolean;
  state: SearchCriteria;
  setState: (_: SearchCriteria) => void;
}) {
  const [dropDownMessage, setDropDownMessage] = useState(
    "Please enter 3 or more characters."
  );
  // Functions
  const handleSearchQuery = (items: any, searchQuery: string) => {
    if (searchQuery.length < 3) {
      if (dropDownMessage !== "Please enter 3 or more characters") {
        setDropDownMessage("Please enter 3 or more characters.");
      }
      return [];
    }
    if (
      dropDownMessage !==
      "No results found. Try entering more or different characters."
    ) {
      setDropDownMessage(
        "No results found. Try entering more or different characters."
      );
    }
    return items;
    // eslint-disable-next-line consistent-return
  };

  const handleFromChange = (e: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    setState({
      ...state,
      to: props.value?.name,
      toIataCode: props.value?.iatacode,
      toCountryCode: props.value?.countrycode,
      arrivalDestinationType: props.value?.city ? "CITY" : "AIRPORT",
    });
  };

  const removeCityFrom = () =>
    options.filter((op: DropdownItem) => op.iatacode !== state.fromIataCode);

  return (
    <Dropdown
      data-testid="cytric-toDropDown"
      styles={(theme) => ({
        backgroundColor: theme.theme.siteVariables?.formDropdown.background,
        color: theme.theme.siteVariables?.formDropdown.color,
      })}
      search={handleSearchQuery}
      fluid
      loading={isLoading}
      loadingMessage="Loading..."
      items={removeCityFrom()}
      searchQuery={state.to}
      noResultsMessage={dropDownMessage}
      onSearchQueryChange={(e, { searchQuery }) =>
        searchQuery !== undefined && setState({ ...state, to: searchQuery })
      }
      onChange={handleFromChange}
      placeholder="City / Airport"
    />
  );
}

export default CytricToDropDown;

export const WELCOME = {
  title: "Welcome to Cytric! We're glad you're here",
  subTitle: "Manage your travel and your expenses in a single place.",
  buttonText: "Plan a trip",
};

export const UPCOMING_TRIPS = {
  title: "Your upcoming trips",
  buttonText: "Plan a new trip",
  header: {
    greeting: "Hello",
    message:
      "Select one of your upcoming trips to view its details or plan a new trip.",
  },
  tripTitle: "Trip by car",
  segmentsText: "Booked:",
  delimiterForDestinations: " \u00B7 ",
};

export const UPCOMING_TRIP_DETAIL = {
  breadcrumb: {
    previousPage: "Travel",
    currentPage: "Trip",
    currentPageTo: "to",
    buttons: {
      share: "Share my trip",
      manage: "Manage my trip",
    },
  },
  header: {
    title: "Trip to ",
    trip: "trip",
  },
  bookingDetails: {
    title: "Your booking",
    stopSingular: "stop",
    stopPlural: "stops",
    nonStop: "Nonstop",
    in: "in",
    inTotal: "in total",
    covidRisk: "COVID 19 risk level in ",
    is: "is",
    and: "and",
  },
  placeholder: {
    flight: "It seems that you haven't booked any flight ticket yet.",
    train: "It seems that you haven't booked any train ticket yet.",
    hotel: "It seems that you haven't booked any hotel yet.",
    car: "It seems that you haven't booked any car or transfer yet.",
  },
  detailCard: {
    stop: " stop in ",
    duration: "Duration: ",
    operatedBy: "Operated by ",
    seat: "Seat: ",
    baggage: "Baggage: ",
    meal: "Meal: ",
  },
};

export const SIMPLIFIED_BOOKING_DETAILS = {
  title: "Coming soon",
  subtitle:
    "In this section you will be able to see all the information related to your booking.",
};

export default {};

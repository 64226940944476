class HostSettings {
  public static get getAmadeusEnvironment(): string {
    return process.env.REACT_APP_AMADEUS_ENVIRONMENT || "";
  }

  public static get clientId(): string {
    return process.env.REACT_APP_CLIENT_ID || "";
  }

  public static get teamsAppId(): string {
    return process.env.REACT_APP_TEAMS_APP_ID || "";
  }

  public static get teamsfxEndpoint(): string {
    return process.env.REACT_APP_TEAMSFX_ENDPOINT || "";
  }

  public static get startLoginPageUrl(): string {
    return process.env.REACT_APP_START_LOGIN_PAGE_URL || "";
  }

  public static get getBaseAPI(): string {
    return process.env.REACT_APP_BASE_API || "";
  }

  public static get getApiKey(): string {
    return process.env.REACT_APP_API_KEY || "";
  }

  public static get getInstrumentationKey(): string {
    return process.env.REACT_APP_INSTRUMENTATION_KEY || "";
  }

  public static get isTelemetryEnabled(): boolean {
    return process.env.REACT_APP_IS_TELEMETRY_ENABLED === "1";
  }

  public static get getKeycloakUri(): string {
    return process.env.REACT_APP_KEYCLOAK_URI || "";
  }

  public static get isCytricLoginEnabled(): boolean {
    return process.env.REACT_APP_IS_CYTRIC_LOGIN_ENABLED === "1";
  }

  public static get tokenScopes(): string[] {
    const scopes = process.env.REACT_APP_TOKEN_SCOPES || "";
    if (scopes === "") {
      return [];
    }
    return scopes.split(",");
  }

  public static get getKeycloakAuthUri(): string {
    return process.env.REACT_APP_KEYCLOAK_AUTH_URI || "";
  }

  public static get iFrameTrustedOrigins(): string[] {
    const iframeTrustedOrigins =
      process.env.REACT_APP_CYTRIC_IFRAME_TRUSTED_ORIGINS || "";
    if (iframeTrustedOrigins === "") {
      return [];
    }
    return iframeTrustedOrigins.split(",");
  }

  public static get getUrlContactSupport(): string {
    return process.env.REACT_APP_URL_SUPPORT || "";
  }

  // expenses dashboard
  public static get getDashboardInfo(): string {
    return process.env.REACT_APP_GET_DASHBOARD_INFO || "";
  }

  public static get getExpensesToApproveCount(): string {
    return process.env.REACT_APP_GET_COUNT_EXPENSES_TOAPPROVE || "";
  }

  public static get getDuplicateExpensesList(): string {
    return process.env.REACT_APP_GET_DUPLICATE_EXPENSES_LIST || "";
  }

  public static get getCurrencyCode(): string {
    return process.env.REACT_APP_GET_DASHBOARD_CURRENCY || "";
  }

  public static get getChartSpendings(): string {
    return process.env.REACT_APP_GET_CHART_SPENDINGS || "";
  }

  public static get getTripList(): string {
    return process.env.REACT_APP_TRIP_LIST || "";
  }
}

export default HostSettings;

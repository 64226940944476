/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-template */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Dependencies
import {
  ArrowSortIcon,
  ChevronEndIcon,
  ExclamationCircleIcon,
  ShiftActivityIcon,
} from "@fluentui/react-icons-northstar";
import {
  Alert,
  Breadcrumb,
  Button,
  Datepicker,
  Divider,
  Dropdown,
  Flex,
  FlexItem,
  Header,
  Image,
  Input,
  Label,
  Loader,
  Popup,
  RadioGroup,
  RadioGroupItemProps,
  Slider,
  Text,
} from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import moment from "moment";
import { useTeams } from "msteams-react-base-component";
import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useHistory } from "react-router-dom";
import useSearchFlightForm from "../../../../hooks/useSearchFlightForm/useHook";
import AutoCompleteService from "../../../../services/search-flight/autocomplete.service";
import CovidModalService from "../../../../services/search-flight/covid-modal.service";
import ProviderLogosService from "../../../../services/search-flight/provider-logos.service";
import TripPurposeService from "../../../../services/search-flight/trip-purpose.service";
// Utils
import { TRIP_TYPE, TRIP_TYPE_TIME } from "../../../../utils/constants";
import HostSettings from "../../../../utils/host.settings";
// Icons
import airportIcon from "./airport-cities-mock-icons/Airplane.png";
import cityIcon from "./airport-cities-mock-icons/City.png";
import AirportOrCity from "./AirportOrCity/AirpotOrCity";
// Models
import { RiskAdvisoryOutput } from "./covid-popup/covid-modal.model";
// Components
import CovidPopup from "./covid-popup/covid-popup";
import CustomSlider from "./customSlider/customSlider";
import CytricFromDropDown from "./cytric-fromDropDown/cytric-fromDropDown";
import CytricToDropDown from "./cytric-toDropDown/cytric-toDropDown";
// Styles
import "./search.component.scss";
import {
  DropdownItem,
  LocationItem,
  LocationResponse,
  SearchCriteria,
  TripPurpose,
} from "./search.model";
import TextCovid from "./TextCovid/TextCovid";

const styles = {
  formDirection: "formDirection",
  formSchedule: "formSchedule",
  formPurpose: "formPurpose",
  arrowIcon: "arrowIcon",
  formMargins: "formMargins",
  formMarginsEditSearch: "formMarginsEditSearch",
  breadcrumb: "breadcrumb",
  breadcrumbLink: "breadcrumbLink",
  breadcrumbBorder: "breadcrumbBorder",
  warningLink: "warningLink",
  warningText: "warningText",
  headerTitle: "headerTitle",
  subheader: "subheader",
  warningAlert: "warningAlert",
  footerButtons: "footerButtons",
  breadcrumbDark: "breadcrumbDark",
  marginOutboundTime: "marginOutboundTime",
  marginInboundDate: "marginInboundDate",
  marginInboundTime: "marginInboundTime",
  marginOutboundDate: "marginOutboundDate",
  boldStyle: "boldStyle",
};

let tripPurposeAppears = "";
const optionsTrip = [
  {
    key: TRIP_TYPE.roundTrip.code,
    value: TRIP_TYPE.roundTrip.code,
    label: TRIP_TYPE.roundTrip.label,
  },
  {
    key: TRIP_TYPE.oneWay.code,
    value: TRIP_TYPE.oneWay.code,
    label: TRIP_TYPE.oneWay.label,
  },
];

const optionsTripTime = [
  {
    key: "1",
    value: TRIP_TYPE_TIME.departure.code,
    label: TRIP_TYPE_TIME.departure.label,
  },
  {
    key: "2",
    value: TRIP_TYPE_TIME.arrival.code,
    label: TRIP_TYPE_TIME.arrival.label,
  },
];

export default function SearchComponent(): JSX.Element {
  // Services
  const autoCompleteService = new AutoCompleteService();

  // Hooks

  const history = useHistory();

  const [formData, { dispatchFillingFields }] = useSearchFlightForm();

  const [state, setState] = useState<SearchCriteria>(formData);

  const {
    tripType,
    tripTypeTimeInbound,
    tripTypeTimeOutbound,
    from,
    to,
    fromIataCode,
    toIataCode,
    toCountryCode,
    departureDate,
    returnDate,
    departureTime,
    returnTime,
    purpose,
  } = state;

  const [value, setValue] = useState(departureTime.value);
  const [valueInbound, setValueInbound] = useState(returnTime.value);
  const [openModal, setOpenModal] = useState(false);
  const [covidMsgVisible, setCovidMsgVisible] = useState(true);
  const [fromOptions, setFromOptions] = useState<DropdownItem[]>([]);
  const [toOptions, setToOptions] = useState<DropdownItem[]>([]);

  const [{ themeString }] = useTeams();

  const airportsAndCitiesFromResponse: UseQueryResult<any | undefined> =
    useQuery(
      ["airportsAndCitiesFrom", state.from],
      () => autoCompleteService.getAirportsAndCitiesv2(state.from),
      {
        refetchOnWindowFocus: false,
      }
    );

  const airportsAndCitiesToResponse: UseQueryResult<any | undefined> = useQuery(
    ["airportsAndCitiesTo", state.to],
    () => autoCompleteService.getAirportsAndCitiesv2(state.to),
    {
      refetchOnWindowFocus: false,
    }
  );

  const fromResponse: LocationResponse =
    airportsAndCitiesFromResponse?.data?.data;
  const toResponse: LocationResponse = airportsAndCitiesToResponse?.data?.data;

  const createOptions = (airportsAndCities: LocationItem[]) => {
    const options: any = [];
    airportsAndCities.forEach((city: LocationItem, index: number) => {
      if (city?.associated) {
        options.push({
          name: `${city.location.name} (${city.location.iataCode})`,
          iatacode: city.location.iataCode,
          countrycode: city.location.address.countryCode,
          key: index,
          city: true,
          content: <AirportOrCity city={city} />,
        });

        city?.associated.forEach((airport) =>
          options.push({
            name: `${airport.name} (${airport.iataCode})`,
            iatacode: airport.iataCode,
            countrycode: airport.address.countryCode,
            key: `${index} ${airport.name}`,
            city: false,

            content: <AirportOrCity city={airport} />,
          })
        );
      } else {
        options.push({
          name: `${city.location.name} (${city.location.iataCode})`,
          iatacode: city.location.iataCode,
          countrycode: city.location.address.countryCode,
          key: index,
          city: false,
          content: <AirportOrCity city={city} />,
        });
      }
    });

    return options;
  };

  useEffect(() => {
    if (to) {
      setCovidMsgVisible(true);
    }
  }, [to]);

  useEffect(() => {
    setFromOptions([]);
    if (
      airportsAndCitiesFromResponse.data?.data?.data &&
      !airportsAndCitiesFromResponse.isLoading &&
      !airportsAndCitiesFromResponse.isError
    ) {
      setFromOptions(createOptions(fromResponse?.data));
    }
  }, [airportsAndCitiesFromResponse.data?.data]);

  useEffect(() => {
    setToOptions([]);
    if (
      airportsAndCitiesToResponse.data?.data?.data &&
      !airportsAndCitiesToResponse.isLoading &&
      !airportsAndCitiesToResponse.isError
    ) {
      setToOptions(createOptions(toResponse?.data));
    }
  }, [airportsAndCitiesToResponse.data?.data]);

  const tripPurposeService = new TripPurposeService();
  const covidModalService = new CovidModalService();

  // Functions
  const tripPurposeResponse = useQuery(
    ["fetchTripPurpose", fromIataCode, toIataCode],
    () => tripPurposeService.getTripPurpose(fromIataCode, toIataCode),
    {
      refetchOnWindowFocus: false,
    }
  );
  const covidModalResponseV2 = useQuery(
    ["covidDataV2", toCountryCode],
    () => covidModalService.getCovidInformationV2(toCountryCode),
    {
      refetchOnWindowFocus: false,
    }
  );
  const tripPurposeData: TripPurpose[] = tripPurposeResponse?.data?.data?.data;
  const tripPurposeMandatory: boolean =
    tripPurposeResponse?.data?.data?.mandatory;

  if (tripPurposeMandatory != null || tripPurposeMandatory !== undefined) {
    if (tripPurposeMandatory) {
      tripPurposeAppears = "mandatory";
    } else if (tripPurposeMandatory === false) {
      tripPurposeAppears = "optional";
    }
  } else {
    tripPurposeAppears = "not_set";
  }
  const covidModalData: RiskAdvisoryOutput = covidModalResponseV2?.data?.data;

  // eslint-disable-next-line react/no-unstable-nested-components
  function AlertCovidWarning() {
    return (
      <Alert
        warning
        className={styles.warningAlert}
        icon={<ExclamationCircleIcon outline />}
        content={
          <TextCovid
            covidModalData={covidModalData}
            setOpenModal={setOpenModal}
          />
        }
        dismissible
        visible={covidMsgVisible}
        onVisibleChange={() => setCovidMsgVisible(false)}
        dismissAction={{ "aria-label": "close" }}
      />
    );
  }

  const handleTypeChange = (e: any, props: RadioGroupItemProps | undefined) =>
    setState({ ...state, tripType: props?.value });

  const handleTypeTimeInboundChange = (
    e: any,
    props: RadioGroupItemProps | undefined
  ) => setState({ ...state, tripTypeTimeInbound: props?.value });

  const handleTypeTimeOutboundChange = (
    e: any,
    props: RadioGroupItemProps | undefined
  ) => setState({ ...state, tripTypeTimeOutbound: props?.value });

  const handleDepDateChange = (e: any, props: any | undefined) => {
    const returnDate: Date = new Date(props?.value);
    returnDate.setTime(returnDate.getTime() + 24 * 60 * 60 * 1000);
    setState({
      ...state,
      departureDate: props?.value ? props?.value : undefined,
      returnDate,
    });
  };

  const handleRetDateChange = (e: any, props: any | undefined) =>
    setState({
      ...state,
      returnDate: props?.value ? props?.value : undefined,
    });

  const handleDepTimeChange = () => {
    setState({
      ...state,
      departureTime: { value, type: tripTypeTimeOutbound },
    });
  };

  const handleRetTimeChange = () => {
    setState({
      ...state,
      returnTime: { value: valueInbound, type: tripTypeTimeInbound },
    });
  };

  const handlePurposeChange = (e: any, props: any | undefined) => {
    setState({
      ...state,
      purposeId: props?.value.id,
      purpose: props?.value.header,
    });
  };

  const handleSearchClick = () => {
    dispatchFillingFields(state);
    history.push("flight-results");
  };

  const handleCancelClick = (e: any) => {
    e.preventDefault();
    history.push("/travel");
  };

  const getDefaultDate = (date: any) => (date ? new Date(date) : undefined);

  const formLabelStyle = (theme: any) => ({
    backgroundColor: theme.theme.siteVariables?.formLabel.background,
    color: theme.theme.siteVariables?.formLabel.color,
  });

  const formDropdownStyle = (theme: any) => ({
    backgroundColor: theme.theme.siteVariables?.formDropdown.background,
    color: theme.theme.siteVariables?.formDropdown.color,
  });

  const tripPurposeLabel: string =
    tripPurposeAppears === "optional"
      ? "Trip purpose (optional)"
      : "Trip purpose";

  const getSearchButtonEnabled =
    from &&
    to &&
    (tripPurposeAppears === "mandatory" ? purpose : true) &&
    departureDate &&
    departureTime &&
    tripType &&
    (tripType === TRIP_TYPE.roundTrip.code ? returnDate && returnTime : true);

  // eslint-disable-next-line consistent-return
  return (
    <Flex id="searchFormContainer">
      <Flex column>
        <Breadcrumb
          aria-label="breadcrumb"
          size="medium"
          className={
            themeString === "default"
              ? styles.breadcrumb
              : styles.breadcrumbDark
          }
        >
          <Breadcrumb.Item>
            <Breadcrumb.Link
              href="/index.html#/travel"
              className={styles.breadcrumbLink}
            >
              Trips
            </Breadcrumb.Link>
          </Breadcrumb.Item>
          <Breadcrumb.Divider>
            <ChevronEndIcon outline size="smaller" />
          </Breadcrumb.Divider>
          <Breadcrumb.Item active>Plan a trip</Breadcrumb.Item>
        </Breadcrumb>
      </Flex>
      <Flex column className={styles.formMarginsEditSearch}>
        <Flex column style={{ backgroundColor: "transparent" }}>
          <h2 className={styles.headerTitle}>Search flights</h2>
          <p className={styles.subheader}>
            Where do you want to go? Please fill in the fields in order to
            proceed.
          </p>
        </Flex>
        <form action="/search" onSubmit={handleSearchClick}>
          <Flex style={{ backgroundColor: "transparent", margin: "15px 0" }}>
            <Flex gap="gap.medium">
              <Flex.Item>
                <RadioGroup
                  data-testid="tripType"
                  checkedValue={tripType}
                  items={optionsTrip}
                  onCheckedValueChange={handleTypeChange}
                />
              </Flex.Item>
            </Flex>
          </Flex>

          <Flex column>
            <Flex
              gap="gap.small"
              padding="padding.medium"
              className={styles.formDirection}
              variables={{ backgroundColor: "#ff0000" }}
            >
              <Flex.Item size="size.half">
                <div>
                  <Flex column gap="gap.small" vAlign="start" hAlign="start">
                    <Label content="From" styles={formLabelStyle} />
                    <CytricFromDropDown
                      data-testid="cytric-fromDropDown"
                      options={fromOptions}
                      isLoading={airportsAndCitiesFromResponse.isLoading}
                      state={state}
                      setState={setState}
                    />
                  </Flex>
                </div>
              </Flex.Item>
              <ArrowSortIcon outline className={styles.arrowIcon} />
              <Flex.Item size="size.half">
                <Flex column gap="gap.small" vAlign="start" hAlign="start">
                  <Label styles={formLabelStyle} content="To" />

                  <CytricToDropDown
                    data-testid="cytric-toDropDown"
                    options={toOptions}
                    isLoading={airportsAndCitiesToResponse.isLoading}
                    state={state}
                    setState={setState}
                  />
                </Flex>
              </Flex.Item>
            </Flex>

            <Flex
              gap="gap.small"
              padding="padding.medium"
              className={styles.formSchedule}
            >
              <Flex.Item size="size.half">
                <Flex gap="gap.small" wrap>
                  <Flex.Item grow>
                    <Flex
                      column
                      className={styles.marginOutboundDate}
                      gap="gap.small"
                      vAlign="start"
                      hAlign="start"
                    >
                      <Label styles={formLabelStyle} content="Outbound date" />
                      <Datepicker
                        data-testid="departureDatePicker"
                        styles={(theme) => ({
                          backgroundColor:
                            theme.theme.siteVariables?.formDropdown.background,
                          color: theme.theme.siteVariables?.formDropdown.color,
                        })}
                        placeholder="Select departure date"
                        minDate={new Date()}
                        defaultSelectedDate={getDefaultDate(departureDate)}
                        onDateChange={handleDepDateChange}
                      />
                    </Flex>
                  </Flex.Item>
                  <Flex.Item grow>
                    <Flex
                      column
                      className={styles.marginOutboundTime}
                      gap="gap.small"
                      vAlign="start"
                      hAlign="start"
                    >
                      <Label
                        styles={formLabelStyle}
                        content={
                          tripTypeTimeOutbound ===
                          TRIP_TYPE_TIME.departure.code ? (
                            <p>Departing after</p>
                          ) : (
                            <p>Arriving before</p>
                          )
                        }
                      />
                      <Popup
                        data-testid="departureTimeSlider"
                        position="below"
                        onOpenChange={handleDepTimeChange}
                        trapFocus
                        trigger={
                          <Input
                            icon={<ShiftActivityIcon />}
                            data-testid="departureTimeSliderInput"
                            fluid
                            styles={formDropdownStyle}
                            value={value}
                            placeholder="00:00"
                          />
                        }
                        content={
                          <>
                            <Header as="h4">
                              Select timing for your booking
                            </Header>
                            <RadioGroup
                              checkedValue={tripTypeTimeOutbound}
                              items={optionsTripTime}
                              onCheckedValueChange={
                                handleTypeTimeOutboundChange
                              }
                            />
                            {tripTypeTimeOutbound ===
                            TRIP_TYPE_TIME.departure.code ? (
                              <p>Departing after</p>
                            ) : (
                              <p>Arriving before</p>
                            )}
                            <CustomSlider
                              data-testid="custom-slider"
                              id="0"
                              value={value}
                              setValue={(props: any) => setValue(`${props}:00`)}
                              departureDate={departureDate}
                              returnDate={returnDate}
                              departureTime={departureTime}
                            />
                          </>
                        }
                      />
                    </Flex>
                  </Flex.Item>
                </Flex>
              </Flex.Item>

              {tripType === TRIP_TYPE.roundTrip.code ? (
                <FlexItem size="size.half">
                  <Flex wrap>
                    <Flex.Item grow>
                      <Flex
                        column
                        className={styles.marginInboundDate}
                        gap="gap.small"
                        vAlign="start"
                        hAlign="start"
                      >
                        <Label styles={formLabelStyle} content="Inbound date" />
                        <Datepicker
                          styles={formDropdownStyle}
                          data-testid="arrivalDatePicker"
                          placeholder="Select return date"
                          minDate={getDefaultDate(departureDate)}
                          selectedDate={getDefaultDate(returnDate)}
                          onDateChange={handleRetDateChange}
                        />
                      </Flex>
                    </Flex.Item>
                    <Flex.Item grow>
                      <Flex
                        column
                        className={styles.marginInboundTime}
                        gap="gap.small"
                        vAlign="start"
                        hAlign="start"
                      >
                        <Label
                          styles={formLabelStyle}
                          content={
                            tripTypeTimeInbound ===
                            TRIP_TYPE_TIME.departure.code ? (
                              <p>Departing after</p>
                            ) : (
                              <p>Arriving before</p>
                            )
                          }
                        />
                        <Popup
                          data-testid="arrivalTimeSlider"
                          position="below"
                          onOpenChange={handleRetTimeChange}
                          trapFocus
                          trigger={
                            <Input
                              icon={<ShiftActivityIcon />}
                              data-testid="arrivalTimeSliderInput"
                              fluid
                              styles={formDropdownStyle}
                              value={valueInbound}
                              placeholder="00:00"
                            />
                          }
                          content={
                            <>
                              <Header as="h4">
                                Select timing for your booking
                              </Header>
                              <RadioGroup
                                checkedValue={tripTypeTimeInbound}
                                items={optionsTripTime}
                                onCheckedValueChange={
                                  handleTypeTimeInboundChange
                                }
                              />
                              {tripTypeTimeInbound ===
                              TRIP_TYPE_TIME.departure.code ? (
                                <p>Departing after</p>
                              ) : (
                                <p>Arriving before</p>
                              )}
                              <CustomSlider
                                id="1"
                                value={valueInbound}
                                setValue={(props: any) =>
                                  setValueInbound(`${props}:00`)
                                }
                                departureDate={departureDate}
                                returnDate={returnDate}
                                departureTime={departureTime}
                              />
                            </>
                          }
                        />
                      </Flex>
                    </Flex.Item>
                  </Flex>
                </FlexItem>
              ) : null}
            </Flex>

            <Flex
              column
              hidden={tripPurposeAppears === "not_set"}
              gap="gap.small"
              vAlign="start"
              hAlign="start"
              padding="padding.medium"
              className={styles.formPurpose}
            >
              {tripPurposeResponse.isLoading ? (
                <Loader />
              ) : (
                tripPurposeData && (
                  <>
                    <Label
                      styles={formLabelStyle}
                      content={tripPurposeLabel}
                      className={styles.boldStyle}
                    />
                    <Dropdown
                      clearable={tripPurposeAppears === "optional"}
                      data-testid="tripPurpose"
                      fluid
                      styles={formDropdownStyle}
                      placeholder="Select a trip purpose"
                      defaultValue={
                        tripPurposeData.find(
                          (tp: TripPurpose) => tp.id === state.purposeId
                        )?.name
                      }
                      items={tripPurposeData.map(
                        (tp: TripPurpose) =>
                          ({ header: tp.name, id: tp.id } || [])
                      )}
                      onChange={handlePurposeChange}
                    />
                  </>
                )
              )}
            </Flex>
          </Flex>

          <Flex>
            <Flex column styles={{ width: "100%" }}>
              {covidModalResponseV2.isLoading && <Loader />}
              {!covidModalResponseV2.isError &&
                covidModalResponseV2?.data?.data && <AlertCovidWarning />}
              <CovidPopup
                open={openModal}
                setOpen={setOpenModal}
                countryCode={toCountryCode}
              />
              <Divider />
              <Flex
                hAlign="end"
                gap="gap.medium"
                padding="padding.medium"
                className={styles.footerButtons}
              >
                <Button
                  content="Cancel"
                  secondary
                  onClick={handleCancelClick}
                />
                <Button
                  content="Search"
                  primary
                  onClick={handleSearchClick}
                  disabled={!getSearchButtonEnabled}
                />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}

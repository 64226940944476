// Dependencies

import { Flex, Label, Slider } from "@fluentui/react-northstar";
import React from "react";

const checkHour = (departureDate: any, returnDate: any, departureTime: any) => {
  const calculatedNum = parseInt(departureTime.value, 10) + 2;
  return departureDate === returnDate ? calculatedNum : 0;
};

function CustomSlider({
  id,
  value,
  setValue,
  departureDate,
  returnDate,
  departureTime,
}: {
  id: any;
  value: any;
  setValue: any;
  departureDate: any;
  returnDate: any;
  departureTime: any;
}) {
  const result = checkHour(departureDate, returnDate, departureTime);
  return (
    <Flex
      inline
      hAlign="center"
      vAlign="center"
      gap="gap.smaller"
      data-testid="customSliderContainer"
    >
      <Slider
        min={id === "0" ? 0 : result}
        max={23}
        value={parseInt(value.split(":")[0], 10)}
        onChange={(e, data) => setValue(data?.value)}
      />
      <Label content={value} />
    </Flex>
  );
}

export default CustomSlider;

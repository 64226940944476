/* eslint-disable no-bitwise */
// Converts decimal to hex equivalent
// (From ADAL.js: https://github.com/AzureAD/azure-activedirectory-library-for-js/blob/dev/lib/adal.js)
export function decimalToHex(number: number) {
  let hex = number.toString(16);
  while (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
}

export default function guid() {
  // RFC4122: The version 4 UUID is meant for generating UUIDs from truly-random or
  // pseudo-random numbers.
  // The algorithm is as follows:
  //     Set the two most significant bits (bits 6 and 7) of the
  //        clock_seq_hi_and_reserved to zero and one, respectively.
  //     Set the four most significant bits (bits 12 through 15) of the
  //        time_hi_and_version field to the 4-bit version number from
  //        Section 4.1.3. Version4
  //     Set all the other bits to randomly (or pseudo-randomly) chosen
  //     values.
  // UUID                   = time-low "-" time-mid "-"time-high-and-version "-"clock-seq-reserved and low(2hexOctet)"-" node
  // time-low               = 4hexOctet
  // time-mid               = 2hexOctet
  // time-high-and-version  = 2hexOctet
  // clock-seq-and-reserved = hexOctet:
  // clock-seq-low          = hexOctet
  // node                   = 6hexOctet
  // Format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
  // y could be 1000, 1001, 1010, 1011 since most significant two bits needs to be 10
  // y values are 8, 9, A, B
  const cryptoObj = window.crypto;
  if (cryptoObj && cryptoObj.getRandomValues) {
    const buffer = new Uint8Array(16);
    cryptoObj.getRandomValues(buffer);
    // buffer[6] and buffer[7] represents the time_hi_and_version field. We will set the four most significant
    // bits (4 through 7) of buffer[6] to represent decimal number 4 (UUID version number).
    buffer[6] |= 0x40; // buffer[6] | 01000000 will set the 6 bit to 1.
    buffer[6] &= 0x4f; // buffer[6] & 01001111 will set the 4, 5, and 7 bit to 0 such that bits 4-7 == 0100 = "4".
    // buffer[8] represents the clock_seq_hi_and_reserved field. We will set the two most significant bits (6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively.
    buffer[8] |= 0x80; // buffer[8] | 10000000 will set the 7 bit to 1.
    buffer[8] &= 0xbf; // buffer[8] & 10111111 will set the 6 bit to 0.
    return `${
      decimalToHex(buffer[0]) +
      decimalToHex(buffer[1]) +
      decimalToHex(buffer[2]) +
      decimalToHex(buffer[3])
    }-${decimalToHex(buffer[4])}${decimalToHex(buffer[5])}-${decimalToHex(
      buffer[6]
    )}${decimalToHex(buffer[7])}-${decimalToHex(buffer[8])}${decimalToHex(
      buffer[9]
    )}-${decimalToHex(buffer[10])}${decimalToHex(buffer[11])}${decimalToHex(
      buffer[12]
    )}${decimalToHex(buffer[13])}${decimalToHex(buffer[14])}${decimalToHex(
      buffer[15]
    )}`;
  }
  const guidHolder = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  const hex = "0123456789abcdef";
  let r = 0;
  let guidResponse = "";
  for (let i = 0; i < 36; i += 1) {
    if (guidHolder[i] !== "-" && guidHolder[i] !== "4") {
      // each x and y needs to be random
      r = (Math.random() * 16) | 0;
    }
    if (guidHolder[i] === "x") {
      guidResponse += hex[r];
    } else if (guidHolder[i] === "y") {
      // clock-seq-and-reserved first hex is filtered and remaining hex values are random
      r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
      r |= 0x8; // set pos 3 to 1 as 1???
      guidResponse += hex[r];
    } else {
      guidResponse += guidHolder[i];
    }
  }
  return guidResponse;
}

/* eslint-disable no-await-in-loop */
import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";
import useGraph from "../../../../hooks/useGraph";
import getPresenceCollaborators from "../../utils/getPresenceCollaborators";
import GetCloseCollaboratorsParams from "../classes/ClassCloseCollaborators";
import {
  mapCollabImages,
  MapCollabsUsers,
} from "./FunctionsCloseCollaborators";
import getImages from "./GetImages";

export default function GetCloseCollaborators(
  countryCode: string,
  context: any
) {
  const queryCollabs = new GetCloseCollaboratorsParams();

  const { data, loading } = useGraph(
    async (graph) => {
      let collaboratorsGraph: { value: any[] };
      const collaborators: any[] = [];
      let isPeopleActive = true;

      try {
        for (
          let index = 0;
          index < queryCollabs.top && isPeopleActive;
          index += 20
        ) {
          const urlCollaborators = `me/people/?$top=${
            index + 20
          }&$skip=${index}&$Select=${queryCollabs.select}&$filter=${
            queryCollabs.filter
          }`;

          collaboratorsGraph = await graph.api(urlCollaborators).get();

          if (
            collaboratorsGraph.value.length > 0 &&
            collaborators.length < 10
          ) {
            const batchRequestContent = new MicrosoftGraph.BatchRequestContent(
              collaboratorsGraph.value.map((item) => ({
                id: item.id,
                request: new Request(
                  `users?$count=true&$select=id&$filter=id eq '${item.id}' and usageLocation eq '${countryCode}'`,
                  {
                    method: "GET",
                  }
                ),
              }))
            );
            const content = await batchRequestContent.getContent();
            const usersByCity = await graph.api("/$batch").post(content);

            MapCollabsUsers(
              usersByCity,
              collaboratorsGraph,
              collaborators,
              context
            );
          } else {
            isPeopleActive = false;
          }
        }

        const collabList = collaborators.map(({ id }: any) => id);

        const images = await getImages(graph, collabList);

        const presencesGraph = await getPresenceCollaborators(
          graph,
          collabList
        );

        mapCollabImages(collaborators, images, presencesGraph);

        return collaborators;
      } catch (error) {
        return collaborators;
      }
    },
    { scope: queryCollabs.scope }
  );

  return { data, loading };
}

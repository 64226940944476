import { Building24Filled } from "@fluentui/react-icons";
import {
  Card,
  cardSelectableBehavior,
  Checkbox,
  Flex,
  FlexItem,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import ProductHotel from "../../../../common/models/ProductHotel";
import DateHelper from "../../../../helpers/DateHelper";
import { isSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSelector";
import { updateSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/StoreHooks";
import "./HotelSegmentContent.scss";

interface HotelSegmentContentProps {
  hotelSegment: ProductHotel;
  tripId: string;
  segmentId: string;
}

function HotelSegmentContent(props: HotelSegmentContentProps) {
  const { hotelSegment, tripId, segmentId } = props;
  const hotelImg = <Building24Filled className="segment-icon" />;

  function formatAddress(productHotel: ProductHotel): string {
    if (productHotel.location) {
      return [
        productHotel.location.address.lines[0],
        productHotel.location.address.postalCode,
        productHotel.location.cityName,
        productHotel.location.countryCode,
      ]
        .filter((value) => value && value !== "")
        .join(", ");
    }

    return "";
  }

  const checked: boolean = useAppSelector((state) =>
    isSegmentSelected(state, {
      productType: hotelSegment.productType,
      id: segmentId,
    })
  );
  const dispatch = useAppDispatch();

  const header = <Text weight="bold"> {hotelSegment.name} </Text>;

  const checkbox = <Checkbox data-testid="air-checkbox" checked={checked} />;

  const content = <Text>{formatAddress(hotelSegment)}</Text>;

  return (
    <Flex column>
      <Text weight="bold">
        {DateHelper.formatDateTimeToDateRange(
          hotelSegment.checkInDate,
          hotelSegment.checkOutDate
        )}
      </Text>
      <Card
        accessibility={cardSelectableBehavior}
        selected={checked}
        className="card-segment"
        onClick={() =>
          dispatch(
            updateSegmentSelected({
              tripId,
              segmentId: {
                productType: hotelSegment.productType,
                id: segmentId,
              },
              isSelected: !checked,
            })
          )
        }
      >
        <Card.Header fitted>
          <Flex gap="gap.small" vAlign="center">
            {hotelImg}
            <Flex column>
              {header}
              {content}
            </Flex>
            <FlexItem push>{checkbox}</FlexItem>
          </Flex>
        </Card.Header>
      </Card>
    </Flex>
  );
}

export default HotelSegmentContent;

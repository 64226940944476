export default function geTheme(context: any) {
  const light = {
    styles: {
      backgroundColor: "#F5F5F5",
      bodyBackground: "#F5F5F5",
      color: "#000000",
      bodyColor: "#000000",
      subTitle: "#292929",
    },
    theme: {
      name: "white",
      siteVariables: {
        colors: {
          subtitle: "#292929",
          tripCard: {
            type: "#616161",
          },
        },
        backgroundColor: "#F5F5F5",
        card: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  };
  const dark = {
    styles: {
      backgroundColor: "#1F1F1F",
      bodyBackground: "#1F1F1F",
      color: "#FFFFFF",
      bodyColor: "#FFFFFF",
      subTitle: "#D6D6D6",
    },
    theme: {
      name: "dark",
      siteVariables: {
        colors: {
          subtitle: "#D6D6D6",
          tripCard: {
            type: "#ffffff",
          },
        },
        backgroundColor: "#1F1F1F",
        card: {
          backgroundColor: "#2d2c2c",
        },
      },
    },
  };
  const theme = context.theme === "default" ? light : dark;

  return theme;
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
} from "../../common/components/generic-info-component/GenericInfo.model";
import { UNAUTHORIZED_ERROR_CODE } from "../../common/Http";
import { EXPENSE_DENIED_ACCESS_CODE } from "../../utils/constants";
import CustomChart from "./chart/CustomChart";
import MergeAlert from "./duplicates/alert/MergeAlert";
import "./ExpenseDashboard.scss";
import { ErrorAccessInterface } from "./ExpenseDashboardInterfaces";
import { getCurrencyCode } from "./ExpenseDashboardRequests";
import DashboardHeader from "./header/DashboardHeader";
import Summary from "./summary/Summary";
import Todo from "./todo/Todo";

function ExpenseDashboard(): React.ReactElement {
  const { t } = useTranslation();

  const refreshWindow = () => {
    window.location.reload();
  };

  const errorInfoGeneric: IGenericInfo = {
    title: t("messages.ERR_Generic"),
    detail: t("upcoming_trips_dashboard.ERR_api_call"),
  };

  const errorInfoExpenseAccess: IGenericInfo = {
    title: t("messages.ERR_ExpenseTabActivation"),
    detail: t("messages.ERR_ExpenseTabActivationDetail"),
  };
  const [mergeActionType, setMergeActionType] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string>("");
  const [errorButtonAction, setErrorButtonAction] = useState<
    IGenericInfoAction | undefined
  >(undefined);
  const [accessError, setAccessError] = useState<ErrorAccessInterface | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);

  const [errorObject, setErrorObject] =
    useState<IGenericInfo>(errorInfoGeneric);

  const getCurrency = async () => {
    try {
      const currencyInfo = await getCurrencyCode();
      if (currencyInfo.isError) {
        setAccessError(currencyInfo);
      } else {
        setCurrency(currencyInfo);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const checkAccess = () => {
    if (accessError?.errorData?.errors[0].code === EXPENSE_DENIED_ACCESS_CODE) {
      setErrorObject(errorInfoExpenseAccess);
    } else {
      setErrorObject(errorInfoGeneric);
    }
  };

  const checkErrors = () => {
    if (accessError) {
      if (accessError.statusCode === UNAUTHORIZED_ERROR_CODE) {
        checkAccess();
      } else {
        setErrorButtonAction({
          text: t("App.Try_again"),
          command: refreshWindow,
        });
        setErrorObject(errorInfoGeneric);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getCurrency();
  }, []);

  useEffect(() => {
    checkErrors();
  }, [accessError]);

  if (isLoading) {
    return <Loader style={{ margin: 100 }} data-testid="loadingSpinner" />;
  }

  return accessError ? (
    <GenericInfo
      infoData={errorObject}
      action={errorButtonAction}
      image={InfoImage.ERROR}
    />
  ) : (
    <>
      {mergeActionType && (
        <MergeAlert
          mergeType={mergeActionType}
          setMergeType={setMergeActionType}
        />
      )}
      <DashboardHeader />
      <div className="tab-content">
        <div className="todo-segment">
          <Todo
            setMergeType={setMergeActionType}
            setAccessError={setAccessError}
          />
        </div>
        <div className="summary-segment">
          <Summary currency={currency} setAccessError={setAccessError} />
        </div>
        <div className="chart-segment">
          <CustomChart currency={currency} setAccessError={setAccessError} />
        </div>
      </div>
    </>
  );
}

export default ExpenseDashboard;

/* eslint-disable import/prefer-default-export */
import Http from "../../../common/Http";
import HostSettings from "../../../utils/host.settings";

export const getChartSpendingsResults = async (period: string) => {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}${HostSettings.getChartSpendings}?period=${period}`
    );
    if (response.status === 200) {
      const spendingsChartResults = await response.data;
      return spendingsChartResults.chartElements;
    }
  } catch (error: any) {
    if (error?.response === undefined) {
      return {
        statusCode: 1,
        errorData: [],
        isError: true,
      };
    }
    return {
      statusCode: error.response.status,
      errorData: error.response.data,
      isError: true,
    };
  }
  return null;
};

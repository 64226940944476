import React from "react";
import AirSegmentContent from "../air-segment-content/AirSegmentContent";
// eslint-disable-next-line import/no-cycle
import { SegmentFunctionData } from "../TripPanelContent";

function AirSegmentFunction(props: SegmentFunctionData) {
  const { product, trip, index } = props;

  if (product.Trip_Product_Air) {
    return (
      <AirSegmentContent
        key={`air-segment-${product.Trip_Product_Air.id}`}
        tripId={trip}
        segmentId={index.toString()}
        airSegment={product.Trip_Product_Air}
      />
    );
  }
  return null;
}

export default AirSegmentFunction;

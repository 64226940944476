/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/naming-convention */
// Dependencies
import { Flex, Text } from "@fluentui/react-northstar";
import { useState } from "react";
import Icon from "../../../../../common/icon/index";
// Styles
import "./flight-header.scss";

const populateOptions = (sf: any, position: number) => {
  const options: string[] = [""];
  if (sf.rank && position < 4) {
    options.push("RANKED");
  }
  if (sf.tags) {
    sf.tags.forEach((tags: any) => options.push(tags.type));
  }
  return options;
};

function FlightHeader({ offer, position }: { offer: any; position: number }) {
  const [options, _] = useState<string[]>(populateOptions(offer, position));

  return (
    <Flex className="headerContainer">
      <Flex gap="gap.small">
        <Flex
          vAlign="center"
          className={
            options.includes("RANKED")
              ? "selectedLabelContainer"
              : "labelContainer"
          }
          styles={(theme) => {
            if (options.includes("RANKED")) {
              return {
                backgroundColor:
                  theme.theme.siteVariables?.tagSelected.background,
              };
            }
            return {
              backgroundColor: theme.theme.siteVariables?.tag.background,
            };
          }}
        >
          <Text
            className={
              options.includes("RANKED") ? "selectedText" : "normalText"
            }
            styles={(theme) => {
              if (options.includes("RANKED")) {
                return {
                  color: theme.theme.siteVariables?.tagSelected.color,
                };
              }
              return {
                color: theme.theme.siteVariables?.tag.color,
              };
            }}
          >
            Tailored for you
          </Text>
          <Icon
            name="Ranked"
            styles={(theme) => {
              if (options.includes("RANKED")) {
                return {
                  color: theme.theme.siteVariables?.tagIconSelected.color,
                  marginLeft: 6,
                };
              }
              return {
                color: theme.theme.siteVariables?.tagIcon.color,
                marginLeft: 6,
              };
            }}
          />
        </Flex>
        <Flex
          vAlign="center"
          className={
            options.includes("GREENEST")
              ? "selectedLabelContainer"
              : "labelContainer"
          }
          styles={(theme) => {
            if (options.includes("GREENEST")) {
              return {
                backgroundColor:
                  theme.theme.siteVariables?.tagSelected.background,
              };
            }
            return {
              backgroundColor: theme.theme.siteVariables?.tag.background,
            };
          }}
        >
          <Text
            className={
              options.includes("GREENEST") ? "selectedText" : "normalText"
            }
            styles={(theme) => {
              if (options.includes("GREENEST")) {
                return {
                  color: theme.theme.siteVariables?.tagSelected.color,
                };
              }
              return {
                color: theme.theme.siteVariables?.tag.color,
              };
            }}
          >
            Greenest
          </Text>
          <Icon
            name="Greenest"
            styles={(theme) => {
              if (options.includes("GREENEST")) {
                return {
                  color: theme.theme.siteVariables?.tagIconSelected.color,
                  marginLeft: 6,
                };
              }
              return {
                color: theme.theme.siteVariables?.tagIcon.color,
                marginLeft: 6,
              };
            }}
          />
        </Flex>
        <Flex
          vAlign="center"
          className={
            options.includes("CHEAPEST")
              ? "selectedLabelContainer"
              : "labelContainer"
          }
          styles={(theme) => {
            if (options.includes("CHEAPEST")) {
              return {
                backgroundColor:
                  theme.theme.siteVariables?.tagSelected.background,
              };
            }
            return {
              backgroundColor: theme.theme.siteVariables?.tag.background,
            };
          }}
        >
          <Text
            className={
              options.includes("CHEAPEST") ? "selectedText" : "normalText"
            }
            styles={(theme) => {
              if (options.includes("CHEAPEST")) {
                return {
                  color: theme.theme.siteVariables?.tagSelected.color,
                };
              }
              return {
                color: theme.theme.siteVariables?.tag.color,
              };
            }}
          >
            Cheapest
          </Text>
          <Icon
            name="Cheapest"
            styles={(theme) => {
              if (options.includes("CHEAPEST")) {
                return {
                  color: theme.theme.siteVariables?.tagIconSelected.color,
                  marginLeft: 6,
                };
              }
              return {
                color: theme.theme.siteVariables?.tagIcon.color,
                marginLeft: 6,
              };
            }}
          />
        </Flex>
        <Flex
          vAlign="center"
          className={
            options.includes("SHORTEST")
              ? "selectedLabelContainer"
              : "labelContainer"
          }
          styles={(theme) => {
            if (options.includes("SHORTEST")) {
              return {
                backgroundColor:
                  theme.theme.siteVariables?.tagSelected.background,
              };
            }
            return {
              backgroundColor: theme.theme.siteVariables?.tag.background,
            };
          }}
        >
          <Text
            className={
              options.includes("SHORTEST") ? "selectedText" : "normalText"
            }
            styles={(theme) => {
              if (options.includes("SHORTEST")) {
                return {
                  color: theme.theme.siteVariables?.tagSelected.color,
                };
              }
              return {
                color: theme.theme.siteVariables?.tag.color,
              };
            }}
          >
            Shortest
          </Text>
          <Icon
            name="Shortest"
            styles={(theme) => {
              if (options.includes("SHORTEST")) {
                return {
                  color: theme.theme.siteVariables?.tagIconSelected.color,
                  marginLeft: 6,
                };
              }
              return {
                color: theme.theme.siteVariables?.tagIcon.color,
                marginLeft: 6,
              };
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FlightHeader;

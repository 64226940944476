/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-nested-ternary */
import { Image, Text } from "@fluentui/react-northstar";
import React from "react";
import airportIcon from "../airport-cities-mock-icons/Airplane.png";
import cityIcon from "../airport-cities-mock-icons/City.png";
import { LocationItem } from "../search.model";

function AirportOrCity({ city }: { city: LocationItem | any }): JSX.Element {
  return city?.associated ? (
    <div className="cityOption" data-testid="airportOrCityContainer">
      <Image src={cityIcon} className="cityIcon" />
      <span style={{ width: "100%", marginTop: "1", fontSize: "14" }}>
        {city.location.name} ({city.location.iataCode})
      </span>
      <Image src={city.location.address.countryFlag} className="dropdownFlag" />
      <Text style={{ fontSize: "10", marginTop: "2" }}>
        {city.location.address.countryCode}
      </Text>
    </div>
  ) : city?.location?.name ? (
    <div className="cityOption">
      <Image src={airportIcon} className="singleAirport" />
      <span className="singleAirportName">
        {city.location.name} ({city.location.iataCode})
      </span>

      <Image src={city.location.address.countryFlag} className="dropdownFlag" />
      <Text style={{ fontSize: "10", marginTop: "2" }}>
        {city.location.address.countryCode}
      </Text>
    </div>
  ) : (
    <div className="airportOption">
      <Image src={airportIcon} className="airportIcon" />
      <span className="airportName">
        {city.name} ({city.iataCode})
      </span>
      <Image src={city.address.countryFlag} className="dropdownFlag" />
      <Text style={{ fontSize: "10", marginTop: "2" }}>
        {city.address.countryCode}
      </Text>
    </div>
  );
}

export default AirportOrCity;

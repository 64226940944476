/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import {
  Loader,
  mergeThemes,
  Provider as UIProvider,
  teamsTheme,
} from "@fluentui/react-northstar";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useEffect, useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import GenericInfo from "../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
} from "../common/components/generic-info-component/GenericInfo.model";
import LoginComponent from "../common/components/login-component/login-component";
import UnauthorizedComponent from "../common/components/unauthorized-component/UnauthorizedComponent";
import { ApiDataModel, setHttpData } from "../common/Http";
import { IError } from "../common/models/Error";
import { ITelemetryProviderProps } from "../common/telemetry/TelemetryModel";
import TelemetryProvider from "../common/telemetry/TelemetryProvider";
import { telemetryService } from "../common/telemetry/TelemetryService";
// Components
import useTeamsFx from "../hooks/useTeamsFx";
import "../i18n/i18n.json";
import "../i18n/i18n.ts";
import {
  isLoggedInSelector,
  userObjectIdSelector,
} from "../store/authentication/AuthenticationSelector";
import {
  resetAuthentication,
  updateUserObjectId,
} from "../store/authentication/AuthenticationSlice";
import { resetCytricContext } from "../store/cytric-context/CytricContextSlice";
import store from "../store/Store";
import { useAppSelector } from "../store/StoreHooks";
import HostSettings from "../utils/host.settings";
import "./App.scss";
import CloseCollaborators from "./close-collaborators/CloseCollaborators";
import ExpenseDashboard from "./expense-dashboard/ExpenseDashboard";
import Feedback from "./feedback/Feedback";
import PersonalTab from "./personal-tab/PersonalTab";
import FlightResults from "./searchFlights/components/flight-results/flight-results";
import SearchFlightsTab from "./searchFlights/SearchFlightsTab";
import Settings from "./settings/Settings";
import TripList from "./trip-list/TripList";
import UpcomingTripDetail from "./upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetail";
import UpcomingTripsDashboard from "./upcoming-trips-dashboard/UpcomingTripsDashboard";
import useTheme from "./upcoming-trips-dashboard/useTheme";
// Components where authentication is required
function RenderOptions() {
  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );

  if (HostSettings.isCytricLoginEnabled && !isLoggedIn) {
    return <LoginComponent />;
  }

  return (
    <>
      <Route exact path="/">
        <Redirect to="/mytrips" />
      </Route>
      <Route exact path="/travel" component={UpcomingTripsDashboard} />
      <Route exact path="/trip:tripId" component={UpcomingTripDetail} />
      <Route exact path="/search-flights" component={SearchFlightsTab} />
      <Route exact path="/flight-results" component={FlightResults} />
      <Route exact path="/close-collaborators" component={CloseCollaborators} />
      <Route exact path="/cytricweb" component={PersonalTab} />
      <Route exact path="/messaging-trip-list" component={TripList} />
      <Route exact path="/expenses" component={ExpenseDashboard} />
      <Route exact path="/unauthorized" component={UnauthorizedComponent} />
    </>
  );
}

export function ErrorFallback({ error }: FallbackProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const requestId = telemetryService.trackException({
    error,
    severityLevel: SeverityLevel.Error,
  });
  const errorInfoBoundary: IGenericInfo = {
    title: t("messages.ERR_Generic"),
    detail: `Error reference: ${requestId}`,
  };
  const action: IGenericInfoAction = {
    text: t("App.Try_again"),
    command: () => {
      history.go(0);
    },
  };
  return (
    <GenericInfo
      infoData={errorInfoBoundary}
      action={action}
      image={InfoImage.ERROR}
    />
  );
}

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme, inTeams, context, themeString } = useTeamsFx();
  const history = useHistory();
  const [errorTeams, setErrorTeams] = useState(false);
  const { i18n, t } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);
  const [customTheme] = useTheme(themeString);
  const userObjectId: string = useAppSelector((state) =>
    userObjectIdSelector(state)
  );

  const queryCLient = new QueryClient();

  const telemetryProviderProps: ITelemetryProviderProps = {
    history,
    instrumentationKey: HostSettings.getInstrumentationKey,
    enabled: HostSettings.isTelemetryEnabled,
  };

  const handleUserIdentityChange = (
    currentContext: microsoftTeams.Context,
    previousUserObjectId: string
  ) => {
    if (currentContext.userObjectId !== previousUserObjectId) {
      // reset all store data from local store linked to the previous user
      store.dispatch(resetAuthentication());
      store.dispatch(resetCytricContext());
      store.dispatch(
        updateUserObjectId({ userObjectId: currentContext.userObjectId || "" })
      );
    }
  };

  useEffect(() => {
    if (inTeams === false) {
      setErrorTeams(true);
      setIsInitialized(true);
    }
    if (context) {
      handleUserIdentityChange(context, userObjectId);
      telemetryService.setUserId(context && context.userObjectId);
      const data: ApiDataModel = {
        apiKey: HostSettings.getApiKey,
        context,
        telemetryService,
        unauthorizedUrl: "unauthorized",
        keycloack: {
          uri: HostSettings.getKeycloakUri,
          authUri: HostSettings.getKeycloakAuthUri,
          scope: HostSettings.tokenScopes,
          isEnabled: HostSettings.isCytricLoginEnabled,
        },
      };
      setHttpData(data);
      setIsInitialized(true);
      i18n.changeLanguage(context?.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inTeams, i18n, context]);

  const errorInfo: IError = {
    status: 0,
    code: 0,
    title: t(`messages.ERR_Generic`),
    detail: t(`messages.ERR_Teams`),
  };

  const renderApp = () => {
    if (!isInitialized) {
      return <Loader style={{ margin: 100 }} />;
    }
    if (errorTeams) {
      return <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />;
    }
    return (
      <Switch>
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/feedback" component={Feedback} />
        <Route render={() => <RenderOptions />} />
      </Switch>
    );
  };

  return (
    <UIProvider
      styles={customTheme.styles}
      theme={mergeThemes(theme || teamsTheme, customTheme.theme)}
    >
      <QueryClientProvider client={queryCLient}>
        <Router>
          <TelemetryProvider {...telemetryProviderProps}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {renderApp()}
            </ErrorBoundary>
          </TelemetryProvider>
        </Router>
      </QueryClientProvider>
    </UIProvider>
  );
}

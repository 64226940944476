/* eslint-disable no-nested-ternary */
import {
  Flex,
  Loader,
  ProviderConsumer as FluentUIThemeConsumer,
} from "@fluentui/react-northstar";
import React from "react";
import ChartEmptyState from "./charts/EmptyState";
import ChartErrorState from "./charts/ErrorState";
import { HorizontalBar } from "./charts/HorizontalBar";
import { EChartTypes, IChartData } from "./ChartTypes";

export interface IChartProps {
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;
  type: EChartTypes;
  // eslint-disable-next-line react/require-default-props
  data?: IChartData;
  currency: string;
}

export function Chart({ type, data, currency }: IChartProps) {
  const renderChart = (theme: any) => {
    let result;
    if (data) {
      if (data?.datasets.length) {
        if (type === EChartTypes.BarHorizontal) {
          result = (
            <HorizontalBar data={data} theme={theme} currency={currency} />
          );
        } else {
          result = <ChartErrorState theme={theme} />;
        }

        if (data?.datasets[0].data.length === 0) {
          result = <ChartEmptyState theme={theme} />;
        }
      }
    } else {
      result = <ChartErrorState theme={theme} />;
    }
    return result;
  };

  return (
    <FluentUIThemeConsumer
      render={(globalTheme) => (
        <React.Suspense fallback={<Loader />}>
          <Flex
            column
            style={{
              height: "100%",
              minHeight: "14rem",
              margin: "0 -1rem 0 0",
              paddingBottom: ".5rem",
              width: "100%",
            }}
          >
            {renderChart(globalTheme)}
          </Flex>
        </React.Suspense>
      )}
    />
  );
}

import { Loader } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfoAction,
  InfoImage,
} from "../../common/components/generic-info-component/GenericInfo.model";
import Http from "../../common/Http";
import { IError } from "../../common/models/Error";
import HostSettings from "../../utils/host.settings";
import UpcomingTrips from "./UpcomingTrips/UpcomingTrips";
import Welcome from "./Welcome/Welcome";

function Error() {
  const history = useHistory();
  const { t } = useTranslation();

  const errorInfo: IError = {
    status: 0,
    code: 0,
    title: t(`messages.ERR_Generic`),
    detail: t(`upcoming_trips_dashboard.ERR_api_call`),
  };
  const action: IGenericInfoAction = {
    text: t(`upcoming_trips_dashboard.Button_try_again`),
    command: () => {
      history.go(0);
    },
  };

  return (
    <GenericInfo infoData={errorInfo} action={action} image={InfoImage.ERROR} />
  );
}

export default function UpcomingTripsDashboard() {
  const [content, setContent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getTrips = async () => {
      const url = `${HostSettings.getBaseAPI}${HostSettings.getTripList}`;

      setIsLoading(true);

      const response = await Http.get(url);
      const data = (await response.data.data) || (await response.data);

      return data;
    };

    getTrips()
      .then((data) => {
        setIsLoading(false);
        if (data && data.length > 0) {
          setContent(<UpcomingTrips data={data} />);
        } else {
          setContent(<Welcome />);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setContent(<Error />);
      });
  }, []);

  if (isLoading) {
    return <Loader style={{ margin: 100 }} data-testid="tab-loader" />;
  }

  return content;
}

/* eslint-disable react/react-in-jsx-scope */
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import CloseCollaboratorsTable from "../close-collaborators-table/CloseCollaboratorsTable";
import { ICloseCollaboratorsProps } from "../CloseCollaborators.model";
import stylesCloseCollaborators from "../styles/close-collaborators.module.scss";
import getTheme from "../utils/getTheme";
import CloseCollaboratorsListComponents from "./CloseCollaboratorsListComponents";
import GetCloseCollaborators from "./utils/GetCloseCollaborators";
import getFilteredUser from "./utils/GetFilteredUser";

export default function CloseCollaboratorsList({
  destination,
  context,
}: ICloseCollaboratorsProps) {
  const { countryCode } = destination;
  const [collaboratorsValue, setCollaboratorsValue] = useState([{}]);
  const [initialValue, setInitialValue] = useState([{}]);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);
  const customTheme = getTheme(context);
  const themeName = customTheme.theme.name;

  const { data: dataCollaborators, loading: loadingDataCollaborators } =
    GetCloseCollaborators(countryCode, context);

  const closeCollaboratorsFindaContact = CloseCollaboratorsListComponents(
    countryCode,
    setCollaboratorsValue,
    initialValue,
    setLoadingCollaborators,
    context
  );

  useEffect(() => {
    if (dataCollaborators && context) {
      const dataCollaboratorsFilter = getFilteredUser(
        dataCollaborators,
        context
      );
      setInitialValue(dataCollaboratorsFilter);
      setCollaboratorsValue(dataCollaboratorsFilter);
      setLoadingCollaborators(loadingDataCollaborators);
    }
  }, [dataCollaborators, loadingDataCollaborators, context]);

  return (
    <div
      className={[
        themeName === "white"
          ? stylesCloseCollaborators.white
          : stylesCloseCollaborators.dark,
        stylesCloseCollaborators.card,
      ].join(" ")}
    >
      <Flex gap="gap.small" className={stylesCloseCollaborators.cardHeader}>
        <Flex column>
          <Text
            content="Close collaborators at your destination"
            className={stylesCloseCollaborators.title}
          />
          <Text
            content="These are some of the contacts at your destination based on your interaction with the workplace users. "
            size="small"
            className={stylesCloseCollaborators.subtitle}
          />
          <Text
            content="Meeting your peers can help you bring novel ideas and resources for your trip."
            size="small"
            className={stylesCloseCollaborators.subtitle}
          />
        </Flex>
      </Flex>
      <Flex className={stylesCloseCollaborators.cardSearch}>
        {closeCollaboratorsFindaContact}
      </Flex>
      <div className={stylesCloseCollaborators.cardBody}>
        {loadingCollaborators ? (
          <Loader label="Loading your close collaborators" />
        ) : (
          <CloseCollaboratorsTable
            collaborators={collaboratorsValue}
            context={context}
          />
        )}
      </div>
    </div>
  );
}

/* eslint-disable react/react-in-jsx-scope */
import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { WELCOME } from "../constants";
import styles from "./Welcome.module.scss";

function Welcome() {
  const history = useHistory();

  const planATripHandler = () => {
    history.push("search-flights");
  };

  return (
    <Flex hAlign="center" vAlign="center" className={styles.welcome}>
      <Flex column hAlign="center">
        <Image src="hello.png" />
        <Text content={WELCOME.title} className={styles.welcome__title} />
        <Text
          content={WELCOME.subTitle}
          className={`${styles.welcome__subtitle}`}
          styles={(theme) => ({
            color: theme.theme.siteVariables.welcome?.subtitle,
          })}
        />
        <div className={styles["welcome__button-wrapper"]}>
          <Button
            className={styles["welcome__plan-a-trip-button"]}
            content={WELCOME.buttonText}
            fluid
            primary
            disabled={false}
            onClick={planATripHandler}
          />
        </div>
      </Flex>
    </Flex>
  );
}

export default Welcome;

import { VehicleSubway24Filled } from "@fluentui/react-icons";
import {
  Card,
  cardSelectableBehavior,
  Checkbox,
  Flex,
  FlexItem,
  Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import ProductTrain from "../../../../common/models/ProductTrain";
import DateHelper from "../../../../helpers/DateHelper";
import { isSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSelector";
import { updateSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/StoreHooks";
import "./RailSegmentContent.scss";

interface RailSegmentContentProps {
  railSegment: ProductTrain;
  tripId: string;
  segmentId: string;
}

function RailSegmentContent(props: RailSegmentContentProps) {
  const { railSegment, tripId, segmentId } = props;
  const railImg = <VehicleSubway24Filled className="segment-icon" />;

  const checked: boolean = useAppSelector((state) =>
    isSegmentSelected(state, {
      productType: railSegment.productType,
      id: segmentId,
    })
  );
  const dispatch = useAppDispatch();

  const checkbox = <Checkbox data-testid="air-checkbox" checked={checked} />;

  const displayStopsInfo = (currentRailSegment: ProductTrain) => {
    if (
      !currentRailSegment.boundStops ||
      !currentRailSegment.boundStops.length
    ) {
      return "";
    }

    return currentRailSegment.boundStops.reduce(
      (stopsInfo, currentBound) =>
        `${stopsInfo}, ${currentBound.category} ${currentBound.number}`,
      ""
    );
  };

  const header = (
    <Flex>
      <Text weight="bold">
        {moment.utc(railSegment.departureDateTime).format("LT")}
        &nbsp;
      </Text>
      <Text>{railSegment.departure?.name} &rarr;&nbsp;</Text>
      <Text weight="bold">
        {moment.utc(railSegment.arrivalDateTime).format("LT")}
        &nbsp;
      </Text>
      <Text>{railSegment.arrival?.name}</Text>
    </Flex>
  );
  const content = (
    <Text>{`${railSegment.serviceProviderName} | ${
      railSegment.vehicle?.category
    } ${railSegment.vehicle?.number}${displayStopsInfo(railSegment)}`}</Text>
  );

  return (
    <Flex column>
      <Text weight="bold">
        {DateHelper.formatDateTimeToDateRange(
          railSegment.departureDateTime,
          railSegment.arrivalDateTime
        )}
      </Text>
      <Card
        accessibility={cardSelectableBehavior}
        selected={checked}
        className="card-segment"
        onClick={() =>
          dispatch(
            updateSegmentSelected({
              tripId,
              segmentId: {
                productType: railSegment.productType,
                id: segmentId,
              },
              isSelected: !checked,
            })
          )
        }
      >
        <Card.Header fitted>
          <Flex gap="gap.small" vAlign="center">
            {railImg}
            <Flex column>
              {header}
              {content}
            </Flex>
            <FlexItem push>{checkbox}</FlexItem>
          </Flex>
        </Card.Header>
      </Card>
    </Flex>
  );
}

export default RailSegmentContent;

const DateUtils = {
  formatDate: (date: Date) => {
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    const year = date.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  },
  formatLongDate: (date: string) => {
    const dateFormat = new Date(date);
    return dateFormat.toLocaleDateString("en-GB", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  },
  getDateInMiliseconds: (date: string) => new Date(date).valueOf(),
  getTimeFromDate: (date: Date) =>
    new Date(date).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    }),
  getHoursFormMilliseconds: (millisec: number) => {
    const minutes = Math.floor((millisec / (60 * 1000)) % 60);
    const hours = Math.floor((millisec / (3600 * 1000)) % 3600);

    const getHours = () => (hours > 0 ? `${hours}h` : "");
    const getMinutes = () => (minutes > 0 ? ` ${minutes}m` : "");

    return `${getHours()}${getMinutes()}`;
  },
  getDayDifference: (departureDate: Date, arrivalDate: Date) => {
    const departure = departureDate.toString().substring(0, 10);
    const arrival = arrivalDate.toString().substring(0, 10);
    const sameDay = departure === arrival;

    if (sameDay) {
      return "";
    }

    const diffInMs =
      new Date(arrival).valueOf() - new Date(departure).valueOf();
    const diffInDays = diffInMs / (1000 * 3600 * 24);

    return `+${diffInDays}`;
  },
};

export default DateUtils;

import { Text } from "@fluentui/react-northstar";
import React from "react";
import "../search.component.scss";

function TextCovid({
  covidModalData,
  setOpenModal,
}: {
  covidModalData: any;
  setOpenModal: (_: boolean) => void;
}) {
  return covidModalData.data[0].area ? (
    <p className="warningText">
      COVID-19 Risk level in {covidModalData.data[0].area.name} is{" "}
      {covidModalData.data[0].diseaseRiskLevel}.<Text> </Text>
      <Text className="warningLink" onClick={() => setOpenModal(true)}>
        See more information
      </Text>
    </p>
  ) : null;
}

export default TextCovid;

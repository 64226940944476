/* eslint-disable react/react-in-jsx-scope */
import {
  CalendarIcon,
  ChatIcon,
  ContactCardIcon,
  EmailIcon,
  Flex,
  MoreIcon,
  Text,
} from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { RoleModel } from "../../models/CloseCollaboratorsModel";
import stylesCloseCollaborators from "../../styles/close-collaborators.module.scss";
import getChat from "../utils/GetChat";

export const headerName = () => {
  const contentHeaderName = (
    <Flex gap="gap.small">
      <ContactCardIcon outline />
      <Text content="Name" size="small" />
    </Flex>
  );
  return contentHeaderName;
};

export const header = {
  key: "header",
  className: stylesCloseCollaborators.tableHeader,
  items: [
    {
      content: headerName(),
      key: "header-user",
      className: stylesCloseCollaborators.firstCol,
    },
    {
      content: "Role",
      key: "header-role",
      className: stylesCloseCollaborators.roleHeader,
    },
    {
      content: "",
      key: "header-options",
      className: stylesCloseCollaborators.options,
    },
  ],
};

export const role = (contentPropsRole: RoleModel) => {
  const propsRole = contentPropsRole || "";
  const contentRole = (
    <Flex column>
      <Text content={propsRole} size="medium" weight="regular" />
    </Flex>
  );
  return contentRole;
};

export const openOutlook = (email: string) => {
  window.location.href = `mailto:${email}`;
};

export const openCalendar = (email: string) => {
  const meetingLink = `https://teams.microsoft.com/l/meeting/new?attendees=${email}`;
  microsoftTeams.executeDeepLink(meetingLink);
};

export const optionItemsCollaborator = (name: string, email: string) => {
  const OptionItems = [
    {
      icon: <ChatIcon outline />,
      key: "chat",
      active: false,
      className: "chat",
      title: `Chat with ${name}`,
      onClick: () => {
        getChat(email);
      },
    },
    {
      icon: <EmailIcon outline />,
      key: "email",
      active: false,
      className: "email",
      title: `Send email to ${email}`,
      onClick: () => {
        openOutlook(email);
      },
    },
    {
      icon: <MoreIcon outline />,
      key: "more",
      indicator: false,
      className: stylesCloseCollaborators.moreIcon,
      title: "More options",
      menu: {
        items: [
          {
            key: "menu-meeting",
            content: "Schedule a meeting",
            title: `Schedule a meeting with ${name}`,
            icon: <CalendarIcon outline />,

            onClick: () => {
              openCalendar(email);
            },
          },
        ],
      },
    },
  ];
  return OptionItems;
};

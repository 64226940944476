import Http from "../../common/Http";
import AirRedirectionInput from "../../common/models/redirection/AirRedirectionInput";
import AirRedirectionParam from "../../common/models/redirection/AirRedirectionParam";
import CarRedirectionInput from "../../common/models/redirection/CarRedirectionInput";
import CarRedirectionParam from "../../common/models/redirection/CarRedirectionParam";
import ExpenseRedirectionInput from "../../common/models/redirection/ExpenseRedirectionInput";
import HotelRedirectionInput from "../../common/models/redirection/HotelRedirectionInput";
import HotelRedirectionParam from "../../common/models/redirection/HotelRedirectionParam";
import RailRedirectionInput from "../../common/models/redirection/RailRedirectionInput";
import RailRedirectionParam from "../../common/models/redirection/RailRedirectionParam";
import RedirectionInput from "../../common/models/redirection/RedirectionInput";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import RedirectionParamaterHelper from "../../helpers/RedirectionParamaterHelper";
import HostSettings from "../../utils/host.settings";

export default class RedirectionService {
  private static readonly SERVER_URL = HostSettings.getBaseAPI;

  private static readonly REDIRECTION_PATH = "api/corporation/redirect/";

  private static async getDashboardCytricUrl(): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}dashboard`);
  }

  private static async getFlightCytricUrl(
    params: AirRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}flight`, {
      params,
    });
  }

  private static async getRailCytricUrl(
    params: RailRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}rail`, {
      params,
    });
  }

  private static async getCarCytricUrl(
    params: CarRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}car`, {
      params,
    });
  }

  private static async getHotelCytricUrl(
    params: HotelRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}hotel`, {
      params,
    });
  }

  private static async getExpenseCytricUrl(params: any): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}your-expenses`, {
      params,
    });
  }

  public static async getCytricUrl(redirectionInput: RedirectionInput) {
    switch (redirectionInput.segmentType) {
      case RedirectionTypeEnum.FLIGHT: {
        const airParam =
          RedirectionParamaterHelper.transformAirRedirectionInput(
            redirectionInput as AirRedirectionInput
          );
        return this.getFlightCytricUrl(airParam);
      }
      case RedirectionTypeEnum.CAR: {
        const carParam =
          RedirectionParamaterHelper.transformCarRedirectionInput(
            redirectionInput as CarRedirectionInput
          );
        return this.getCarCytricUrl(carParam);
      }
      case RedirectionTypeEnum.HOTEL: {
        const hotelParam =
          RedirectionParamaterHelper.transformHotelRedirectionInput(
            redirectionInput as HotelRedirectionInput
          );
        return this.getHotelCytricUrl(hotelParam);
      }
      case RedirectionTypeEnum.RAIL: {
        const railParam =
          RedirectionParamaterHelper.transformRailRedirectionInput(
            redirectionInput as RailRedirectionInput
          );
        return this.getRailCytricUrl(railParam);
      }
      case RedirectionTypeEnum.EXPENSE: {
        const expenseParam =
          RedirectionParamaterHelper.transformExpenseRedirectionInput(
            redirectionInput as ExpenseRedirectionInput
          );
        return this.getExpenseCytricUrl(expenseParam);
      }
      default: {
        return this.getDashboardCytricUrl();
      }
    }
  }
}

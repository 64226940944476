import { Box, Card, CardBody } from "@fluentui/react-northstar";
import React from "react";
import { useHistory } from "react-router-dom";
import DateComponent from "../Date/DateComponent";
import Destination from "../Destination/Destination";
import DestinationImage from "../DestinationImage/DestinationImage";
import SegmentIcons from "../Segments/SegmentIcons";
import { ITripCardProps } from "./TripCard.model";
import Styles from "./TripCard.module.scss";

function TripCard({ trip }: ITripCardProps) {
  const history = useHistory();
  const selectTripClickHandler = () => {
    history.push(`/trip${trip.id}`);
  };
  return (
    <Card
      fluid
      compact
      elevated
      className={Styles["trip-card"]}
      style={{
        borderStyle: "none",
        borderRadius: "4px",
      }}
      onClick={selectTripClickHandler}
      styles={(theme) => ({
        background: theme.theme.siteVariables.tripCard?.background,
      })}
      data-testid="card"
    >
      <DestinationImage trip={trip} />
      <CardBody className={Styles["trip-card__body"]}>
        <Destination trip={trip} />
        <div className={Styles["trip-card__date"]}>
          <DateComponent trip={trip} />
        </div>
        <Box
          className={Styles["trip-card__line"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.tripCard?.divider,
          })}
        />
        <SegmentIcons trip={trip} />
      </CardBody>
    </Card>
  );
}

export default TripCard;

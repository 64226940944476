/* eslint-disable react/react-in-jsx-scope */
import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import { StatusModel } from "../../models/CloseCollaboratorsModel";
import stylesCloseCollaborators from "../../styles/close-collaborators.module.scss";
import statusIcon from "../close-collaborators-user-status/StatusIcon";
import statusIconClass from "../close-collaborators-user-status/StatusIconClass";

export default function User(props: StatusModel) {
  const { displayName, image, presence } = props;

  return (
    <Flex
      gap="gap.small"
      vAlign="center"
      className={stylesCloseCollaborators.userText}
    >
      <Flex.Item>
        <Avatar
          image={{ src: image }}
          name={displayName}
          className={stylesCloseCollaborators.avatar}
          status={{
            styles: (theme) => ({
              backgroundColor:
                theme.theme.siteVariables.widgetContainer?.widget,
            }),
            icon: statusIcon(presence?.availability),
            title: presence?.activity,
            className: `${stylesCloseCollaborators.statusIndicator} ${
              statusIconClass(presence?.availability).icon.class
            }`,
          }}
        />
      </Flex.Item>
      <Flex.Item className={stylesCloseCollaborators.userText}>
        <Flex column className={stylesCloseCollaborators.userText}>
          <Text content={displayName} size="medium" weight="regular" />
        </Flex>
      </Flex.Item>
    </Flex>
  );
}

enum RedirectionTypeEnum {
  FLIGHT = "Flight",
  CAR = "Car",
  HOTEL = "Hotel",
  RAIL = "Rail",
  DASHBOARD = "Dashboard",
  EXPENSE = "Expense",
}

export default RedirectionTypeEnum;

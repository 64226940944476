/* eslint-disable react/react-in-jsx-scope */
import { ComponentStyleFunctionParam, Text } from "@fluentui/react-northstar";
import { composeTripName } from "../../trip-utils";

import DateComponent from "../../UpcomingTrips/Date/DateComponent";
import Styles from "./Header.module.scss";

function Header({ trip }: any) {
  const statusList = [
    "to-be-approved",
    "preapproved",
    "approved",
    "rejected",
    "rejected-not-cancelled",
    "rejected-not-canceled",
    "",
  ];
  const statusStyleNameWithDash = trip ? trip.status?.replace(/ /g, "-") : "";
  const statusStyleName = statusList.includes(statusStyleNameWithDash)
    ? statusStyleNameWithDash
    : "unknown";

  const STATUS_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.color
      : "",
    borderColor: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.borderColor
      : "",
    background: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.background
      : "",
  });

  const status =
    trip?.status === "rejected not canceled" ||
    trip?.status === "rejected not cancelled"
      ? "Rejected"
      : trip?.status;

  const tripName = composeTripName(trip, "Trip to");

  return (
    <div className={Styles.header}>
      <Text
        className={Styles.header__title}
        as="div"
        styles={(theme) => ({
          color: theme.theme.siteVariables.upcomingTrips?.header.title,
        })}
      >
        {tripName}
      </Text>
      <div className={Styles["header__date-and-status-container"]}>
        <div className={Styles.header__date}>
          <DateComponent trip={trip} />
        </div>
        <Text
          content={status}
          className={Styles[`header--status-${statusStyleName}`]}
          styles={STATUS_STYLE}
        />
      </div>
    </div>
  );
}

export default Header;

import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import axios from "axios";
import qs from "qs";
import {
  CYTRIC_EASY_DENIED_ACCESS_CODE,
  INTERNAL_KEYCLOAK_FLAG,
  KEYCLOAK_QS_URL,
} from "../utils/constants";
import { TelemetryServiceModel } from "./telemetry/TelemetryModel";

export const KEYCLOACK_ERROR_CODE = 401;
export const UNAUTHORIZED_ERROR_CODE = 403;

const APP_KEY = "cytric-easy-teams-app";

export interface ApiDataModel {
  apiKey: string;
  context: microsoftTeams.Context;
  token?: string;
  telemetryService?: TelemetryServiceModel;
  unauthorizedUrl?: string;
  keycloack?: {
    uri: string;
    authUri: string;
    scope: string[];
    isEnabled: boolean;
  };
}

let httpData: ApiDataModel | undefined;

export const setHttpData = (data?: ApiDataModel) => {
  if (data) {
    httpData = data;
  } else {
    httpData = undefined;
  }
};

const getToken = async () => {
  const credential = new TeamsUserCredential();
  const accessToken = await credential.getToken(
    httpData?.keycloack?.scope || ""
  );
  return accessToken?.token;
};

const instance = axios.create({
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false });
  },
});

// request header
instance.interceptors.request.use(
  async (config) => {
    const configuration = config;

    if (httpData) {
      if (
        process.env.REACT_APP_FAKE_CYTRIC_TOKEN &&
        process.env.REACT_APP_FAKE_CYTRIC_TOKEN !== ""
      ) {
        httpData.token = process.env.REACT_APP_FAKE_CYTRIC_TOKEN;
      } else {
        httpData.token = await getToken();
      }
      configuration.headers["Content-Type"] =
        "application/json, application/vnd.amadeus+json";
      configuration.headers["Accept-Language"] = httpData.context?.locale;
      configuration.headers.Authorization = `Bearer ${httpData.token}`;
      configuration.headers["x-api-key"] = httpData.apiKey;
      configuration.headers.app_key = APP_KEY;
      configuration.headers.aid = httpData.context.userObjectId;
      configuration.headers.operation_type = window.location.hash.replace(
        "#/",
        ""
      );
    }

    if (configuration.headers.attempts === undefined) {
      configuration.headers.attempts = "0";
    }

    return configuration;
  },
  (error) => Promise.reject(error)
);

// response parse
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (httpData && httpData.telemetryService) {
      httpData.telemetryService.trackException({
        error,
        severityLevel: SeverityLevel.Error,
      });
    }

    return new Promise<any>((resolve, reject) => {
      if (
        error?.response?.status === KEYCLOACK_ERROR_CODE &&
        error?.config?.headers?.attempts === "0" &&
        httpData?.keycloack?.isEnabled
      ) {
        // Use qs to send the keycloak_uri to avoid firefox issues in private
        const url = `${
          httpData.keycloack.authUri
        }&${KEYCLOAK_QS_URL}=${encodeURIComponent(httpData.keycloack.uri)}`;
        microsoftTeams.authentication.authenticate({
          url,
          width: 650,
          height: 535,
          successCallback: () => {
            // eslint-disable-next-line no-param-reassign
            error.config.headers.attempts = "1";
            instance
              .request(error.config)
              .then((data) => resolve(data))
              .catch((e) => reject(e));
          },
          failureCallback: () => {
            localStorage.removeItem(INTERNAL_KEYCLOAK_FLAG);
            window.location.reload();
            reject(error);
          },
        });
      } else if (
        error.response === undefined &&
        error.message.includes(`${UNAUTHORIZED_ERROR_CODE}`)
      ) {
        window.location.href = `${window.location.href?.substring(
          0,
          window.location.href.indexOf("#/")
        )}#/${httpData?.unauthorizedUrl}`;
        reject(error);
      }
      // ! error.response is undefined on api calls to auth/token (401, 403 errors)
      else if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
        if (
          error?.response?.data?.errors[0]?.code ===
          CYTRIC_EASY_DENIED_ACCESS_CODE
        ) {
          window.location.href = `${window.location.href?.substring(
            0,
            window.location.href.indexOf("#/")
          )}#/${httpData?.unauthorizedUrl}`;
        }
        reject(error);
      } else {
        reject(error);
      }
    });
  }
);

const Http = instance;
export default Http;

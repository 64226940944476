import { Airplane24Filled } from "@fluentui/react-icons";
import {
  Card,
  Checkbox,
  Flex,
  FlexItem,
  Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import ProductAir, {
  FlightSegmentEndpoint,
} from "../../../../common/models/ProductAir";
import DateHelper from "../../../../helpers/DateHelper";
import { isSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSelector";
import { updateSegmentSelected } from "../../../../store/segment-selected/SegmentSelectedSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/StoreHooks";
import "./AirSegmentContent.scss";

interface AirSegmentContentProps {
  airSegment: ProductAir;
  tripId: string;
  segmentId: string;
}

function AirSegmentContent(props: AirSegmentContentProps) {
  const { airSegment, tripId, segmentId } = props;
  const getDateTime = (location: FlightSegmentEndpoint): string => {
    if (location) {
      return location.dateTime ? location.dateTime : "";
    }
    return "";
  };
  const checked: boolean = useAppSelector((state) =>
    isSegmentSelected(state, {
      productType: airSegment.productType,
      id: segmentId,
    })
  );
  const dispatch = useAppDispatch();

  const displayStopsInfo = (currentAirSegment: ProductAir) => {
    if (!currentAirSegment.boundStops || !currentAirSegment.boundStops.length) {
      return "";
    }

    return currentAirSegment.boundStops.reduce(
      (stopsInfo, currentBound) =>
        `${stopsInfo}, ${currentBound.carrierCode} ${currentBound.number}`,
      ""
    );
  };

  const airImg = <Airplane24Filled className="segment-icon" />;

  const checkbox = <Checkbox data-testid="air-checkbox" checked={checked} />;

  const header = (
    <Flex>
      <Text weight="bold">
        {moment.utc(airSegment.departure.dateTime).format("LT")}&nbsp;
      </Text>
      <Text>{airSegment.departure?.iataCode} &rarr;&nbsp;</Text>
      <Text weight="bold">
        {moment.utc(airSegment.arrival.dateTime).format("LT")}&nbsp;
      </Text>
      <Text>{airSegment.arrival?.iataCode}</Text>
    </Flex>
  );

  const content = (
    <Text>{`${airSegment.marketing?.carrier.name} | ${
      airSegment.carrierCode
    }  ${airSegment.number}${displayStopsInfo(airSegment)}`}</Text>
  );

  return (
    <Flex column>
      <Text weight="bold">
        {DateHelper.formatDateTimeToDateRange(
          getDateTime(airSegment.departure),
          getDateTime(airSegment.arrival)
        )}
      </Text>
      <Card
        className="card-segment"
        onClick={() =>
          dispatch(
            updateSegmentSelected({
              tripId,
              segmentId: { productType: airSegment.productType, id: segmentId },
              isSelected: !checked,
            })
          )
        }
      >
        <Card.Header fitted>
          <Flex gap="gap.small" vAlign="center">
            {airImg}
            <Flex column>
              {header}
              {content}
            </Flex>
            <FlexItem push>{checkbox}</FlexItem>
          </Flex>
        </Card.Header>
      </Card>
    </Flex>
  );
}

export default AirSegmentContent;

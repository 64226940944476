import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styles from "./Welcome.module.scss";
import { WELCOME } from "./WelcomeGuiText";

function Welcome() {
  return (
    <Flex hAlign="center" vAlign="center" className={styles.welcome}>
      <Flex column hAlign="center">
        <Image src="hello.png" />
        <Text content={WELCOME.title} className={styles.welcome__title} />
        <Text
          content={WELCOME.subTitle}
          data-testid="notrip"
          className={`${styles.welcome__subtitle}`}
        />
      </Flex>
    </Flex>
  );
}

export default Welcome;

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  ITraceTelemetry,
} from "@microsoft/applicationinsights-web";
import guid from "../Utilities";
import { TelemetryServiceModel } from "./TelemetryModel";

let reactPlugin: ReactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | null = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (instrumentationKey: string, browserHistory: any) => {
    if (!browserHistory) {
      throw new Error("BrowserHistory not provided!");
    }
    if (!instrumentationKey) {
      throw new Error("Instrumentation key not provided!");
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: 0,
        enableCorsCorrelation: true,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        enableRequestHeaderTracking: true,
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });

    appInsights.loadAppInsights();
  };

  return { reactPlugin, appInsights, initialize };
};

const getCustomProperties = (
  customProperties: ICustomProperties | undefined,
  requestId: string
) => {
  let myCustomProperties: ICustomProperties = {};
  if (customProperties) {
    myCustomProperties = customProperties;
  }
  if (!customProperties?.operationType) {
    const operationType = window.location.hash.replace("#/", "");
    myCustomProperties = {
      ...myCustomProperties,
      operation_type: operationType,
    };
  }
  if (!customProperties?.requestId) {
    myCustomProperties = { ...myCustomProperties, "request-id": requestId };
  }
  if (!customProperties?.appKey) {
    const appKey = "cytric-easy-teams-app";
    myCustomProperties = { ...myCustomProperties, app_key: appKey };
  }
  return myCustomProperties;
};

export const appInsightService = createTelemetryService();
export const getAppInsights = () => appInsights;
export const setAppInsights = (insights: ApplicationInsights) => {
  appInsights = insights;
};

export const telemetryService: TelemetryServiceModel = {
  setUserId: (userId: string | undefined) => {
    if (appInsights && userId) {
      appInsights.setAuthenticatedUserContext(userId, undefined, true);
    }
  },

  trackException: (
    exception: IExceptionTelemetry,
    customProperties?: ICustomProperties
  ) => {
    const requestId = guid();
    if (appInsights) {
      const myCustomProperties = getCustomProperties(
        customProperties,
        requestId
      );

      appInsights.trackException(exception, myCustomProperties);
    }
    return requestId;
  },

  trackTrace: (
    trace: ITraceTelemetry,
    customProperties?: ICustomProperties
  ) => {
    const requestId = guid();
    if (appInsights) {
      const myCustomProperties = getCustomProperties(
        customProperties,
        requestId
      );

      appInsights.trackTrace(trace, myCustomProperties);
    }
    return requestId;
  },

  trackEvent: (
    event: IEventTelemetry,
    customProperties?: ICustomProperties
  ) => {
    const requestId = guid();
    if (appInsights) {
      const myCustomProperties = getCustomProperties(
        customProperties,
        requestId
      );
      appInsights.trackEvent(event, myCustomProperties);
    }
    return requestId;
  },

  trackMetric: (
    metric: IMetricTelemetry,
    customProperties?: ICustomProperties
  ) => {
    const requestId = guid();
    if (appInsights) {
      const myCustomProperties = getCustomProperties(
        customProperties,
        requestId
      );
      appInsights.trackMetric(metric, myCustomProperties);
    }
    return requestId;
  },
};

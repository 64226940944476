/* eslint-disable react-hooks/exhaustive-deps */
import { Header, Loader, Segment } from "@fluentui/react-northstar";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TODO_ROUTE_ITEM } from "../../../utils/constants";
import DialogPopup from "../duplicates/pop-up/dialog-popup/DialogPopup";
import { ErrorAccessInterface } from "../ExpenseDashboardInterfaces";
import { getDashboardInfo } from "../ExpenseDashboardRequests";
import TodoContent from "./todo-content/TodoContent";
import "./Todo.scss";
import { ItemInterface } from "./TodoInterfaces";

interface TodoPropsInterface {
  setMergeType: React.Dispatch<React.SetStateAction<string | null>>;
  setAccessError: React.Dispatch<
    React.SetStateAction<ErrorAccessInterface | null>
  >;
}

interface CountersInterface {
  toSubmit: number;
  duplicated: number;
  notAssigned: number;
  isActivatedForDuplicates: boolean;
}

function Todo({ setMergeType, setAccessError }: TodoPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.todo",
  });
  const pairsFromStorage = localStorage.getItem("duplicatePairs");

  const [countOfTodos, setCountOfTodos] = useState<number>(0);
  const [didMergeAction, setDidMergeAction] = useState<boolean>(false);

  const [counters, setCounters] = React.useState<CountersInterface>({
    toSubmit: -1,
    duplicated: -1,
    notAssigned: -1,
    isActivatedForDuplicates: false,
  });

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const items: ItemInterface[] = [
    {
      keyId: 1,
      header: t("expensesToSubmit"),
      count: counters.toSubmit,
    },
    // {
    //   keyId: 2,
    //   header: t("duplicatesFound"),
    //   count: counters.duplicated,
    // },
    {
      keyId: 3,
      header: t("unassignedExpenses"),
      count: counters.notAssigned,
    },
  ];

  const isMounted = useRef(false);

  const getCounters = async () => {
    setIsLoading(true);
    const allInfo = await getDashboardInfo(TODO_ROUTE_ITEM);

    if (allInfo.isError) {
      setAccessError(allInfo);
    } else {
      const receivedCounters = allInfo.toDoCounter;
      setCounters(receivedCounters);

      if (pairsFromStorage) {
        setCounters({
          ...receivedCounters,
          duplicated: JSON.parse(pairsFromStorage).length,
        });
      }
    }
    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    getCounters();
    return () => {
      isMounted.current = false;
    };
  }, [didMergeAction]);

  useEffect(() => {
    if (items) {
      let totalTodos: number = 0;
      items.forEach((item) => {
        totalTodos += item.count;
      });
      setCountOfTodos(totalTodos);
    }
  }, [counters]);

  return (
    <Segment className="todo-list-container">
      <Header
        as="h4"
        content={
          countOfTodos && !isLoading ? `To do's (${countOfTodos})` : "To do's"
        }
        className="todo-list-title"
        data-testid="header"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <TodoContent
          items={items}
          counts={countOfTodos}
          openDialog={setIsDialogOpen}
          didMergeAction={setDidMergeAction}
        />
      )}
      {isDialogOpen && (
        <DialogPopup
          openDialog={isDialogOpen}
          setOpenDialog={setIsDialogOpen}
          setMergeType={setMergeType}
          setDidMergeAction={setDidMergeAction}
        />
      )}
    </Segment>
  );
}

export default Todo;

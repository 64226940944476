/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  CheckboxProps,
  Divider,
  Flex,
  List,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  DuplicateExpensesInterface,
  DuplicatePairsInterface,
} from "../DuplicatesInterfaces";

interface DialogContentPropsInterface {
  duplicatePairs: DuplicatePairsInterface[];
  manageCheckboxes: (
    e: React.FormEvent<HTMLElement>,
    checked: CheckboxProps | undefined,
    pairId: number
  ) => void;
}

export function ListItemHeader(props: {
  expense: DuplicateExpensesInterface;
}): JSX.Element {
  const { expense } = props;
  return (
    <span
      id="popup__dialog-content__item-title"
      data-testid="popup__dialog-content__item-title"
      style={{ fontWeight: "bold" }}
    >
      {expense.sourceType}
    </span>
  );
}

export function ListItemContent(props: {
  expense: DuplicateExpensesInterface;
}): JSX.Element {
  const { expense } = props;
  return (
    <Box
      id="popup__dialog-content__item-content"
      data-testid="popup__dialog-content__item-content"
      styles={{ fontSize: "0.8rem" }}
    >
      <Box
        id="popup__dialog-content__item-details"
        data-testid="popup__dialog-content__item-details"
        styles={{ margin: "0.3rem 0" }}
      >
        {expense.description} / Atts: {expense.attachmentCount} / Date:{" "}
        {expense.period} / Location: {expense.location}
      </Box>
      <Box
        id="popup__dialog-content__item-amount"
        data-testid="popup__dialog-content__item-amount"
      >
        <b>{expense.amount}</b>
      </Box>
    </Box>
  );
}

function DialogContent({
  duplicatePairs,
  manageCheckboxes,
}: DialogContentPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.popup",
  });
  return (
    <Box id="popup__dialog-content" data-testid="popup__dialog-content">
      <Box
        id="popup__dialog-content__merge-question"
        data-testid="popup__dialog-content__merge-question"
        styles={{ margin: "0.5rem 0 0.3rem" }}
      >
        <b>{t("mergeQuestion")}</b>
        <Box data-testid="popup__dialog-content__merge-info-title">
          {t("mergeInfo")}
        </Box>
        <Divider styles={{ margin: "0.5rem 0 0.3rem" }} />
      </Box>

      {duplicatePairs?.map((pair) => (
        <Box
          key={pair.id}
          id={`popup__dialog-content__item-container-${pair.id}`}
          data-testid={`popup__dialog-content__item-container-${pair.id}`}
        >
          <Flex
            id="popup__dialog-content__list-container"
            data-testid="popup__dialog-content__list-container"
            hAlign="center"
            vAlign="start"
          >
            {duplicatePairs?.length > 1 && (
              <Checkbox
                id="popup__dialog-content__item-checkbox"
                data-testid="popup__dialog-content__item-checkbox"
                onChange={(e, checked) => manageCheckboxes(e, checked, pair.id)}
              />
            )}

            <List
              id="popup__dialog-content__list"
              data-testid="popup__dialog-content__list"
              styles={{ width: "100%" }}
            >
              {pair.duplicateExpenses.map((expense, index) => (
                <div
                  key={expense.id}
                  data-testid={`popup__dialog-content__list-item-container-${expense.id}`}
                >
                  <List.Item
                    id={`popup__dialog-content__list-item-${expense.id}`}
                    styles={{ paddingLeft: "0" }}
                    header={<ListItemHeader expense={expense} />}
                    content={<ListItemContent expense={expense} />}
                    index={0}
                  />
                  {index !== pair.duplicateExpenses.length - 1 && (
                    <Divider styles={{ margin: "0.3rem 0" }} />
                  )}
                </div>
              ))}
            </List>
          </Flex>
          <Divider styles={{ margin: "0.3rem 0" }} />
        </Box>
      ))}
    </Box>
  );
}

export default DialogContent;

/* eslint-disable import/no-named-as-default */
/* eslint-disable react/react-in-jsx-scope */

import {
  Alert,
  ExclamationTriangleIcon,
  Provider,
} from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTeams } from "msteams-react-base-component";
import React, { useEffect, useState } from "react";
import { TRIP_TYPE, TRIP_TYPE_TIME } from "../../utils/constants";
import SearchComponent from "./components/search/search.component";
// Styles
import "./SearchFlightsTab.scss";

const INITIAL_STATE = {
  component: {
    loading: false,
    showError: false,
  },
  flightsData: null,
  searchCriteria: {
    tripType: TRIP_TYPE.roundTrip.code,
    from: "",
    to: "",
    departureDate: "",
    returnDate: "",
    departureTime: { value: "06", type: "" },
    returnTime: { value: "06", type: "" },
    purpose: "",
    tripTypeTimeInbound: TRIP_TYPE_TIME.departure.code,
    tripTypeTimeOutbound: TRIP_TYPE_TIME.departure.code,
  },
};

/**
 * Implementation of the SearchFlights Message Extension Task Module page
 */
function SearchFlightsTab() {
  const [{ inTeams, theme }] = useTeams();
  const [state, setState] = useState(INITIAL_STATE.component);

  useEffect(() => {
    if (inTeams === true) {
      microsoftTeams.appInitialization.notifySuccess();
      // microsoftTeams.getContext((ctx) => {console.log('ctx :>> ', ctx);});
    }
  }, [inTeams]);

  return (
    <Provider
      theme={theme}
      className="provider"
      data-testid="search-flight-container"
    >
      <SearchComponent />

      <Alert
        warning
        icon={<ExclamationTriangleIcon />}
        header="There was an error during the process."
        content="Please try again later"
        dismissible
        dismissAction={{
          "aria-label": "close",
        }}
        onVisibleChange={() => setState({ ...state, showError: false })}
        visible={state.showError}
      />
    </Provider>
  );
}

export default SearchFlightsTab;

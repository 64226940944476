// Dependencies
// Hooks
import { useDispatch, useSelector } from "react-redux";
// Actions
import { fillingFields } from "./slice";

// Types

const useSearchFlightForm = () => {
  // Hooks
  const data = useSelector((state: any) => state.searchflight);
  const dispatch = useDispatch();

  const dispatchFillingFields = (fields: any) => {
    dispatch(fillingFields(fields));
  };

  return [data, { dispatchFillingFields }];
};

export default useSearchFlightForm;

import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";

export default async function getImages(client: any, usersList: any) {
  const batchRequestContent = new MicrosoftGraph.BatchRequestContent(
    usersList.map((item: any) => ({
      id: item,
      request: new Request(`/users/${item}/photo/$value`, {
        method: "GET",
      }),
    }))
  );

  const content = await batchRequestContent.getContent();
  const images = await client.api("/$batch").post(content);

  return images;
}

import AirRedirectionInput from "../common/models/redirection/AirRedirectionInput";
import AirRedirectionParam from "../common/models/redirection/AirRedirectionParam";
import CarRedirectionInput from "../common/models/redirection/CarRedirectionInput";
import CarRedirectionParam from "../common/models/redirection/CarRedirectionParam";
import ExpenseRedirectionInput from "../common/models/redirection/ExpenseRedirectionInput";
import ExpenseRedirectionParam from "../common/models/redirection/ExpenseRedirectionParam";
import HotelRedirectionInput from "../common/models/redirection/HotelRedirectionInput";
import HotelRedirectionParam from "../common/models/redirection/HotelRedirectionParam";
import RailRedirectionInput from "../common/models/redirection/RailRedirectionInput";
import RailRedirectionParam from "../common/models/redirection/RailRedirectionParam";
import DateHelper from "./DateHelper";

export default class RedirectionParamaterHelper {
  public static transformAirRedirectionInput(
    airRedirectionInput: AirRedirectionInput
  ): AirRedirectionParam {
    const airParam: AirRedirectionParam = {};

    if (airRedirectionInput.numberOfPassengers) {
      airParam.numberOfPassengers = airRedirectionInput.numberOfPassengers;
    }
    if (
      airRedirectionInput.directFlights !== undefined &&
      airRedirectionInput.directFlights !== null
    ) {
      airParam.directFlights = airRedirectionInput.directFlights;
    }
    if (
      airRedirectionInput.stopOnStartPage !== undefined &&
      airRedirectionInput.stopOnStartPage !== null
    ) {
      airParam.stopOnStartPage = airRedirectionInput.stopOnStartPage;
    }
    if (airRedirectionInput.departure) {
      airParam.departureLocationCode = airRedirectionInput.departure;
    }
    if (airRedirectionInput.arrival) {
      airParam.arrivalLocationCode = airRedirectionInput.arrival;
    }
    if (airRedirectionInput.dateTime) {
      airParam.dateTime = airRedirectionInput.dateTime
        .filter((val) => val)
        .map((datetime) =>
          DateHelper.formatDateTimeToRedirectionDateTime(datetime)
        );
    }
    if (airRedirectionInput.purpose) {
      airParam.tripPurpose = airRedirectionInput.purpose;
    }
    if (airRedirectionInput.airlineCode) {
      airParam.airlineCode = airRedirectionInput.airlineCode;
    }
    if (airRedirectionInput.airlineNumber) {
      airParam.airlineNumber = airRedirectionInput.airlineNumber;
    }
    if (airRedirectionInput.numberOfFlights) {
      airParam.numberOfFlights = airRedirectionInput.numberOfFlights;
    }

    return airParam;
  }

  public static transformCarRedirectionInput(
    carRedirectionInput: CarRedirectionInput
  ): CarRedirectionParam {
    const carParam: CarRedirectionParam = {};

    if (
      carRedirectionInput.carDelivery !== undefined &&
      carRedirectionInput.carDelivery !== null
    ) {
      carParam.carDelivery = carRedirectionInput.carDelivery;
    }
    if (
      carRedirectionInput.carCollection !== undefined &&
      carRedirectionInput.carCollection !== null
    ) {
      carParam.carCollection = carRedirectionInput.carCollection;
    }
    if (carRedirectionInput.name) {
      carParam.locationName = carRedirectionInput.name;
    }
    if (carRedirectionInput.latitude) {
      carParam.latitude = carRedirectionInput.latitude;
    }
    if (carRedirectionInput.longitude) {
      carParam.longitude = carRedirectionInput.longitude;
    }
    if (carRedirectionInput.code) {
      carParam.locationCode = carRedirectionInput.code;
    }
    if (carRedirectionInput.country) {
      carParam.countryCode = carRedirectionInput.country;
    }
    if (carRedirectionInput.purpose) {
      carParam.tripPurpose = carRedirectionInput.purpose;
    }
    if (
      carRedirectionInput.stopOnStartPage !== undefined &&
      carRedirectionInput.stopOnStartPage !== null
    ) {
      carParam.stopOnStartPage = carRedirectionInput.stopOnStartPage;
    }
    if (carRedirectionInput.dateTime) {
      carParam.dateTime = carRedirectionInput.dateTime
        .filter((val) => val)
        .map((datetime) =>
          DateHelper.formatDateTimeToRedirectionDateTime(datetime)
        );
    }

    return carParam;
  }

  public static transformHotelRedirectionInput(
    hotelRedirectionInput: HotelRedirectionInput
  ): HotelRedirectionParam {
    const hotelParam: HotelRedirectionParam = {};

    if (hotelRedirectionInput.code) {
      hotelParam.locationCode = hotelRedirectionInput.code;
    }
    if (hotelRedirectionInput.departure) {
      hotelParam.checkOutDate = hotelRedirectionInput.departure;
    }
    if (hotelRedirectionInput.arrival) {
      hotelParam.checkInDate = hotelRedirectionInput.arrival;
    }
    if (hotelRedirectionInput.name) {
      hotelParam.locationName = hotelRedirectionInput.name;
    }
    if (hotelRedirectionInput.latitude) {
      hotelParam.latitude = hotelRedirectionInput.latitude;
    }
    if (hotelRedirectionInput.longitude) {
      hotelParam.longitude = hotelRedirectionInput.longitude;
    }
    if (hotelRedirectionInput.purpose) {
      hotelParam.tripPurpose = hotelRedirectionInput.purpose;
    }
    if (
      hotelRedirectionInput.stopOnStartPage !== undefined &&
      hotelRedirectionInput.stopOnStartPage !== null
    ) {
      hotelParam.stopOnStartPage = hotelRedirectionInput.stopOnStartPage;
    }

    return hotelParam;
  }

  public static transformRailRedirectionInput(
    railRedirectionInput: RailRedirectionInput
  ): RailRedirectionParam {
    const railParam: RailRedirectionParam = {};

    if (
      railRedirectionInput.directTrains !== undefined &&
      railRedirectionInput.directTrains !== null
    ) {
      railParam.directTrains = railRedirectionInput.directTrains;
    }
    if (railRedirectionInput.dateTime) {
      railParam.dateTime = railRedirectionInput.dateTime
        .filter((val) => val)
        .map((datetime) =>
          DateHelper.formatDateTimeToRedirectionDateTime(datetime)
        );
    }
    if (railRedirectionInput.directTrains) {
      railParam.directTrains = railRedirectionInput.directTrains;
    }
    if (railRedirectionInput.purpose) {
      railParam.tripPurpose = railRedirectionInput.purpose;
    }
    if (
      railRedirectionInput.stopOnStartPage !== undefined &&
      railRedirectionInput.stopOnStartPage !== null
    ) {
      railParam.stopOnStartPage = railRedirectionInput.stopOnStartPage;
    }
    if (railRedirectionInput.departure) {
      railParam.departureLocationCode = railRedirectionInput.departure;
    }
    if (railRedirectionInput.arrival) {
      railParam.arrivalLocationCode = railRedirectionInput.arrival;
    }

    return railParam;
  }

  public static transformExpenseRedirectionInput(
    expenseRedirectionInput: ExpenseRedirectionInput
  ): ExpenseRedirectionParam {
    const expenseParam: ExpenseRedirectionParam = {};

    if (expenseRedirectionInput.expenseId) {
      expenseParam.expenseStatementId = expenseRedirectionInput.expenseId;
    }

    return expenseParam;
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FLAG_LOGIN,
  INTERNAL_KEYCLOAK_FLAG,
  USER_OBJECT_ID,
} from "../../utils/constants";
import { login, logout } from "./AuthenticationAction";

interface AuthenticationModel {
  isLoggedIn: boolean;
  isPending: boolean;
  isError: boolean;
  errorMessage: string;
  userObjectId: string;
}

const INITIAL_STATE: AuthenticationModel = {
  isLoggedIn: localStorage.getItem(INTERNAL_KEYCLOAK_FLAG) === FLAG_LOGIN,
  isPending: false,
  isError: false,
  errorMessage: "",
  userObjectId: localStorage.getItem(USER_OBJECT_ID) || "",
};

const pendingStateReducer = (state: any) => ({
  ...state,
  isPending: true,
  isError: false,
  errorMessage: "",
});

export const authenticationSlice = createSlice({
  name: "segmentSelected",
  initialState: INITIAL_STATE,
  reducers: {
    updateUserObjectId: (
      state,
      action: PayloadAction<{ userObjectId: string }>
    ) => {
      localStorage.setItem(USER_OBJECT_ID, action.payload.userObjectId);
      return {
        ...state,
        userObjectId: action.payload.userObjectId,
      };
    },

    resetAuthentication: (state) => {
      localStorage.removeItem(INTERNAL_KEYCLOAK_FLAG);
      return {
        ...state,
        isLoggedIn: false,
        isPending: false,
        isError: false,
        errorMessage: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, pendingStateReducer);

    builder.addCase(login.fulfilled, (state) => ({
      ...state,
      isLoggedIn: true,
      isPending: false,
      isError: false,
      errorMessage: "",
    }));

    builder.addCase(login.rejected, (state, action) => ({
      ...state,
      isLoggedIn: false,
      isPending: false,
      isError: true,
      errorMessage: (action.payload as string) || "",
    }));

    builder.addCase(logout.pending, pendingStateReducer);

    builder.addCase(logout.fulfilled, (state) => ({
      ...state,
      isLoggedIn: false,
      isPending: false,
      isError: false,
      errorMessage: "",
    }));

    builder.addCase(logout.rejected, (state) => ({
      ...state,
      isPending: false,
      isError: true,
      errorMessage: "",
    }));
  },
});

// Action creators are generated for each case reducer function
export const { resetAuthentication, updateUserObjectId } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;

/* eslint-disable react/react-in-jsx-scope */
import { ComponentStyleFunctionParam, Text } from "@fluentui/react-northstar";
import Responsive from "../../../../common/components/responsive/Responsive";
import Icon from "../../../../common/icon";
import { UPCOMING_TRIPS } from "../../constants";
import { ISegmentIconsProps } from "./SegmentIcons.model";
import Styles from "./SegmentIcons.module.scss";

function SegmentIcons({ trip }: ISegmentIconsProps) {
  const ICON_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.segmentIcons?.icons
      : "",
  });

  const iconsForMobile = (
    <Responsive forMaxSize="tablet">
      {trip?.products[0]?.hasAir && (
        <Icon name="Air" styles={ICON_STYLE} size="large" />
      )}
      {trip?.products[0]?.hasRail && (
        <Icon name="Train" styles={ICON_STYLE} size="large" />
      )}
      {trip?.products[0]?.hasHotel && (
        <Icon name="Hotel" styles={ICON_STYLE} size="large" />
      )}
      {trip?.products[0]?.hasCar && (
        <Icon name="Car" styles={ICON_STYLE} size="large" />
      )}
    </Responsive>
  );
  const iconsForDesktop = (
    <Responsive forMinSize="desktopSmall">
      {trip?.products[0]?.hasAir && (
        <Icon name="Air" styles={ICON_STYLE} size="medium" />
      )}
      {trip?.products[0]?.hasRail && (
        <Icon name="Train" styles={ICON_STYLE} size="medium" />
      )}
      {trip?.products[0]?.hasHotel && (
        <Icon name="Hotel" styles={ICON_STYLE} size="medium" />
      )}
      {trip?.products[0]?.hasCar && (
        <Icon name="Car" styles={ICON_STYLE} size="medium" />
      )}
    </Responsive>
  );
  return (
    <div className={Styles.segments}>
      <Text
        content={UPCOMING_TRIPS.segmentsText}
        styles={(theme) => ({
          color: theme.theme.siteVariables?.segmentIcons?.text,
        })}
      />
      <div className={Styles.segments__icons}>
        {iconsForMobile}
        {iconsForDesktop}
      </div>
    </div>
  );
}
export default SegmentIcons;

import * as microsoftTeams from "@microsoft/teams-js";
import ExpenseRedirectionInput from "../../../common/models/redirection/ExpenseRedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import HostSettings from "../../../utils/host.settings";

export function redirectToExpenseDashboard() {
  const deeplinkData: ExpenseRedirectionInput = {
    segmentType: RedirectionTypeEnum.EXPENSE,
  };

  const urlEncodedDeepLinkData: any = {
    subEntityId: JSON.stringify(deeplinkData),
  };
  const deepLink = `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/cytricweb?context=${encodeURI(JSON.stringify(urlEncodedDeepLinkData))}`;
  microsoftTeams.executeDeepLink(deepLink);
}

export function redirectToExpenseStatement(redirectionExpenseId: string) {
  const deeplinkData: ExpenseRedirectionInput = {
    segmentType: RedirectionTypeEnum.EXPENSE,
    expenseId: redirectionExpenseId,
  };

  const urlEncodedDeepLinkData: any = {
    subEntityId: JSON.stringify(deeplinkData),
  };
  const deepLink = `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/cytricweb?context=${encodeURI(JSON.stringify(urlEncodedDeepLinkData))}`;
  microsoftTeams.executeDeepLink(deepLink);
}

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable object-shorthand */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */

// Dependencies
import { Flex, Popup, Provider, Text } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import getSymbolFromCurrency from "currency-symbol-map";
import { useState } from "react";
import Icon from "../../../../../common/icon";
import useSearchFlightForm from "../../../../../hooks/useSearchFlightForm/useHook";
// Styles
import "./flight-footer.scss";

function FlightFooter(props: any): JSX.Element {
  const { price, travelClass, currency, noPrice, flightData } = props;

  const [teamsLocale, setTeamsLocale] = useState("");
  const [infoWithoutPrice, setInfoWithoutPrice] = useState(false);

  const [{ tripType }] = useSearchFlightForm();

  const getPriceText = () => {
    const priceToShow = `${
      Math.round(price.total * 100) / 100
    } ${getSymbolFromCurrency(currency)}`;
    return <Text className="price">{priceToShow}</Text>;
  };

  const getCabinTravelClass = (tClass: string) => {
    const finalTravelClass = `${tClass[0]}${tClass
      .toLowerCase()
      .substring(1, tClass.length)}`;
    return finalTravelClass.replace(/_/g, " ");
  };

  const WhyNoPriceContent = () => (
    <div className="noPricePopUP">
      <div>Prices are not displayed due to system setup</div>
    </div>
  );

  const overrides = {
    componentStyles: {
      PopupContent: {
        content: {
          backgroundColor: "#424242",
          padding: 5,
        },
        pointer: {
          "&::before": {
            borderRightColor: "#424242",
          },
        },
      },
    },
  };

  return noPrice ? (
    <Flex className="borderMarked" hAlign="end">
      <Text className="noPriceText">Continue for fares and prices</Text>
      <Flex
        className="borderContainer"
        onClick={(e: any) => {
          e.stopPropagation();
          setInfoWithoutPrice(!infoWithoutPrice);
        }}
      >
        <Provider theme={overrides}>
          <Popup
            content={{
              content: <WhyNoPriceContent />,
            }}
            open={infoWithoutPrice}
            position="after"
            align="center"
            pointing
            trigger={
              <Icon
                name="Ranked"
                styles={(theme) => ({
                  marginLeft: 12,
                  marginRight: 20,
                  marginTop: 13,
                })}
              />
            }
          />
        </Provider>
      </Flex>
    </Flex>
  ) : (
    <Flex className="borderMarked" space="between">
      <Flex>
        <Text className="cabinText">{`${getCabinTravelClass(
          travelClass[0]?.cabin.travelClass
        )}`}</Text>
        {tripType === "roundtrip" &&
          (travelClass[1] ? (
            <Text className="cabinSecondaryText">
              {` · ${getCabinTravelClass(travelClass[1]?.cabin.travelClass)}`}
            </Text>
          ) : (
            price.pricingGroupIds.length > 1 &&
            flightData?.pricingOptions.map((tp: any) =>
              price.pricingGroupIds[1] === tp.id ? (
                <Text className="cabinSecondaryText">{` · ${getCabinTravelClass(
                  tp.fareDetails[0].cabin.travelClass
                )}`}</Text>
              ) : null
            )
          ))}
      </Flex>
      <Flex>
        <Text className="totalText">{`Total price `}</Text>
        {price.total && getPriceText()}
      </Flex>
    </Flex>
  );
}

export default FlightFooter;

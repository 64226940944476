/* eslint-disable import/prefer-default-export */

import Http from "../../../../common/Http";
import HostSettings from "../../../../utils/host.settings";

export const getDuplicatesExpenses = async () => {
  try {
    const response = await Http.get(
      `${HostSettings.getBaseAPI}${HostSettings.getDuplicateExpensesList}`
    );
    if (response.status === 200) {
      const duplicatesList = await response.data;
      return duplicatesList.duplicatePairs;
    }
  } catch (error) {
    return null;
  }
  return null;
};

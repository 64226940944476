const DarkError = () =>
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYwIiBoZWlnaHQ9IjE2MC" +
  "Igdmlld0JveD0iMCAwIDE2MCAxNjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8" +
  "vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBf" +
  "MTc2Xzg4MDIpIj4NCjxwYXRoIGQ9Ik0xMzkuNSA1OS4xTDEwNS4yIDkzLjdDMTAzL" +
  "jIgOTUuNyAxMDQuMSA5OS4yIDEwNi45IDk5LjlMMTU0IDExMi40QzE1Ni44IDExMy" +
  "4xIDE1OS4zIDExMC42IDE1OC42IDEwNy44TDE0NS44IDYwLjhDMTQ0LjkgNTggMTQ" +
  "xLjUgNTcgMTM5LjUgNTkuMVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8xNzZf" +
  "ODgwMikiLz4NCjxwYXRoIGQ9Ik0zOS42OTk5IDE5LjlMMTkuMjk5OSA2NC4yQzE4L" +
  "jA5OTkgNjYuOCAyMC4xOTk5IDY5LjcgMjIuOTk5OSA2OS41TDcxLjQ5OTkgNjVDNz" +
  "QuMzk5OSA2NC43IDc1Ljg5OTkgNjEuNSA3NC4xOTk5IDU5LjFMNDYuMDk5OSAxOS4" +
  "zQzQ0LjM5OTkgMTYuOSA0MC45IDE3LjMgMzkuNjk5OSAxOS45WiIgZmlsbD0idXJs" +
  "KCNwYWludDFfbGluZWFyXzE3Nl84ODAyKSIvPg0KPHBhdGggZD0iTTc4LjUgMjYuM" +
  "UwxOS42IDExMy40QzE2LjEgMTE4LjUgMTkuNSAxMjUuNSAyNS43IDEyNkwxMzAuOC" +
  "AxMzMuNEMxMzcgMTMzLjggMTQxLjMgMTI3LjQgMTM4LjYgMTIxLjhMOTIuNCAyNy4" +
  "xQzg5LjcgMjEuNSA4MiAyMSA3OC41IDI2LjFaIiBmaWxsPSIjMDA4NkYwIi8+DQo8" +
  "cGF0aCBkPSJNODAuNDk5OSA5Ny4xQzc4LjI5OTkgOTYuOSA3Ni41OTk5IDk1IDc2L" +
  "jc5OTkgOTIuOEw3OS42OTk5IDUxLjlDNzkuODk5OSA0OS43IDgxLjc5OTkgNDggOD" +
  "MuOTk5OSA0OC4yQzg2LjE5OTkgNDguNCA4Ny44OTk5IDUwLjMgODcuNjk5OSA1Mi4" +
  "1TDg0Ljc5OTkgOTMuNEM4NC41OTk5IDk1LjYgODIuNjk5OSA5Ny4yIDgwLjQ5OTkg" +
  "OTcuMVoiIGZpbGw9ImJsYWNrIi8+DQo8cGF0aCBkPSJNNzkuOSAxMTEuMUM4Mi4zO" +
  "DUzIDExMS4xIDg0LjQgMTA5LjA4NSA4NC40IDEwNi42Qzg0LjQgMTA0LjExNSA4Mi" +
  "4zODUzIDEwMi4xIDc5LjkgMTAyLjFDNzcuNDE0NyAxMDIuMSA3NS40IDEwNC4xMTU" +
  "gNzUuNCAxMDYuNkM3NS40IDEwOS4wODUgNzcuNDE0NyAxMTEuMSA3OS45IDExMS4x" +
  "WiIgZmlsbD0iYmxhY2siLz4NCjxwYXRoIGQ9Ik0zNC42IDQ3LjdDMzIuNSA0OC4zI" +
  "DMwLjMgNDcuMSAyOS43IDQ0LjlMMjEuNSAxNkMyMC45IDEzLjkgMjIuMSAxMS43ID" +
  "I0LjMgMTEuMUMyNi40IDEwLjUgMjguNiAxMS43IDI5LjIgMTMuOUwzNy40IDQyLjh" +
  "DMzcuOSA0NC45IDM2LjcgNDcuMSAzNC42IDQ3LjdaIiBmaWxsPSIjMjhDMkQxIi8+" +
  "DQo8cGF0aCBkPSJNMzcuMiA2MS40QzM5LjY4NTIgNjEuNCA0MS43IDU5LjM4NTMgN" +
  "DEuNyA1Ni45QzQxLjcgNTQuNDE0NyAzOS42ODUyIDUyLjQgMzcuMiA1Mi40QzM0Lj" +
  "cxNDcgNTIuNCAzMi43IDU0LjQxNDcgMzIuNyA1Ni45QzMyLjcgNTkuMzg1MyAzNC4" +
  "3MTQ3IDYxLjQgMzcuMiA2MS40WiIgZmlsbD0iIzI4QzJEMSIvPg0KPHBhdGggZD0i" +
  "TTE0NC4xIDgyLjZDMTQyIDgyIDE0MC44IDc5LjcgMTQxLjQgNzcuNkwxNTAgNDguO" +
  "UMxNTAuNiA0Ni44IDE1Mi45IDQ1LjYgMTU1IDQ2LjJDMTU3LjEgNDYuOCAxNTguMy" +
  "A0OS4xIDE1Ny43IDUxLjJMMTQ5IDgwQzE0OC40IDgyLjEgMTQ2LjIgODMuMyAxNDQ" +
  "uMSA4Mi42WiIgZmlsbD0iI0IzQUZBQiIvPg0KPHBhdGggZD0iTTE0MS4zIDk2LjND" +
  "MTQzLjc4NSA5Ni4zIDE0NS44IDk0LjI4NTMgMTQ1LjggOTEuOEMxNDUuOCA4OS4zM" +
  "TQ3IDE0My43ODUgODcuMyAxNDEuMyA4Ny4zQzEzOC44MTUgODcuMyAxMzYuOCA4OS" +
  "4zMTQ3IDEzNi44IDkxLjhDMTM2LjggOTQuMjg1MyAxMzguODE1IDk2LjMgMTQxLjM" +
  "gOTYuM1oiIGZpbGw9IiNCM0FGQUIiLz4NCjxwYXRoIGQ9Ik0zNC42IDQ3LjdDMzIu" +
  "NSA0OC4zIDMwLjMgNDcuMSAyOS43IDQ0LjlMMjEuNSAxNkMyMC45IDEzLjkgMjIuM" +
  "SAxMS43IDI0LjMgMTEuMUMyNi40IDEwLjUgMjguNiAxMS43IDI5LjIgMTMuOUwzNy" +
  "40IDQyLjhDMzcuOSA0NC45IDM2LjcgNDcuMSAzNC42IDQ3LjdaIiBmaWxsPSIjQjN" +
  "BRkFCIi8+DQo8cGF0aCBkPSJNMzcuMiA2MS40QzM5LjY4NTIgNjEuNCA0MS43IDU5" +
  "LjM4NTMgNDEuNyA1Ni45QzQxLjcgNTQuNDE0NyAzOS42ODUyIDUyLjQgMzcuMiA1M" +
  "i40QzM0LjcxNDcgNTIuNCAzMi43IDU0LjQxNDcgMzIuNyA1Ni45QzMyLjcgNTkuMz" +
  "g1MyAzNC43MTQ3IDYxLjQgMzcuMiA2MS40WiIgZmlsbD0iI0IzQUZBQiIvPg0KPHB" +
  "hdGggZD0iTTk1LjQgMTUuNEM5NC44IDE2LjQgOTUgMTcuNiA5NS44IDE4LjRDOTUu" +
  "OSAxOC41IDk2LjEgMTguNyA5Ni4zIDE4LjdDOTcuNSAxOS40IDk5IDE5IDk5LjYgM" +
  "TcuOUwxMDIuNyAxMi43QzEwMyAxMi4xIDEwMy4xIDExLjUgMTAzIDEwLjhDMTAyLj" +
  "kgMTAuMyAxMDIuNiA5LjggMTAyLjIgOS41QzEwMi4xIDkuNCAxMDIgOS40IDEwMS4" +
  "5IDkuM0MxMDAuNyA4LjYgOTkuMiA5IDk4LjYgMTAuMUw5NS40IDE1LjRaIiBmaWxs" +
  "PSIjMzMzMTJEIi8+DQo8cGF0aCBkPSJNMTA0LjkgMjYuNUMxMDUgMjYuNyAxMDUuM" +
  "iAyNyAxMDUuNCAyNy4yQzEwNi4yIDI3LjkgMTA3LjMgMjguMSAxMDguMiAyNy42TD" +
  "ExMy40IDI1QzExNCAyNC43IDExNC40IDI0LjIgMTE0LjYgMjMuNkMxMTQuOCAyMyA" +
  "xMTQuOCAyMi4zIDExNC41IDIxLjdDMTE0LjMgMjEuNCAxMTQuMSAyMS4xIDExMy44" +
  "IDIwLjlDMTEzLjYgMjAuNyAxMTMuMyAyMC42IDExMy4xIDIwLjVDMTEyLjUgMjAuM" +
  "yAxMTEuOCAyMC4zIDExMS4yIDIwLjZMMTA2IDIzLjJDMTA1LjQgMjMuNSAxMDUgMj" +
  "QgMTA0LjggMjQuNkMxMDQuNiAyNS4zIDEwNC42IDI1LjkgMTA0LjkgMjYuNVoiIGZ" +
  "pbGw9IiMzMzMxMkQiLz4NCjxwYXRoIGQ9Ik04My4yIDEzLjJDODMuMyAxMy43IDgz" +
  "LjUgMTQuMiA4My45IDE0LjZDODQuNCAxNS4xIDg1LjIgMTUuNCA4NS45IDE1LjNDO" +
  "DcuMiAxNS4xIDg4LjIgMTMuOSA4OCAxMi41TDg3LjEgNi4xQzg3IDUuNSA4Ni43ID" +
  "QuOSA4Ni4yIDQuNUM4NS43IDQuMSA4NSAzLjkgODQuMyA0QzgzIDQuMiA4MiA1LjQ" +
  "gODIuMiA2LjhMODMuMiAxMy4yWiIgZmlsbD0iIzMzMzEyRCIvPg0KPC9nPg0KPGRl" +
  "ZnM+DQo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTc2Xzg4MDIiI" +
  "HgxPSIxMDIuMTEiIHkxPSI5NC44NzMzIiB4Mj0iMTM5LjE5OCIgeTI9Ijg3LjE1NS" +
  "IgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPg0KPHN0b3Agb2Zmc2V0PSI" +
  "wLjAwMDk4NDI1MiIgc3RvcC1jb2xvcj0iIzYwNUQ1QSIvPg0KPHN0b3Agb2Zmc2V0" +
  "PSIxIiBzdG9wLWNvbG9yPSIjNzk3NjczIi8+DQo8L2xpbmVhckdyYWRpZW50Pg0KP" +
  "GxpbmVhckdyYWRpZW50IGlkPSJwYWludDFfbGluZWFyXzE3Nl84ODAyIiB4MT0iNz" +
  "AuODk0MSIgeTE9IjY2LjMxOSIgeDI9IjQzLjE2MjciIHkyPSI0OS43MjA4IiBncmF" +
  "kaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQo8c3RvcCBvZmZzZXQ9IjAuMDAw" +
  "OTg0MjUyIiBzdG9wLWNvbG9yPSIjNjA1RDVBIi8+DQo8c3RvcCBvZmZzZXQ9IjEiI" +
  "HN0b3AtY29sb3I9IiM3OTc2NzMiLz4NCjwvbGluZWFyR3JhZGllbnQ+DQo8Y2xpcF" +
  "BhdGggaWQ9ImNsaXAwXzE3Nl84ODAyIj4NCjxyZWN0IHdpZHRoPSIxNTYuNiIgaGV" +
  "pZ2h0PSIxNTEuMSIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIg" +
  "NCkiLz4NCjwvY2xpcFBhdGg+DQo8L2RlZnM+DQo8L3N2Zz4NCg==";

export default DarkError;

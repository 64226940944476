import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import searchFlightReducer from "../hooks/useSearchFlightForm/slice";
import authenticationReducer from "./authentication/AuthenticationSlice";
import CytricContextReducer from "./cytric-context/CytricContextSlice";
import RedirectionReducer from "./redirection/RedirectionSlice";
import SegmentSelectedReducer from "./segment-selected/SegmentSelectedSlice";
import TripsReducer from "./trips/TripsSlice";

const middlewares = [logger];

const Store = configureStore({
  reducer: {
    segmentSelected: SegmentSelectedReducer,
    trips: TripsReducer,
    redirection: RedirectionReducer,
    searchflight: searchFlightReducer,
    authentication: authenticationReducer,
    cytricContext: CytricContextReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      // prepend and concat calls can be chained
      .concat(middlewares),
});

export default Store;

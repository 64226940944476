/* eslint-disable class-methods-use-this */
/* istanbul ignore file */

import Http from "../../common/Http";
import { USER_PREFERENCES } from "../../utils/constants";
import HostSettings from "../../utils/host.settings";

export default class UserPreferredLocationService {
  public getPreferredLocation = async () =>
    Http.get(`${HostSettings.getBaseAPI}${USER_PREFERENCES}`);
}

import { Flex } from "@fluentui/react-northstar";
import React from "react";
import "./Feedback.scss";

function Feedback() {
  const FEEDBACK_URL = process.env.REACT_APP_FEEDBACK_FORM_URL;
  return (
    <Flex space="between" className="root-body" column>
      <iframe
        className="root-body"
        allowFullScreen
        src={FEEDBACK_URL}
        title="Feedback Form"
      />
    </Flex>
  );
}

export default Feedback;

/* eslint-disable react/react-in-jsx-scope */
import { Segment } from "@fluentui/react-northstar";
import stylesCloseCollaborators from "../../styles/close-collaborators.module.scss";

export default function CloseCollaboratorsNoItems() {
  return (
    <Segment
      content="It seems that you don't have any close collaborator at your destination"
      className={stylesCloseCollaborators.noCollaboratorsMsg}
    />
  );
}
